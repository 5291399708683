import React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Button from '../../../components/Button';
import { positionTemplateListHeaderHeight } from '../../../theme/theme';

const RootGrid = styled(Grid)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  height: positionTemplateListHeaderHeight,
}));

const PositionTemplateListHeader = () => {
  const navigate = useNavigate();

  return (
    <RootGrid item container>
      <Typography sx={{ marginLeft: 2 }}>Positions</Typography>
      <Button
        style={{ marginLeft: 1 }}
        color="primary"
        text="+&nbsp;New Position"
        variant="contained"
        key="position-template-list-view-new-button"
        onClick={() => navigate('/jobs/createPositionTemplate')}
      />
    </RootGrid>
  );
};

export default PositionTemplateListHeader;
