import React, { useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGridPro } from '@mui/x-data-grid-pro';

import selectUser from '../../../store/selectors/appSelector';
import {
  selectCorporations,
  selectIsLoading,
  selectJobListRowData,
} from '../../../store/selectors/jobListSelectors';
import { appBarHeight, jobListHeaderHeight, paperElevation } from '../../../theme/theme';
import JobDetail from '../jobDetail';

import getJobListColumnData from './getJobListColumnData';
import JobsListHeader from './JobListHeader';
import { getCorporationsForJobsList, getJobsList } from './reducer';

const RootGrid = styled(Grid)(({ theme }) => ({
  height: `calc(100vh - ${appBarHeight})`,
  padding: theme.spacing(2, 0),
}));

const JobsList = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const jobListRowData = useSelector(selectJobListRowData);
  const corporations = useSelector(selectCorporations);
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [jobId, setJobId] = useState('');
  const [pageSize, setPageSize] = React.useState(25);
  const jobListColumnData = useMemo(() => getJobListColumnData(user.role), [user]);
  const [mappedRowData, setMappedRowData] = useState(null);

  const onSelectionModelChange = (selectedJob) => {
    setJobId(selectedJob[0]);
  };

  useEffect(() => {
    onSelectionModelChange('');
  }, [jobListRowData]);

  useEffect(() => {
    batch(() => {
      dispatch(getJobsList());
      dispatch(getCorporationsForJobsList());
    });
  }, [dispatch]);

  useEffect(() => {
    if (corporations && jobListRowData) {
      setMappedRowData(
        jobListRowData.map((job) => ({
          ...job,
          corporation: corporations.find((corp) => corp.id === job.corporation),
        }))
      );
    }
  }, [corporations, jobListRowData]);

  return (
    <RootGrid container>
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && !!jobId)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          direction="column"
          sx={{ height: '100%', marginRight: !!jobId && 2 }}
        >
          <Paper elevation={mediumScreen ? paperElevation : 0} sx={{ height: '100%' }}>
            <JobsListHeader />
            {mappedRowData && (
              <DataGridPro
                columns={jobListColumnData}
                loading={isLoading}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSelectionModelChange={onSelectionModelChange}
                pageSize={pageSize}
                pagination
                rowsPerPageOptions={[25, 50, 100]}
                rows={mappedRowData}
                sx={{ border: 0, height: `calc(100% - ${jobListHeaderHeight})`, paddingLeft: 1 }}
              />
            )}
          </Paper>
        </Grid>
      </Slide>

      <JobDetail jobId={jobId} cleanJobId={() => setJobId('')} />
    </RootGrid>
  );
};

export default JobsList;
