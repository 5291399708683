import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import MessageIcon from '@mui/icons-material/Message';
import PaymentsIcon from '@mui/icons-material/Payments';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

const BottomAppBar = () => {
  const location = useLocation();
  let currentValue = location.pathname.split('/')[1];
  currentValue = currentValue === 'dashboard' ? 'jobs' : currentValue;
  const [value, setValue] = React.useState(currentValue);

  return (
    <>
      <CssBaseline />
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_event, newValue) => setValue(newValue)}
      >
        <BottomNavigationAction
          component={Link}
          to="jobs/jobsList"
          value="jobs"
          label="Work"
          icon={<WorkIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="payment"
          value="payment"
          label="Payment"
          icon={<PaymentsIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="notification"
          value="notification"
          label="Notification"
          icon={<MessageIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="account"
          value="account"
          label="Account"
          icon={<PersonIcon />}
        />
      </BottomNavigation>
    </>
  );
};

export default BottomAppBar;
