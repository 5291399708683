import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCreateJobs = (state) => get('createJobs', state);

export const selectIsLoading = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.isLoading
);

export const jobsList = createSelector(selectCreateJobs, (createJobs) => createJobs.jobsList);
export const locationList = createSelector(selectCreateJobs, (createJobs) => createJobs.locations);
export const clearJobsList = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.clearJobsList
);
export const allDataValid = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.allDataValid
);

export const validateList = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.validateList
);

export const selectPositionTemplateData = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.positionTemplateData
);
export const selectConfirmationData = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.confirmationData
);
