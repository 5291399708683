import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Portal } from '@mui/material';

const WorkDetailHeader = ({ mobileHeaderRef }) => {
  const navigate = useNavigate();
  const navigateToJobsList = () => {
    navigate({ pathname: '/jobs/jobsList' });
  };

  const { current: container } = mobileHeaderRef;

  return (
    <Portal container={container}>
      <IconButton
        color="inherit"
        aria-label="back to jobs list"
        onClick={navigateToJobsList}
        edge="start"
        sx={{ mr: 2 }}
      >
        <ArrowBackIcon />
      </IconButton>
      Job Details
    </Portal>
  );
};

WorkDetailHeader.propTypes = {
  mobileHeaderRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

export default WorkDetailHeader;
