/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AddLocationIcon from '@mui/icons-material/AddLocation';
import { Grid } from '@mui/material';

import {
  selectCorporationById,
  selectLocationById,
} from '../../../store/selectors/employeeWorkSelectors';

const ShiftDetailLocation = ({ jobOrder }) => {
  const { corporation: corporationId } = jobOrder;
  const { name } = useSelector((state) => selectCorporationById(state, corporationId)) || {};

  const { location: locationId } = jobOrder;
  const location = useSelector((state) => selectLocationById(state, locationId)) || {};
  const { address, address2, city, state, zipcode } = location;

  const LocationInfo = (
    <Grid item>
      <h4 style={{ margin: 0, padding: 0 }}>{name}</h4>
      {address} {address2} {city}, {state} {zipcode}
    </Grid>
  );

  // TODO: add functionality to open device default map app
  const openDeviceMapAppHandler = () => {
    alert(`SIMULATED:\nOpen location/direction in device's default map app`);
  };

  const OpenDirectionsIcon = (
    <Grid item>
      <AddLocationIcon color="primary" onClick={openDeviceMapAppHandler} />
    </Grid>
  );

  return (
    <Grid item>
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        wrap="nowrap"
      >
        {LocationInfo}
        {OpenDirectionsIcon}
      </Grid>
    </Grid>
  );
};

const jobOrder = PropTypes.shape({
  location: PropTypes.string,
  corporation: PropTypes.string,
});

ShiftDetailLocation.propTypes = { jobOrder };

export default ShiftDetailLocation;
