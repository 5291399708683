import React from 'react';
import PropTypes from 'prop-types';
import { createSearchParams, useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Portal } from '@mui/material';

const ShiftDetailHeader = ({ mobileHeaderRef, positionTemplateId, locationId }) => {
  const navigate = useNavigate();
  const navigateToJobDetails = () => {
    let search = createSearchParams({ positionTemplateId, locationId });
    search = search.toString();
    navigate({ pathname: '/jobs/jobDetail', search });
  };

  const { current: container } = mobileHeaderRef;

  return (
    <Portal container={container}>
      <IconButton
        color="inherit"
        aria-label="back to job details"
        onClick={navigateToJobDetails}
        edge="start"
        sx={{ mr: 2 }}
      >
        <ArrowBackIcon />
      </IconButton>
      Shift Details
    </Portal>
  );
};

ShiftDetailHeader.propTypes = {
  mobileHeaderRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  positionTemplateId: PropTypes.string,
  locationId: PropTypes.string,
};

export default ShiftDetailHeader;
