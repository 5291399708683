import React, { memo, useCallback, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Form from '../../../components/Form';
import { isEmpty } from '../../../components/Form/formLevelValidations';
import UserRole from '../../../constants/user-role';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectCertifications,
  selectErrors,
  selectIsLoading,
  selectIsLoadingCertifications,
  selectIsLoadingLocations,
  selectLocations,
} from '../../../store/selectors/createPositionTemplateSelectors';

import getEmployerFormFieldData from './getEmployerFormFieldData';
import getFormFieldData from './getFormFieldData';
import { getCertifications, getLocations, postPositionTemplate } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const CreatePositionTemplate = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorData = useSelector(selectErrors);
  const isLoadingLocations = useSelector(selectIsLoadingLocations);
  const isLoadingCertifications = useSelector(selectIsLoadingCertifications);
  const isLoading = useSelector(selectIsLoading);
  const locations = useSelector(selectLocations);
  const certifications = useSelector(selectCertifications);
  const user = useSelector(selectUser);

  useEffect(() => {
    batch(() => {
      dispatch(getLocations());
      dispatch(getCertifications());
    });
  }, [dispatch]);

  const handleOnSubmit = useCallback(
    (values) => {
      const {
        location: selectedLocations,
        certification: selectedCertifications,
        ...rest
      } = values;

      const locationIDs = selectedLocations.map((loc) => loc.id);
      const certificationIDs =
        user.role === UserRole.EMPLOYER ? null : selectedCertifications.map((loc) => loc.id);

      const data = {
        fields: {
          // FIXME: HB-792 for now most of the values are hardcoded
          // corporation: locationID,  corporation ID is required if you are admin user, will be implemented in future tickets
          billRateDT: 0,
          billRateOT: 0,
          billRateReg: 0,
          branch: 'Rochester General Staffing',
          certifications: certificationIDs,
          locations: locationIDs,
          payRateDT: 0,
          payRateOT: 0,
          payRateReg: 0,
          saleRep: 'wae@waeapp.com',
          workersCompCode: '1212',
          ...rest,
        },
      };
      user.role === UserRole.EMPLOYER && delete data.fields.certifications;
      dispatch(postPositionTemplate(data));
    },
    [dispatch, user.role]
  );

  const formFieldData =
    user.role === UserRole.EMPLOYER
      ? getEmployerFormFieldData({
          isLoadingLocations,
          locations,
        })
      : getFormFieldData({
          isLoadingLocations,
          locations,
          isLoadingCertifications,
          certifications,
        });

  const buttonData = [
    {
      color: 'primary',
      disabled: !!isLoading,
      onClick: () => navigate('dashboard'),
      text: 'Cancel',
    },
    {
      color: 'primary',
      disabled: !!isLoading,
      showIndeterminateProgress: !!isLoading,
      text: 'Create Position',
      type: 'submit',
      variant: 'contained',
    },
  ];

  return (
    <RootGrid container>
      <FormGrid item xs={11} sm={8} md={6} lg={6}>
        <Typography variant="h4" align="center" sx={{ padding: 2 }}>
          Request New Position
        </Typography>
        <Form
          errorData={errorData}
          buttonData={buttonData}
          formId="createPositionTemplate"
          formFieldData={formFieldData}
          keepDirtyOnReinitialize
          onSubmit={handleOnSubmit}
          validateForm={isEmpty('shifts', {
            shifts: 'At least one shift must be created to submit',
          })}
        />
      </FormGrid>
    </RootGrid>
  );
});

export default CreatePositionTemplate;
