import React, { memo } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import {
  addHours,
  minutesFromMidnightToTimeOnlyHrAndMins,
  timeToMinutesFromMidnight,
} from '../../utils';
import { showSnackbar } from '../../views/app';
import Button from '../Button';
import Dialog from '../Dialog';
import IconButton from '../IconButton';
import TimePickerField from '../TimePicker/TimePickerField';

import { errorHandler, hasFormSubmitError, hasServerError } from './formUtils';

const FormShiftPicker = memo(
  ({
    additiveElements,
    shiftTitle,
    shiftDialogFullWidth,
    shiftDialogTitle,
    fieldErrorData,
    initialValue,
    input,
    label,
    required,
    shiftOpenDialogButtonVariant,
    listViewGridSpacing,
    meta,
    onBackdropClick,
  }) => {
    const { onChange } = input;

    const [listData, setListData] = React.useState([]);
    const [isShiftDialogOpen, setIsShiftDialogOpen] = React.useState(false);
    const [shiftDialogIsError, setShiftDialogIsError] = React.useState(false);
    const defaultShiftStartTime = new Date();
    const defaultShiftEndTime = addHours(8, defaultShiftStartTime);
    const [shiftInputData, setShiftInputData] = React.useState({
      name: '',
      start: defaultShiftStartTime,
      end: defaultShiftEndTime,
    });

    const error = errorHandler(input, meta, fieldErrorData);
    const dispatch = useDispatch();
    const shiftInputDataName = get('name', shiftInputData);
    const shiftInputDataStart = get('start', shiftInputData);
    const shiftInputDataEnd = get('end', shiftInputData);

    // Shift Dialog Components
    const dialogAddShiftOnClick = (setRFFValue) => {
      setShiftDialogIsError(false);
      if (shiftInputDataName.length < 1) {
        dispatch(showSnackbar({ message: 'Shift name must be at least 1 character long.' }));
        setShiftDialogIsError(true);
        return;
      }
      if (
        listData.filter((row) => row.name.toLowerCase() === shiftInputDataName.toLowerCase())
          .length > 0
      ) {
        dispatch(showSnackbar({ message: 'Cannot add shift with a duplicate shift name.' }));
        setShiftDialogIsError(true);
        return;
      }
      if (
        Number.isNaN(Date.parse(shiftInputDataStart)) ||
        Number.isNaN(Date.parse(shiftInputDataEnd))
      ) {
        return;
      }
      setShiftDialogIsError(false);

      setListData([
        ...listData,
        {
          ...shiftInputData,
          start: timeToMinutesFromMidnight(shiftInputDataStart),
          end: timeToMinutesFromMidnight(shiftInputDataEnd),
        },
      ]);

      setShiftInputData({
        name: '',
        start: defaultShiftStartTime,
        end: defaultShiftEndTime,
      });

      setRFFValue([
        ...listData,
        {
          ...shiftInputData,
          start: timeToMinutesFromMidnight(shiftInputDataStart),
          end: timeToMinutesFromMidnight(shiftInputDataEnd),
        },
      ]);

      setIsShiftDialogOpen(false);
    };

    const nameShiftInputOnchange = (event) => {
      setShiftInputData({ ...shiftInputData, name: event.target.value });
    };

    const startTimeShiftInputOnChange = (value) =>
      value &&
      setShiftInputData({
        ...shiftInputData,
        start: new Date(value.toString()),
      });

    const endTimeShiftInputOnChange = (value) =>
      value && setShiftInputData({ ...shiftInputData, end: new Date(value.toString()) });

    const shiftDialogCancelOnClick = () => {
      setIsShiftDialogOpen(false);
      setShiftDialogIsError(false);
      setShiftInputData({
        name: '',
        start: defaultShiftStartTime,
        end: defaultShiftEndTime,
      });
    };

    const shiftDialogInputComponent = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            error={shiftDialogIsError}
            label="Shift Name"
            onChange={nameShiftInputOnchange}
            placeholder="Morning Shift"
            size="small"
            value={shiftInputDataName}
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <TimePickerField
            label="Start time"
            onChange={startTimeShiftInputOnChange}
            size="small"
            value={shiftInputDataStart}
            error={
              Number.isNaN(Date.parse(shiftInputDataStart)) ? 'Incorrect time format' : undefined
            }
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <TimePickerField
            label="End time"
            onChange={endTimeShiftInputOnChange}
            size="small"
            value={shiftInputDataEnd}
            error={
              Number.isNaN(Date.parse(shiftInputDataEnd)) ? 'Incorrect time format' : undefined
            }
            variant="standard"
          />
        </Grid>
        <Grid item xs={5}>
          {/* FIXME:HB-951/HB-952 Paid Break needs to be implemented once express has added it to the schema, currently checkbox is disabled and does not pass value */}
          <FormGroup>
            <FormControlLabel control={<Checkbox disabled />} label="Paid Break" />
          </FormGroup>
        </Grid>
      </Grid>
    );

    const formListViewDefaultDialogActions = (
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button onClick={shiftDialogCancelOnClick}>Cancel</Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              dialogAddShiftOnClick(onChange);
            }}
            onChange={onChange}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );
    // end of shift dialog component

    const shiftTitleCheckIfRequired = required ? `${shiftTitle}*` : shiftTitle;
    const shiftDeleteOnClick = (rowName, setRFFValue) => {
      setListData(listData.filter((row) => row.name !== rowName));
      setRFFValue(listData.filter((row) => row.name !== rowName));
    };
    return (
      <Grid container spacing={listViewGridSpacing}>
        <Dialog
          component="span"
          open={isShiftDialogOpen}
          fullWidth={shiftDialogFullWidth}
          dialogActions={formListViewDefaultDialogActions}
          dialogTitle={shiftDialogTitle}
          dialogContentText={shiftDialogInputComponent}
          onBackdropClick={onBackdropClick || shiftDialogCancelOnClick}
        />

        <Grid item xs={12} required={required}>
          {shiftTitle && shiftTitleCheckIfRequired}
          <FormHelperText
            sx={{
              color:
                hasFormSubmitError(meta) || hasServerError(meta, fieldErrorData) ? 'red' : null,
            }}
          >
            {error}
          </FormHelperText>
        </Grid>
        <Grid item xs>
          <List name={input.name} label={label} values={input.values || initialValue}>
            <TransitionGroup>
              {listData.length > 0 &&
                listData.map((rowData) => (
                  <Collapse key={`${rowData.name}`}>
                    <ListItem>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Grid item xs={8}>
                            <Typography component="span" key={`${rowData.name}`}>
                              {rowData.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              component="span"
                              color="text.secondary"
                              key={`${rowData.start}`}
                            >
                              {`${minutesFromMidnightToTimeOnlyHrAndMins(
                                rowData.start
                              )}-${minutesFromMidnightToTimeOnlyHrAndMins(rowData.end)}`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <IconButton
                            iconElement={<DeleteIcon />}
                            onClick={() => shiftDeleteOnClick(rowData.name, onChange)}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Collapse>
                ))}
            </TransitionGroup>
          </List>
        </Grid>
        {additiveElements}

        <Grid item xs={12}>
          <Button
            onClick={() => setIsShiftDialogOpen(true)}
            variant={shiftOpenDialogButtonVariant}
            size="small"
          >
            Add Shift
          </Button>
        </Grid>
      </Grid>
    );
  }
);
FormShiftPicker.propTypes = {
  additiveElements: PropTypes.element,
  shiftTitle: PropTypes.string,
  shiftDialogFullWidth: PropTypes.bool,
  shiftDialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fieldErrorData: PropTypes.string,
  initialValue: PropTypes.arrayOf(PropTypes.shape({})),
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  label: PropTypes.string,
  required: PropTypes.bool,
  shiftOpenDialogButtonVariant: PropTypes.string,
  listViewGridSpacing: PropTypes.number,
  meta: PropTypes.shape(),
  onBackdropClick: PropTypes.func,
};
export default FormShiftPicker;
