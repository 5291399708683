// TODO: fix this rule
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { flow, maxBy, minBy } from 'lodash/fp';
import first from 'lodash/fp/first';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Backdrop, CircularProgress } from '@mui/material';

import {
  selectCertifications,
  selectCorporations,
  selectJobOrders,
  selectPlacements,
} from '../../../store/selectors/employeeWorkSelectors';

import WorkDetail from './WorkDetail';
import WorkDetailHeader from './WorkDetailHeader';

const WorkDetailContainer = ({ mobileHeaderRef }) => {
  const [searchParams] = useSearchParams();
  const [groupCertifications, setGroupCertifications] = useState([]);
  const [availableShifts, setAvailableShifts] = useState([]);
  const [groupPlacements, setGroupPlacements] = useState([]);
  const [selectedJobOrders, setSelectedJobOrders] = useState([]);
  const [firstJobOrder, setFirstJobOrder] = useState(null);
  const certifications = useSelector(selectCertifications);
  const corporations = useSelector(selectCorporations);
  const jobOrders = useSelector(selectJobOrders);
  const placements = useSelector(selectPlacements);

  useEffect(() => {
    const positionTemplateId = searchParams.get('positionTemplateId');
    const locationId = searchParams.get('locationId');

    setSelectedJobOrders(
      jobOrders.filter(
        (jobOrder) =>
          jobOrder &&
          jobOrder.positionTemplate === positionTemplateId &&
          jobOrder.location._id === locationId
      )
    );

    setFirstJobOrder(first(selectedJobOrders));

    if (firstJobOrder) {
      setGroupCertifications(
        firstJobOrder.certifications
          .map((cert) => certifications.find((c) => c.id === cert) || [])
          .flat()
      );
      setGroupPlacements(
        placements.filter((placement) =>
          selectedJobOrders.find((jobOrder) => jobOrder._id === get(['jobOrder', '_id'], placement))
        )
      );
      setAvailableShifts(
        selectedJobOrders.filter(
          (jobOrder) =>
            !placements.some((placement) => get(['jobOrder', '_id'], placement) === jobOrder._id)
        )
      );
    }
  }, [
    certifications,
    JSON.stringify(firstJobOrder),
    JSON.stringify(jobOrders),
    placements,
    searchParams,
    JSON.stringify(selectedJobOrders),
  ]);

  if (!firstJobOrder) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const { corporation: corporationId, description, distance, payRateReg } = firstJobOrder;

  const max = flow(maxBy('totalPay'), get('totalPay'))(selectedJobOrders);
  const min = flow(minBy('totalPay'), get('totalPay'))(selectedJobOrders);
  const totalPay = { max, min };

  const jobOrderName = firstJobOrder.name;

  const { name: corporationName } = corporations.find(({ _id }) => _id === corporationId || {});

  return (
    <>
      <WorkDetailHeader mobileHeaderRef={mobileHeaderRef} />
      <WorkDetail
        availableShifts={availableShifts}
        corporation={corporationName}
        description={description}
        distance={distance}
        groupCertifications={groupCertifications}
        groupPlacements={groupPlacements}
        jobOrderName={jobOrderName}
        payRateReg={payRateReg}
        totalPay={totalPay}
      />
    </>
  );
};

WorkDetailContainer.propTypes = {
  mobileHeaderRef: PropTypes.shape({}),
};

export default WorkDetailContainer;
