import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import MenuAppBar from '../../../components/MenuAppBar';
import SideBar from '../../../components/SideBar';
import { drawerWidth } from '../../../theme/theme';

import UserAccountWidget from './UserAccountWidget';

const appBarActions = (
  <>
    <UserAccountWidget />
  </>
);

const BodyContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open }) => ({
  maxWidth: '100% !important',
  ...(open && {
    width: `calc(100% - ${drawerWidth})`,
    marginLeft: `${drawerWidth}`,
  }),
}));

const Navigation = ({ variant }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [isOpen, setIsOpen] = useState(false);
  const isLargeScreenAndOpen = !!(largeScreen && isOpen);

  // FIXME: HB-946 Tab Shift might not work in mac/windows, use nums instead
  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const closeDrawer = useCallback(() => {
    if (!largeScreen) {
      setIsOpen(false);
    }
  }, [largeScreen]);

  useEffect(() => {
    if (largeScreen) {
      setIsOpen(true);
    } else setIsOpen(false);
  }, [largeScreen]);

  return variant === 'TODO' ? null : (
    <div>
      <Grid sx={{ display: 'flex' }}>
        <CssBaseline />
        <SideBar
          toggleDrawer={toggleDrawer}
          closeDrawer={closeDrawer}
          isOpen={isOpen}
          appbarActions={appBarActions}
        />
      </Grid>
      <BodyContainer open={isLargeScreenAndOpen}>
        <MenuAppBar
          toggleDrawer={toggleDrawer}
          isOpen={isLargeScreenAndOpen}
          appbarActions={appBarActions}
        />
        <Outlet />
      </BodyContainer>
    </div>
  );
};

Navigation.propTypes = {
  variant: PropTypes.string,
};

export default Navigation;
