import React, { memo, useCallback, useEffect } from 'react';
import get from 'lodash/fp/get';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import GradientLogo from '../../assets/WAE_logo_Gradient_F.svg';
import Form from '../../components/Form';
import FormPasswordField from '../../components/Form/FormPasswordField';
import FormTextField from '../../components/Form/FormTextField';
import selectUser from '../../store/selectors/appSelector';
import selectIsLoading from '../../store/selectors/loginSelectors';

import { login } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const Login = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(selectIsLoading);
  const user = useSelector(selectUser);
  const token = get('token', user);
  useEffect(() => {
    if (token) {
      navigate('dashboard');
    }
  }, [navigate, token]);

  const handleOnSubmitLogIn = useCallback(
    (values) => {
      dispatch(login(values));
    },
    [dispatch]
  );

  const buttonData = [
    {
      color: 'primary',
      disabled: !!isLoading,
      fullWidth: true,
      showIndeterminateProgress: !!isLoading,
      text: 'Sign in',
      type: 'submit',
      variant: 'contained',
      xs: 12,
    },
    {
      color: 'primary',
      disabled: !!isLoading,
      fullWidth: true,
      onClick: () => navigate('register'),
      text: 'Create Account',
      variant: 'outlined',
      xs: 12,
    },
    {
      color: 'primary',
      disabled: !!isLoading,
      fullWidth: true,
      onClick: () => navigate('forgotMyPassword'),
      text: 'Forgot Password',
      variant: 'text',
      xs: 12,
    },
  ];

  const formFieldData = [
    {
      ComponentType: FormTextField,
      fullWidth: true,
      label: 'Email address',
      name: 'email',
      required: true,
      variant: 'standard',
    },
    {
      ComponentType: FormPasswordField,
      fullWidth: true,
      label: 'Password',
      name: 'password',
      required: true,
      type: 'password',
      variant: 'standard',
    },
  ];

  return (
    <RootGrid container>
      <FormGrid item xs={10} sm={5} md={4} lg={3}>
        <Typography align="center">
          <img src={GradientLogo} alt="WAE" height={250} width={250} />
        </Typography>
        <Form
          buttonData={buttonData}
          formFieldData={formFieldData}
          onSubmit={handleOnSubmitLogIn}
        />
      </FormGrid>
    </RootGrid>
  );
});

export default Login;
