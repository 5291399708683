import React from 'react';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import ButtonActions from './ButtonActions';
import ConfirmationModal from './ConfirmationModal';
import formFieldFactory from './formFieldFactory';
import { mapFormValuesToStringRepresentation } from './formFrameworkUtilities';

const Form = ({
  buttonData,
  debugMode = false,
  errorData,
  formFieldData,
  formRules,
  formId,
  keepDirtyOnReinitialize,
  onSubmit,
  onUpdate, // TODO: HB-1025: This "onUpdate" callback is deprecated and can be removed.
  confirmationModal,
  validateForm,
}) => {
  const calculatedInitialValues = formFieldData.reduce(
    (previousValue, currentValue) =>
      (currentValue.initialValue && {
        ...previousValue,
        ...{ [currentValue.name]: currentValue.initialValue },
      }) ||
      previousValue,
    {}
  );

  const [confirmationModalIsOpen, setConfirmationModalOpen] = React.useState(false);
  const createOpenConfirmationModalButtonData = (props) => ({
    color: 'primary',
    variant: 'contained',
    onClick: () => setConfirmationModalOpen(!confirmationModalIsOpen),
    text: confirmationModal.buttonText,
    ...props,
  });
  return (
    <>
      <RFForm
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        initialValues={calculatedInitialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, valid, values }) => (
          <form onSubmit={handleSubmit} id={formId}>
            <>
              {formRules}
              {formFieldFactory(
                errorData,
                formFieldData,
                (name, label, value, preVal) => onUpdate && onUpdate(name, label, value, preVal) // TODO: HB-1025: This "onUpdate" callback is deprecated and can be removed.
              )}
              {buttonData && (
                <ButtonActions
                  buttonData={[
                    ...buttonData,
                    confirmationModal &&
                      createOpenConfirmationModalButtonData({
                        disabled: !valid,
                      }),
                  ].filter(Boolean)}
                />
              )}
              {confirmationModal && (
                <ConfirmationModal
                  data={mapFormValuesToStringRepresentation(form, formFieldData)}
                  isOpen={confirmationModalIsOpen}
                  onConfirm={() => onSubmit(values)}
                  setIsOpen={setConfirmationModalOpen}
                  submitting={confirmationModal.submitting}
                />
              )}
              {debugMode && <pre>{JSON.stringify(values, 0, 2)}</pre>}
            </>
          </form>
        )}
        validate={(values) => {
          const baseErrors = formFieldData.reduce(
            (accumulator, formFieldDef) =>
              // Verify required fields have values.
              (formFieldDef.required &&
                !values[formFieldDef.name] && {
                  ...accumulator,
                  [formFieldDef.name]: 'This value is required',
                }) ||
              // Else, this field has no errors.
              accumulator,
            {}
          );
          const customErrors = (validateForm && validateForm(values)) || {};
          return { ...baseErrors, ...customErrors };
        }}
      />
    </>
  );
};
Form.propTypes = {
  buttonData: PropTypes.arrayOf(PropTypes.shape({})),
  confirmationModal: PropTypes.shape({ buttonText: PropTypes.string, submitting: PropTypes.bool }),
  debugMode: PropTypes.bool,
  errorData: PropTypes.shape({}),
  formData: PropTypes.shape({}),
  formFieldData: PropTypes.arrayOf(PropTypes.shape({})),
  formRules: PropTypes.arrayOf(PropTypes.shape({})),
  formId: PropTypes.string,
  keepDirtyOnReinitialize: PropTypes.bool,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  validateForm: PropTypes.func,
};
export default Form;
