import React from 'react';
import has from 'lodash/fp/has';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

/**
 * This Rule is intended for updating the current state of our Form Framework's CustomFormFieldData.
 * This rule does not support chaining and it is not supported for more than one rule to update our
 * CustomFormFieldData in a single render. Failure to adhere to this limitation will result in rules
 * overwriting the changes of other rules.
 *
 * @param {{}} props
 *  changedFieldName - The name of the field causing the change event
 *
 *  updateFieldDataCallback - Function that create the new data to update the "CustomFormFieldData."
 *  Must return data in the format of {...rest, FIELDNAME: {...CHANGES}}
 *
 *  prevFormData - The current "CustomFormFieldData" of our form framework
 *
 *  setFormData - Function that will set the current "CustomFormFieldData" of our form framework
 * @returns RFFFormSpy
 */
const CustomFormDataUpdateRule = ({
  changedFieldName,
  updateFieldDataCallback,
  prevFormData,
  setFormData,
}) => {
  const updateCustomFormData = (newFieldValue) => {
    const dataToUpdate = updateFieldDataCallback(newFieldValue);

    setFormData(
      prevFormData.map(
        (fieldData) =>
          (has(fieldData.name, dataToUpdate) && {
            ...fieldData,
            ...dataToUpdate[fieldData.name],
          }) ||
          fieldData
      )
    );
  };
  return (
    <FormSpy subscription={{}}>
      {() => (
        <OnChange name={changedFieldName}>
          {(newFieldValue) => {
            updateCustomFormData(newFieldValue);
          }}
        </OnChange>
      )}
    </FormSpy>
  );
};

CustomFormDataUpdateRule.propTypes = {
  changedFieldName: PropTypes.string,
  prevFormData: PropTypes.arrayOf(PropTypes.shape({})),
  setFormData: PropTypes.func,
  updateFieldDataCallback: PropTypes.func,
};
export default CustomFormDataUpdateRule;
