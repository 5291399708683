import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { epochToShortDateString, epochToTimeInReadableFormat } from '../../../../utils';

import WorkDetailShiftTable from './WorkDetailShiftTable';

const WorkDetailYourShifts = ({ placements }) => {
  const navigate = useNavigate();
  const viewShiftClickHandler = (placementId) => {
    navigate(`/shifts/${placementId}`);
  };

  return (
    <>
      <Typography color="primary" fontWeight="bold" fontSize="large" mb={1}>
        Your Shifts
      </Typography>
      <WorkDetailShiftTable>
        <TableBody>
          {placements.map((placement) => (
            <TableRow
              key={placement.jobOrder.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ paddingLeft: 0 }}>
                {epochToShortDateString(get('start', placement.jobOrder))}
              </TableCell>
              <TableCell align="center">
                {epochToTimeInReadableFormat(get('start', placement.jobOrder))}
              </TableCell>
              <TableCell align="center">
                {epochToTimeInReadableFormat(get('end', placement.jobOrder))}
              </TableCell>
              <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Button
                  onClick={() => viewShiftClickHandler(placement._id)}
                  variant="text"
                  color="primary"
                  sx={{
                    textDecoration: 'underline',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  }}
                >
                  View shift
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </WorkDetailShiftTable>
    </>
  );
};

WorkDetailYourShifts.propTypes = {
  placements: PropTypes.arrayOf(PropTypes.shape({})),
};

export default WorkDetailYourShifts;
