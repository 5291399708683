import format from 'date-fns/format';
import enUsLocale from 'date-fns/locale/en-US';

/**
 * In the future we may need multiple languages we can change the displayLocale variable to change the language
 * for example: change the date text to Spanish
 * import esLocale from 'date-fns/locale/es';
 * check the current language selection.
 * If the language is Spanish, set displayLocale to esLocale. The output date string will be Spanish
 */
const displayLocale = enUsLocale;

/**
 * Converts a string to a JS Date object when the string is formatted like this "August 08, 2022"
 * @param {*} stringDate A date formatted as a string in the format: "August 08, 2022"
 * @returns Date
 */
export const convertMMMMDDYYYYToDate = (stringDate) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const removedComma = stringDate.replace(',', '');
  const [month, day, year] = removedComma.split(' ');

  return Date(parseInt(year, 10), months.indexOf(month), parseInt(day, 10));
};

// returns in this format  => "Wed Jun 08 2022 13:00:24 GMT-0400 (Eastern Daylight Time)"
export const dateToEpoch = (date) => new Date(date).valueOf() / 1000; // or Math.floor(new Date(a).getTime() / 1000.0) can be used to remove 000 at the end

export const epochToTimeInReadableFormat = (epoch) => {
  // returns format  => "01:00 PM"
  const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCMilliseconds(epoch);
  return d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};
export const epochToTimeObject = (epoch) => {
  // returns format  => "01:00 PM"
  const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCMilliseconds(epoch);
  return d;
};

export const epochToDateInReadableFormat = (epoch) => {
  // returns in this format  => "Wed Jun 08 2022"
  const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCMilliseconds(epoch);

  return d.toDateString();
};

export const epochToShortDateString = (epoch) => {
  const d = new Date(0);
  d.setUTCMilliseconds(epoch);

  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
};

export const epochToDate = (epoch) => new Date(epoch);

/**
 * Strips the Hours and Minutes out of a JavaScript Date object
 * and returns the total number of minutes since midnight.
 * @param {Date} time
 * @returns int
 */
export const timeToMinutesFromMidnight = (time) => time.getHours() * 60 + time.getMinutes();

/**
 *
 * @param {int} minutes
 * @returns
 */
export const minutesFromMidnightToTime = (minutes) => {
  const hours = minutes / 60;
  const flooredHours = Math.floor(minutes / 60);
  const remainderMinutes = (hours - flooredHours) * 60;
  const roundedMinutes = Math.round(remainderMinutes);

  const newDate = new Date();

  newDate.setHours(flooredHours);
  newDate.setMinutes(roundedMinutes);
  return newDate;
};

/**
 * Function that reformat javascript Date object
 * @param    {Date} date  Javascript Date Object contains date information
 * @return   {String}     More readable date string like "February 12, 2022"
 */
export const reformatDateMMMMDDYYYY = (date) => {
  try {
    return format(new Date(date), 'MMMM dd, yyyy', {
      locale: displayLocale,
    });
  } catch (e) {
    return '';
  }
}; // TODO: HB-200 i18n needed for month names above.

export const dateObjectToAPI = (date, minutes) => {
  const newObject = {
    year: date.getYear() + 1900,
    day: date.getDate(),
    month: date.getMonth() + 1,
    minutes,
  };
  return newObject;
};

// returns minutes from midnight into Hours and Minute Format
export const minutesFromMidnightToTimeOnlyHrAndMins = (minutes) => {
  const hours = minutes / 60;
  const flooredHours = Math.floor(minutes / 60);
  const remainderMinutes = (hours - flooredHours) * 60;
  const roundedMinutes = Math.round(remainderMinutes);

  let newDate = new Date();

  newDate.setHours(flooredHours);
  newDate.setMinutes(roundedMinutes);

  newDate = newDate.toLocaleTimeString('en-US', {
    // en-US can be set to 'default' to use user's browser settings
    hour: '2-digit',
    minute: '2-digit',
  });
  return newDate;
};

/**
 * Function that add X amount of hours to a given date. Returns a new date.
 * @param    {int} numOfHours  Integer contains number of hours to be added to date
 * @param    {Date} date Javascript Date Object contains date information
 * @return   {Date} New Date Object with updated hour
 */
export const addHours = (numOfHours, date) => {
  const newDate = new Date(date);
  newDate.setTime(newDate.getTime() + numOfHours * 60 * 60 * 1000);

  return newDate;
};
