import React, { useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGridPro } from '@mui/x-data-grid-pro';

import selectUser from '../../../store/selectors/appSelector';
import {
  selectCertifications,
  selectCorporations,
  selectIsLoading,
  selectIsLoadingCorporations,
  selectIsLoadingLocations,
  selectLocations,
  selectPositionTemplateListRowData,
} from '../../../store/selectors/positionTemplateListSelectors';
import {
  appBarHeight,
  paperElevation,
  positionTemplateListHeaderHeight,
} from '../../../theme/theme';
import PositionTemplateDetail from '../positionTemplateDetail';

import getPositionTemplateListColumnData from './getPositionTemplateListColumnData';
import PositionTemplateListHeader from './PositionTemplateListHeader';
import {
  getCertificationsForPositionTemplateList,
  getCorporationsForPositionTemplateList,
  getLocationsForPositionTemplateList,
  getPositionTemplateList,
} from './reducer';

const RootGrid = styled(Grid)(({ theme }) => ({
  height: `calc(100vh - ${appBarHeight})`,
  padding: theme.spacing(2, 0),
}));

const PositionTemplateList = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const positionTemplateListRowData = useSelector(selectPositionTemplateListRowData);
  const isLoadingLocations = useSelector(selectIsLoadingLocations);
  const locations = useSelector(selectLocations);
  const isLoadingCertifications = useSelector(selectIsLoadingCorporations);
  const certifications = useSelector(selectCertifications);
  const isLoadingCorporations = useSelector(selectIsLoadingCorporations);
  const corporations = useSelector(selectCorporations);
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [positionTemplateId, setPositionTemplateId] = useState(null);
  const positionTemplateListColumnData = useMemo(
    () => getPositionTemplateListColumnData(user.role),
    [user]
  );
  const [pageSize, setPageSize] = React.useState(25);
  const [mappedRowData, setMappedRowData] = useState(null);

  const onSelectionModelChange = (selectedPositionTemplate) => {
    setPositionTemplateId((selectedPositionTemplate && selectedPositionTemplate[0]) || null);
  };

  useEffect(() => {
    onSelectionModelChange(null);
  }, [positionTemplateListRowData]);

  useEffect(() => {
    batch(() => {
      dispatch(getCertificationsForPositionTemplateList());
      dispatch(getCorporationsForPositionTemplateList());
      dispatch(getPositionTemplateList());
      dispatch(getLocationsForPositionTemplateList());
    });
  }, [dispatch]);

  useEffect(() => {
    if (corporations && positionTemplateListRowData) {
      setMappedRowData(
        positionTemplateListRowData.map((positionTemplate) => ({
          ...positionTemplate,
          corporation: corporations.find((corp) => corp.id === positionTemplate.corporation),
        }))
      );
    }
  }, [corporations, positionTemplateListRowData]);

  return (
    <RootGrid container>
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && positionTemplateId)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          direction="column"
          sx={{ height: '100%', marginRight: positionTemplateId && 2 }}
        >
          <Paper elevation={mediumScreen ? paperElevation : 0} sx={{ height: '100%' }}>
            <PositionTemplateListHeader />
            {mappedRowData && (
              <DataGridPro
                rows={mappedRowData}
                columns={positionTemplateListColumnData}
                onSelectionModelChange={onSelectionModelChange}
                loading={isLoading}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                pageSize={pageSize}
                pagination
                rowsPerPageOptions={[25, 50, 100]}
                sx={{ border: 0, height: `calc(100% - ${positionTemplateListHeaderHeight})` }}
              />
            )}
          </Paper>
        </Grid>
      </Slide>
      {!isLoadingCertifications && !isLoadingLocations && !isLoadingCorporations && (
        <PositionTemplateDetail
          positionTemplateId={positionTemplateId}
          cleanPositionTemplateId={() => setPositionTemplateId(null)}
          certifications={certifications}
          corporations={corporations}
          locations={locations}
        />
      )}
    </RootGrid>
  );
};

export default PositionTemplateList;
