import React, { useEffect } from 'react';
import find from 'lodash/fp/find';
import intersectionBy from 'lodash/fp/intersectionBy';
import PropTypes from 'prop-types';
import { OnChange } from 'react-final-form-listeners';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { errorHandler } from './formUtils';

const FormSelectDropdownField = ({
  fieldErrorData,
  fullWidth,
  input,
  isLoading,
  label,
  meta,
  multiple,
  onUpdate, // TODO: HB-1025: Is this related to this ticket? What is the use case of this parameter?
  options,
  placeholder,
  required,
  sx,
  variant,
}) => {
  const { onChange } = input;

  const error = errorHandler(input, meta, fieldErrorData);
  useEffect(() => {
    onChange('');
  }, [options, onChange]);

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      sx={sx}
      variant={variant}
      error={!!error}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        multiple={multiple}
        name={input.name}
        value={
          multiple
            ? intersectionBy('id', input.value || [], options)
            : find((val) => val.id === (input.value || '').id, options) || ''
        }
        onChange={input.onChange}
        placeholder={placeholder}
      >
        {isLoading ? (
          <MenuItem value="">
            <em>Loading...</em>
          </MenuItem>
        ) : (
          options.map((option) => {
            // Every option should have a unique id
            // Options data format should be [{id:123.., name: "string", disabled: bool}, ...]
            // disabled prop is optional
            const { name: optionName, disabled, id } = option;

            return (
              <MenuItem key={id} value={option} disabled={disabled}>
                {optionName}
              </MenuItem>
            );
          })
        )}
      </Select>
      <FormHelperText>{error}</FormHelperText>
      <OnChange name={input.name}>
        {(curVal, preVal) => {
          onUpdate(input.name, curVal, preVal);
        }}
      </OnChange>
    </FormControl>
  );
};

FormSelectDropdownField.propTypes = {
  fieldErrorData: PropTypes.string,
  fullWidth: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      // When nothing is selected the value is an empty string (per request of mui)
      PropTypes.string,
      // Non-multiple selection has to be an object
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      // Multiple selection is an array
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    ]),
  }),
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.shape(),
  multiple: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),

  variant: PropTypes.string,
};

export default FormSelectDropdownField;
