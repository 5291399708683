import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import { batch } from 'react-redux';

import { showSnackbar } from '../main/views/app';
import { logout } from '../main/views/app/reducer';

import { apiDelete, apiGet, apiPost, apiPut } from './axios';
import API from './datahubApi';

const parseTokenFromStore = (store) => get(['app', 'session', 'user', 'token'], store.getState());

const parseWebResponse = (webResponse) => ({
  meta: { statusText: get('statusText', webResponse) },

  data: get(['data'], webResponse),

  headers: get(['headers'], webResponse),
});

const logOutOn401 = (storeAPI) => {
  batch(() => {
    storeAPI.dispatch(logout());
    storeAPI.dispatch(
      showSnackbar({
        message: 'Session expired, please login again!',
      })
    );
  });
};

export default function datahubMiddleware(storeAPI) {
  const onSuccess = (webResponse, successCallback) => {
    successCallback(parseWebResponse(webResponse), storeAPI.dispatch);
  };

  const onError = (err, errorCallback) => {
    const code = get(['response', 'data', 'code'], err);
    if (code === 401) {
      logOutOn401(storeAPI);
      return;
    }
    errorCallback(
      parseWebResponse({ err, data: getOr('Unexpected error format', ['response', 'data'], err) }),
      storeAPI.dispatch
    );
  };

  const dispatchAsyncGet = ({ path, payload, successCallback, errorCallback, axiosOptions }) => {
    apiGet(path, payload, parseTokenFromStore(storeAPI), axiosOptions)
      .then((webResponse) => onSuccess(webResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  const dispatchAsyncPost = ({ path, payload, successCallback, errorCallback }) => {
    apiPost(path, payload, parseTokenFromStore(storeAPI))
      .then((webResponse) => onSuccess(webResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  const dispatchAsyncPut = ({ path, payload, successCallback, errorCallback }) => {
    apiPut(path, payload, parseTokenFromStore(storeAPI))
      .then((webResponse) => onSuccess(webResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  const dispatchAsyncDelete = ({ path, payload, successCallback, errorCallback }) => {
    apiDelete(path, payload, parseTokenFromStore(storeAPI))
      .then((webResponse) => onSuccess(webResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  return function wrapDispatch(next) {
    return function handleAction(action) {
      const apiEndpoint = find((endpoint) => endpoint.type === action.type, API);
      if (apiEndpoint) {
        const {
          axiosOptions,
          type: baseAction,
          path,
          successCallback,
          errorCallback,
        } = apiEndpoint;
        const serverMessage = {
          axiosOptions,
          baseAction,
          path,
          payload: action.payload,
          successCallback,
          errorCallback,
        };

        if (apiEndpoint.http === 'get') {
          dispatchAsyncGet(serverMessage);
        } else if (apiEndpoint.http === 'post') {
          dispatchAsyncPost(serverMessage);
        } else if (apiEndpoint.http === 'put') {
          dispatchAsyncPut(serverMessage);
        } else if (apiEndpoint.http === 'delete') {
          dispatchAsyncDelete(serverMessage);
        } else {
          throw Error('API Request Type was invalid or not implemented yet.');
        }
      }
      next(action);
    };
  };
}
