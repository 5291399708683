import { createSlice } from '@reduxjs/toolkit';

const initialState = { isLoading: false };

export const registerSlice = createSlice({
  name: 'registerSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    register: (state) => {
      state.isLoading = true;
    },
    registerProcessed: (state) => {
      state.isLoading = false;
    },
    registerError: (state) => {
      state.isLoading = false;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const { register, registerProcessed, registerError } = registerSlice.actions;

export const registerReducer = registerSlice.reducer;
