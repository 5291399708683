/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import Dialog from '../../../components/Dialog';

const ShiftDetailClockInConfirmDialog = ({ placementId, open, setOpen }) => {
  const closeDialog = () => setOpen(false);

  const CloseDialogButton = (
    <Button
      color="primary"
      variant="text"
      component="span"
      key="handle-cancel-button"
      onClick={closeDialog}
    >
      Cancel
    </Button>
  );

  const navigate = useNavigate();

  // TODO: Implement cancel Job functionality
  const cancelJob = () => {
    alert(`SIMULATED: Removed placement ${placementId}`);
    navigate('/jobs/jobsList');
    closeDialog();
  };

  const CancelJobButton = (
    <Button
      color="primary"
      variant="text"
      component="span"
      key="handle-confirm-button"
      onClick={cancelJob}
    >
      Confirm
    </Button>
  );

  return (
    <Dialog
      open={open}
      dialogTitle="Cancel shift"
      dialogContentText="Remove this job from your schedule?"
      dialogActions={[CloseDialogButton, CancelJobButton]}
    />
  );
};

ShiftDetailClockInConfirmDialog.propTypes = {
  placementId: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default ShiftDetailClockInConfirmDialog;
