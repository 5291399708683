import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import { calculateTotalPay } from '../viewCalculations';

const WorkDetailPayment = ({ payRateReg, totalPay }) => (
  <>
    <Typography fontWeight="bold" fontSize="large">
      Payment
    </Typography>
    <Typography>
      ${payRateReg} an hour (est&nbsp;
      {calculateTotalPay(totalPay)} total)
    </Typography>
  </>
);

WorkDetailPayment.propTypes = {
  payRateReg: PropTypes.number,
  totalPay: PropTypes.shape({
    max: PropTypes.number,
    min: PropTypes.number,
    total: PropTypes.number,
  }),
};

export default WorkDetailPayment;
