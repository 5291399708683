import React from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
  selectGroups,
  selectIsLoadingWorkList,
} from '../../../store/selectors/employeeWorkSelectors';
import theme from '../../../theme/theme';

import WorkListCard from './WorkListCard';

const AvailableWork = () => {
  const navigate = useNavigate();

  const workCardClickHandler = (group) => {
    const jobOrder = group.jobOrders[0];
    const positionTemplateId = jobOrder.positionTemplate;
    const locationId = jobOrder.location._id;
    navigate({
      pathname: '/jobs/jobDetail',
      search: createSearchParams({ positionTemplateId, locationId }).toString(),
    });
  };

  const isLoadingWorkList = useSelector(selectIsLoadingWorkList);
  const groups = useSelector(selectGroups);

  if (isLoadingWorkList) return <Typography variant="h4">Loading available jobs... </Typography>;

  return (
    <Grid item>
      <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
        Available
      </Typography>
      <Stack>
        {groups.length ? (
          groups.map((group) => (
            <WorkListCard data={group} itemClickHandler={workCardClickHandler} key={group._id} />
          ))
        ) : (
          <Typography variant="h4">There are no jobs to show</Typography>
        )}
      </Stack>
    </Grid>
  );
};

export default AvailableWork;
