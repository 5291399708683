/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Grid } from '@mui/material';

import { epochToTimeInReadableFormat as formatTime } from '../../../utils';

const ShiftDetailPunchClock = ({ clockedInDateTime }) => {
  // only show punch clock if clock-in is recorded
  if (!clockedInDateTime) return null;

  const readableClockInTime = formatTime(clockedInDateTime);
  const clockedInString = `Clocked in at ${readableClockInTime}`;

  const PunchClockInfo = (
    <Grid item>
      <h4 style={{ margin: 0, padding: 0 }}>Punch clock</h4>
      {clockedInString}
    </Grid>
  );

  // TODO: Define functionality
  const iconClickHandler = () => {
    alert(`Behavior not defined`);
  };

  // TODO: Rename when functionality is defined
  const MoreOptionsIcon = (
    <Grid item>
      <MoreHorizIcon color="primary" onClick={iconClickHandler} />
    </Grid>
  );

  return (
    <Grid item>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        {PunchClockInfo}
        {MoreOptionsIcon}
      </Grid>
    </Grid>
  );
};

ShiftDetailPunchClock.propTypes = {
  clockedInDateTime: PropTypes.number,
};

export default ShiftDetailPunchClock;
