import axios from 'axios';
import { v4 } from 'uuid';

// TODO:HB-954 Link to the ec2 instance address should be replaced with our DNS name.
const apiUrl =
  process.env.REACT_APP_EXPRESS_ADDRESS || 'http://ec2-54-91-94-234.compute-1.amazonaws.com:8089/';

export const apiPost = (route, data, token) =>
  axios.post(`${apiUrl}${route}`, data, {
    headers: {
      token: `${token}`,
      correlationId: v4(),
    },
  });

export const apiPut = (route, data, token) =>
  axios.put(`${apiUrl}${route}`, data, {
    headers: {
      token: `${token}`, // TODO: HB-955 Verify that this is the correct way to specify the token
      correlationId: v4(),
    },
  });

export const apiGet = (route, data, token, axiosOptions = {}) =>
  axios.get(`${apiUrl}${route}`, {
    params: { ...data },
    headers: {
      Authorization: `Bearer ${token}`,
      correlationId: v4(),
    },
    ...axiosOptions,
  });

export const apiDelete = (route, data, token) =>
  axios.delete(`${apiUrl}${route}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      correlationId: v4(),
    },
    data,
  });
