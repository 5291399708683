/* eslint-disable no-debugger */
import React, { useState } from 'react';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import selectUser from '../../../../store/selectors/appSelector';
import { epochToShortDateString, epochToTimeInReadableFormat } from '../../../../utils';
import { postPlacement } from '../../reducer';

import WorkDetailShiftSignUpDialog from './WorkDetailShiftSignUpDialog';
import WorkDetailShiftTable from './WorkDetailShiftTable';

const WorkDetailComponent = ({ shift, signUpClickHandler }) => {
  const now = new Date();
  if (shift.start < now && shift.end < now) {
    return null;
  }

  return (
    <TableRow key={shift.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row" sx={{ paddingLeft: 0 }}>
        {epochToShortDateString(get('start', shift))}
      </TableCell>
      <TableCell align="center">{epochToTimeInReadableFormat(get('start', shift))}</TableCell>
      <TableCell align="center">{epochToTimeInReadableFormat(get('end', shift))}</TableCell>
      <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <Button
          onClick={() => signUpClickHandler(shift)}
          variant="text"
          color="primary"
          sx={{
            textDecoration: 'underline',
            textTransform: 'none',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
          }}
        >
          Sign up
        </Button>
      </TableCell>
    </TableRow>
  );
};

WorkDetailComponent.propTypes = {
  shift: PropTypes.shape({ start: PropTypes.number, end: PropTypes.number, id: PropTypes.string }),
  signUpClickHandler: PropTypes.func,
};

const WorkDetailAvailableShifts = ({ shifts }) => {
  const user = useSelector(selectUser);
  const [isSignUpConfirmDialogOpen, setIsSignUpConfirmDialogOpen] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);

  const signUpClickHandler = (shiftSignUp) => {
    setSelectedShift(shiftSignUp);
    setIsSignUpConfirmDialogOpen(true);
    // TODO: show confirm skill modal if skills are unconfirmed
  };

  const dispatch = useDispatch();
  const onSignUpConfirmHandler = () => {
    const acceptShiftData = {
      fields: {
        candidate: user._id,
        jobOrder: selectedShift.id,
      },
    };
    setIsSignUpConfirmDialogOpen(false);
    setSelectedShift(null);
    dispatch(postPlacement(acceptShiftData));
  };

  const onSignUpCancelHandler = () => {
    setIsSignUpConfirmDialogOpen(false);
    setSelectedShift(null);
  };

  const sortedShifts = sortBy((shift) => shift.start, shifts);

  return (
    <>
      <Typography color="primary" fontWeight="bold" fontSize="large" mb={1}>
        Available Shifts
      </Typography>
      <WorkDetailShiftTable>
        <TableBody>
          {sortedShifts.map((shift) => (
            <WorkDetailComponent
              shift={shift}
              signUpClickHandler={signUpClickHandler}
              key={shift.id}
            />
          ))}
        </TableBody>
      </WorkDetailShiftTable>
      {selectedShift && (
        <WorkDetailShiftSignUpDialog
          shift={selectedShift}
          isOpen={isSignUpConfirmDialogOpen}
          onConfirm={onSignUpConfirmHandler}
          onCancel={onSignUpCancelHandler}
        />
      )}
    </>
  );
};

WorkDetailAvailableShifts.propTypes = {
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
};

export default WorkDetailAvailableShifts;
