import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { batch, useDispatch } from 'react-redux';

import {
  getCertificationsForCandidate,
  getCorporationsForCandidate,
  getJobOrderList,
  getPlacements,
  getWorkList,
} from './reducer';

const ConnectedWork = ({ children }) => {
  const dispatch = useDispatch();

  /**
   * Note that the `getCorporationsForCandidate` dispatch intrinsically calls the dispatch to get
   * the job groups. If this interaction is split, the job groups will also have to be called
   * in this dispatch.
   */

  useEffect(() => {
    batch(() => {
      dispatch(getWorkList());
      dispatch(getCorporationsForCandidate());
      dispatch(getCertificationsForCandidate());
      dispatch(getPlacements());
      dispatch(getJobOrderList());
    });
  }, [dispatch]);

  return <>{children}</>;
};

ConnectedWork.propTypes = {
  children: PropTypes.shape(),
};

export default ConnectedWork;
