import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Grid, Typography } from '@mui/material';

const WorkDetailCertification = ({ certification }) => (
  <Grid container direction="row" alignItems="center" columnGap={1} wrap="nowrap">
    <Box item p={1}>
      <CheckCircleOutlineIcon fontSize="large" color="success" />
    </Box>
    <Box item>
      <Typography fontWeight="bold">{certification.name}</Typography>
      <Typography>{certification.description || '[no description provided]'}</Typography>
    </Box>
  </Grid>
);

WorkDetailCertification.propTypes = {
  certification: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default WorkDetailCertification;
