import React from 'react';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';

import { CustomFormDataUpdateRule, ValueUpdateRule } from '../../../../components/Form/FormSpy';
import { nameAndTimeToReadable } from '../../../../utils';

const buildFormRules = (currentFormData, setFormData, { locations, positionTemplateData }) => [
  /*
   * Setting the Corporation will:
   * - fill the Position field with options
   */
  <CustomFormDataUpdateRule
    changedFieldName="corporation"
    key="corporationChangeSetsFormData"
    prevFormData={currentFormData}
    setFormData={setFormData}
    updateFieldDataCallback={(newCorporationValue) => ({
      position: {
        options:
          (get('id', newCorporationValue) &&
            flow(
              filter((pt) => pt.corporation === newCorporationValue.id),
              map((pt) => ({
                name: pt.approved ? pt.name : `${pt.name}  (approval pending)`,
                id: pt.id,
                disabled: !pt.approved,
              }))
            )(positionTemplateData)) ||
          [],
      },
    })}
  />,

  /*
   * Setting the Position field will set the Description field
   */
  <ValueUpdateRule
    changedFieldName="position"
    key="positionChangeSetsDescriptionValue"
    updateTargetValueCallback={(newPositionValue) =>
      flow(
        find((pt) => pt.id === newPositionValue.id),
        get('description')
      )(positionTemplateData)
    }
    targetFieldName="description"
  />,

  /*
   * Setting the Position field will set the title field
   */
  <ValueUpdateRule
    changedFieldName="position"
    key="positionChangeSetsTitleValue"
    updateTargetValueCallback={(newPositionValue) =>
      flow(
        find((pt) => pt.id === newPositionValue.id),
        get('name')
      )(positionTemplateData)
    }
    targetFieldName="title"
  />,

  /*
   * Setting the Position will:
   * - fill the Location field with options
   * - fill the Shift field with options
   */
  <CustomFormDataUpdateRule
    changedFieldName="position"
    key="positionChangeSetsFormData"
    prevFormData={currentFormData}
    setFormData={setFormData}
    updateFieldDataCallback={(newPositionValue) => ({
      location: {
        options:
          (get('id', newPositionValue) &&
            flow(
              // Get all locations belonging to currently selected Position Template
              filter((ln) =>
                flow(
                  find((pt) => pt.id === newPositionValue.id),
                  get('locations'),
                  some((locationId) => ln.id === locationId)
                )(positionTemplateData)
              ),
              // Map locations into necessary format for input field options
              map((ln) => ({
                id: ln.id,
                name: `${ln.description} - ${ln.address}`,
              }))
            )(locations)) ||
          [],
      },
      shift: {
        options:
          (get('id', newPositionValue) &&
            flow(
              find((pt) => pt.id === newPositionValue.id),
              get('shifts'),
              map((shift) => ({
                // eslint-disable-next-line no-underscore-dangle
                id: shift._id,
                name: nameAndTimeToReadable(shift.name, shift.start, shift.end),
                start: shift.start,
                end: shift.end,
              }))
            )(positionTemplateData)) ||
          [],
      },
    })}
  />,
];

export default buildFormRules;
