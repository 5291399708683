import React from 'react';
import PropTypes from 'prop-types';
import { OnChange } from 'react-final-form-listeners';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { errorHandler } from './formUtils';

const FormAutocompleteField = ({
  fieldErrorData,
  fullWidth,
  input,
  label,
  meta,
  multiple,
  onUpdate, // TODO:  HB-1025: Is this related to this ticket? What is the use case for this parameter?
  optionLabel,
  options,
  placeholder,
  required,
  sx,
  variant,
}) => {
  const { onChange } = input;
  const error = errorHandler(input, meta, fieldErrorData);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, val) => option.id === val.id}
      multiple={multiple || false}
      name={input.name}
      onChange={(event, newValue) => onChange(newValue)}
      options={options || []}
      getOptionLabel={(option) => option[optionLabel] || ''}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option[optionLabel]}
        </li>
      )}
      renderInput={(params) => (
        <FormControl fullWidth={fullWidth}>
          <TextField
            {...params}
            error={!!error}
            helperText={error || null}
            label={label}
            placeholder={placeholder}
            variant={variant}
          />
          <OnChange name={input.name}>
            {(curVal) => {
              onUpdate(input.name, curVal);
            }}
          </OnChange>
        </FormControl>
      )}
      required={required}
      sx={sx}
      value={input.value || (multiple ? [] : null)}
    />
  );
};

FormAutocompleteField.propTypes = {
  fieldErrorData: PropTypes.string,
  fullWidth: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }),
  label: PropTypes.string,
  meta: PropTypes.shape(),
  multiple: PropTypes.bool,
  onUpdate: PropTypes.func,
  optionLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default FormAutocompleteField;
