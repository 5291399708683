import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LockResetIcon from '@mui/icons-material/LockReset';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

/*
This is what will be used when we eventually move to having only one settings option in the 
sidebar navigation. This can overwrite what is found in the return statement below once
the move is made.


<>
      <List component="nav" disablePadding>
        <ListItemButton sx={{ pl: 2 }} onClick={() => navigate('settings/general')}>
          <ListItemIcon>
            <SettingsIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="General" />
        </ListItemButton>
      </List>
    </>
 */

const SettingsMenu = ({ closeDrawer }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleExpand = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton onClick={handleExpand}>
        <ListItemIcon>
          <SettingsIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Settings" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List component="nav" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              navigate('settings/passwordUpdate');
              closeDrawer();
            }}
          >
            <ListItemIcon>
              <LockResetIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Update Password" />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

SettingsMenu.propTypes = {
  closeDrawer: PropTypes.func,
};

export default SettingsMenu;
