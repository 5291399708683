/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';

import EventIcon from '@mui/icons-material/Event';
import { Grid } from '@mui/material';

import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../utils';

const ShiftDetailDateTime = ({ jobOrder }) => {
  const { start, end } = jobOrder;
  const startDate = epochToDateInReadableFormat(start);
  const startTime = epochToTimeInReadableFormat(start);
  const endTime = epochToTimeInReadableFormat(end);

  const DateTimeInfo = (
    <Grid item>
      <h4 style={{ margin: 0, padding: 0 }}>{startDate}</h4>
      {startTime} to {endTime}
    </Grid>
  );

  // TODO: add functionality to add event to calendar
  const addToCalendarHandler = () => {
    alert(`SIMULATED:\nAdded to device default calendar`);
  };

  const AddEventIcon = (
    <Grid item>
      <EventIcon color="primary" onClick={addToCalendarHandler} />
    </Grid>
  );

  return (
    <Grid item>
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        wrap="nowrap"
      >
        {DateTimeInfo}
        {AddEventIcon}
      </Grid>
    </Grid>
  );
};

const jobOrder = PropTypes.shape({
  start: PropTypes.number,
  end: PropTypes.number,
});

ShiftDetailDateTime.propTypes = { jobOrder };

export default ShiftDetailDateTime;
