import React from 'react';

import { Typography } from '@mui/material';

import WorkDetailSkill from './WorkDetailSkill';

const WorkDetailSkills = () => {
  const DUMMY_SKILLS = [
    {
      title: 'Basic Computer Literacy',
      summary: 'Proficient in operating a computer and typing information.',
      prompt:
        'Are you able to operate a computer and type at a reasonable speed for extended lengths of time?',
      enabled: false,
    },
  ];

  return (
    <>
      <Typography fontWeight="bold" fontSize="large" mb={1}>
        Skills
      </Typography>
      {DUMMY_SKILLS.map((skill) => (
        <WorkDetailSkill skill={skill} key={skill.title} />
      ))}
    </>
  );
};

export default WorkDetailSkills;
