import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import { selectPlacementById } from '../../../store/selectors/employeeWorkSelectors';

import ShiftDetailCancelJob from './ShiftDetailCancelJob';
import ShiftDetailClockInOut from './ShiftDetailClockInOut';
import ShiftDetailDateTime from './ShiftDetailDateTime';
import ShiftDetailHeader from './ShiftDetailHeader';
import ShiftDetailLocation from './ShiftDetailLocation';
import ShiftDetailPunchClock from './ShiftDetailPunchClock';

const ShiftDetail = ({ mobileHeaderRef }) => {
  // TODO: implement actual clock in/out functionality
  const [clockedInDateTime, setClockedInDateTime] = useState(0);

  // lookup placement using id from url params
  const { placementId } = useParams();
  const placement = useSelector((state) => selectPlacementById(state, placementId));

  if (!placement) return null;

  const { jobOrder } = placement;
  const { name, positionTemplate: positionTemplateId, location: locationId } = jobOrder;

  return (
    <>
      <ShiftDetailHeader
        mobileHeaderRef={mobileHeaderRef}
        positionTemplateId={positionTemplateId}
        locationId={locationId}
      />
      <Grid container direction="column" spacing={10}>
        <Grid item>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <h3>{name}</h3>
            </Grid>
            <ShiftDetailDateTime jobOrder={jobOrder} />
            <ShiftDetailLocation jobOrder={jobOrder} />
            <ShiftDetailPunchClock clockedInDateTime={clockedInDateTime} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <ShiftDetailClockInOut
              placementId={placementId}
              clockedInDateTime={clockedInDateTime}
              setClockedInDateTime={setClockedInDateTime}
            />
            <ShiftDetailCancelJob placementId={placementId} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ShiftDetail.propTypes = {
  mobileHeaderRef: PropTypes.shape({}),
};

export default ShiftDetail;
