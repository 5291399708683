import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import Dialog from '../../../../components/Dialog';
import { epochToShortDateString, epochToTimeInReadableFormat } from '../../../../utils';

const WorkDetailConfirmShiftDialog = ({ shift, onCancel, onConfirm, isOpen }) => {
  const dialogTitle = 'Sign up for shift';

  const { name, start, end } = shift;
  const startDate = epochToShortDateString(start);
  const startTime = epochToTimeInReadableFormat(start);
  const endTime = epochToTimeInReadableFormat(end);

  const dialogContentText = `Sign up for ${name} work occurring ${startDate} from ${startTime} to ${endTime}?`;

  return (
    <>
      <Dialog
        open={isOpen}
        dialogTitle={dialogTitle}
        dialogContentText={dialogContentText}
        dialogActions={[
          <Button
            color="primary"
            variant="text"
            component="span"
            key="handle-cancel-sign-up-button"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>,
          <Button
            color="primary"
            variant="text"
            component="span"
            key="handle-confirm-sign-up-button"
            onClick={() => onConfirm()}
          >
            Confirm
          </Button>,
        ]}
      />
    </>
  );
};

WorkDetailConfirmShiftDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  shift: PropTypes.shape({
    name: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    enabled: PropTypes.bool,
  }),
};

export default WorkDetailConfirmShiftDialog;
