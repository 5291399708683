import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCreatePositionTemplate = (state) => get('createPositionTemplate', state);

export const selectLocations = createSelector(
  selectCreatePositionTemplate,
  (createPositionTemplate) => createPositionTemplate.locations
);
export const selectIsLoadingLocations = createSelector(
  selectCreatePositionTemplate,
  (createPositionTemplate) => createPositionTemplate.isLoadingLocations
);
export const selectCertifications = createSelector(
  selectCreatePositionTemplate,
  (createPositionTemplate) => createPositionTemplate.certifications
);
export const selectIsLoadingCertifications = createSelector(
  selectCreatePositionTemplate,
  (createPositionTemplate) => createPositionTemplate.isLoadingCertifications
);
export const selectIsLoading = createSelector(
  selectCreatePositionTemplate,
  (createPositionTemplate) => createPositionTemplate.isLoading
);
export const selectErrors = createSelector(
  selectCreatePositionTemplate,
  (createPositionTemplate) => createPositionTemplate.errors
);
