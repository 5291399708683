import { createSlice } from '@reduxjs/toolkit';

const initialState = { forgotIsProcessing: false, forgotIsNavigating: false };
export const forgotMyPasswordSlice = createSlice({
  name: 'forgotMyPasswordSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    postForgotPassword: (state) => {
      state.forgotIsProcessing = true;
    },
    postForgotPasswordProcessed: (state) => {
      state.forgotIsProcessing = false;
    },
    postForgotPasswordError: (state) => {
      state.forgotIsProcessing = false;
    },
    forgotPasswordIsNavigating: (state) => {
      state.forgotIsNavigating = true;
    },
    forgotPasswordNavigated: (state) => {
      state.forgotIsNavigating = false;
    },

    /* eslint-disable no-param-reassign */
  },
});

export const {
  postForgotPassword,
  postForgotPasswordProcessed,
  postForgotPasswordError,
  forgotPasswordIsNavigating,
  forgotPasswordNavigated,
} = forgotMyPasswordSlice.actions;

export const forgotMyPasswordReducer = forgotMyPasswordSlice.reducer;
