import React from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';

import store from './main/store/configureStore';
import theme from './main/theme/theme';
import App from './main/views/app';

const muiLicenseKey = process.env.REACT_APP_MUI_LICENSE_KEY;
const launchDarklyClientID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;

LicenseInfo.setLicenseKey(muiLicenseKey);
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: `${launchDarklyClientID}`,
    user: {
      key: 'wae-highbar-client',
      name: 'WAE Client',
    },
    options: {
      /* ... */
    },
  });
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <LDProvider>
          <App />
        </LDProvider>
      </Provider>
    </ThemeProvider>,
    document.getElementById('root')
  );
})();
