import getOr from 'lodash/fp/getOr';

import UserRole from '../../../constants/user-role';
import currencyFormatter from '../../../utils/currencyFormatter';

const approved = {
  field: 'approved',
  headerName: 'Approved',
  width: 150,
  valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
};

const billRateReg = {
  field: 'billRateReg',
  headerName: 'Bill Rate',
  valueFormatter: ({ value }) => currencyFormatter.format(value),
  width: 150,
};

const corporation = {
  field: 'corporation',
  headerName: 'Corporation',
  valueFormatter: ({ value }) => getOr('', 'name', value),
  width: 200,
};

const description = {
  field: 'description',
  headerName: 'Description',
  width: 300,
};

const name = {
  field: 'name',
  headerName: 'Name',
  width: 200,
};

const numCertifications = {
  field: 'numCertifications',
  headerName: 'Certifications',
  width: 150,
};

const numLocations = {
  field: 'numLocations',
  headerName: 'Locations',
  width: 150,
};

const numShifts = {
  field: 'numShifts',
  headerName: 'Shifts',
  width: 150,
};

const payRateReg = {
  field: 'payRateReg',
  headerName: 'Pay Rate',
  valueFormatter: ({ value }) => currencyFormatter.format(value),
  width: 150,
};

const saleRep = {
  field: 'saleRep',
  headerName: 'Sales Rep',
  width: 200,
};

const adminColumnData = [
  name,
  description,
  approved,
  corporation,
  billRateReg,
  payRateReg,
  numLocations,
  numShifts,
  numCertifications,
  saleRep,
];

const employerColumnData = [
  name,
  description,
  approved,
  billRateReg,
  numLocations,
  numShifts,
  numCertifications,
];

const recruiterColumnData = [
  name,
  description,
  approved,
  corporation,
  billRateReg,
  payRateReg,
  numLocations,
  numShifts,
  numCertifications,
  saleRep,
];

const getPositionTemplateListColumnData = (userRole) => {
  switch (userRole) {
    case UserRole.ADMIN:
      return adminColumnData;
    case UserRole.EMPLOYER:
      return employerColumnData;
    case UserRole.RECRUITER:
      return recruiterColumnData;
    default:
      throw Error(`Invalid user role '${userRole}'`);
  }
};

export default getPositionTemplateListColumnData;
