import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import Button from '../Button';

const ButtonActions = ({ buttonData }) => {
  const ButtonGridItems = buttonData.map((button) => {
    const {
      color,
      disabled,
      fullWidth,
      onClick,
      showIndeterminateProgress,
      sx,
      text,
      type,
      variant,
      xs,
    } = button;

    const ButtonAction = (
      <Button
        color={color}
        disabled={disabled}
        fullWidth={fullWidth}
        onClick={onClick}
        showIndeterminateProgress={showIndeterminateProgress}
        sx={sx}
        text={text}
        type={type}
        variant={variant}
      />
    );

    return (
      <Grid item key={`btn-${text}`} xs={xs}>
        {ButtonAction}
      </Grid>
    );
  });

  return (
    <Grid container spacing={1} justifyContent="flex-end" sx={{ marginTop: 5, marginBottom: 1 }}>
      {ButtonGridItems}
    </Grid>
  );
};

ButtonActions.propTypes = {
  buttonData: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      disabled: PropTypes.bool,
      fullWidth: PropTypes.bool,
      onClick: PropTypes.func,
      showIndeterminateProgress: PropTypes.bool,
      text: PropTypes.string,
      variant: PropTypes.string,
    })
  ),
};

export default ButtonActions;
