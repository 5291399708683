import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectJobList = (state) => get('jobList', state);

export const selectIsLoading = createSelector(selectJobList, (jobList) => jobList.isLoading);

export const selectJobListRowData = createSelector(
  selectJobList,
  (jobList) => jobList.jobListRowData
);

export const selectedJobsList = createSelector(selectJobList, (jobList) => jobList.selectedJobs);

export const selectCorporations = createSelector(selectJobList, (jobList) => jobList.corporations);

export const selectIsLoadingCorporations = createSelector(
  selectJobList,
  (jobList) => jobList.isLoadingCorporations
);

export const deleteIsProcessing = createSelector(
  selectJobList,
  (jobList) => jobList.deleteIsProcessing
);

export const updateIsProcessing = createSelector(
  selectJobList,
  (jobList) => jobList.updateIsProcessing
);
