import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

export const selectEmployeeWork = (state) => get('employeeWork', state);

export const selectCertifications = createSelector(
  selectEmployeeWork,
  (employeeWork) => employeeWork.certifications
);
export const selectIsLoadingCertifications = createSelector(
  selectEmployeeWork,
  (employeeWork) => employeeWork.isLoadingCertifications
);

export const selectCorporations = createSelector(
  selectEmployeeWork,
  (employeeWork) => employeeWork.corporations
);
export const selectCorporationById = createSelector(
  selectCorporations,
  (state, corporationId) => corporationId,
  (corporations, corporationId) => corporations.find(({ _id }) => _id === corporationId)
);

export const selectFiles = createSelector(selectEmployeeWork, (employeeWork) => employeeWork.files);

export const selectGroups = createSelector(
  selectEmployeeWork,
  (employeeWork) => employeeWork.groups
);

export const selectIsLoadingWorkList = createSelector(
  selectEmployeeWork,
  (employeeWork) => employeeWork.isLoadingWorkList
);

export const selectJobOrders = createSelector(
  selectEmployeeWork,
  (employeeWork) => employeeWork.jobOrders
);

export const selectLocations = createSelector(
  selectEmployeeWork,
  (employeeWork) => employeeWork.locations
);
export const selectLocationById = createSelector(
  selectLocations,
  (state, locationId) => locationId,
  (locations, locationId) => locations.find(({ _id }) => _id === locationId)
);

export const selectPlacements = createSelector(
  selectEmployeeWork,
  (employeeWork) => employeeWork.placements
);
export const selectPlacementById = createSelector(
  selectPlacements,
  (state, placementId) => placementId,
  (placements, placementId) => placements.find(({ _id }) => _id === placementId)
);
export const selectIsLoadingPlacements = createSelector(
  selectEmployeeWork,
  (employeeWork) => employeeWork.isLoadingPlacements
);
