import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const AccountVerification = (state) => get('accountVerification', state);

const selectVerifyingIsProcessing = createSelector(
  AccountVerification,
  (accVerify) => accVerify.verifyingIsProcessing
);

export default selectVerifyingIsProcessing;
