import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import Dialog from '../../../../components/Dialog';

const WorkDetailConfirmSkillDialog = ({ skill, onCancel, onConfirm, isOpen }) => {
  const dialogTitle = `Confirm skill: ${skill.title}`;

  useEffect(() => {}, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        dialogTitle={dialogTitle}
        dialogContentText={skill.prompt}
        dialogActions={[
          <Button
            color="primary"
            variant="text"
            component="span"
            key="handle-cancel-skill-button"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>,
          <Button
            color="primary"
            variant="text"
            component="span"
            key="handle-confirm-skill-button"
            onClick={() => onConfirm()}
          >
            Confirm
          </Button>,
        ]}
      />
    </>
  );
};

WorkDetailConfirmSkillDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  skill: PropTypes.shape({
    prompt: PropTypes.string,
    summary: PropTypes.string,
    title: PropTypes.string,
    enabled: PropTypes.bool,
  }),
};

export default WorkDetailConfirmSkillDialog;
