import React from 'react';
import { Field } from 'react-final-form';

import Grid from '@mui/material/Grid';

import FormCheckBox from './FormCheckBox';
import FormRadioButtons from './FormRadioButtons';

// TODO: HB-1025: This "onUpdate" callback is deprecated and can be removed.
const formFieldFactory = (errorData, formFieldData, onUpdate) => (
  <Grid container spacing={2}>
    {formFieldData.map((data) => {
      const { ComponentType, format, name, parse, type, validate, xs = 12 } = data;

      const errorObject = errorData === undefined ? {} : errorData;
      const fieldErrorData = errorObject[name] ? errorObject[name] : null;

      const rffFieldProps =
        (ComponentType === FormRadioButtons && { type }) ||
        (ComponentType === FormCheckBox && {
          type: true,
        });

      return (
        <Grid item key={name} xs={xs}>
          <Field
            format={format}
            formatOnBlur
            name={name}
            parse={parse}
            validate={validate}
            {...rffFieldProps}
          >
            {(props) => (
              <ComponentType
                {...data}
                {...props}
                fieldErrorData={fieldErrorData}
                onUpdate={onUpdate} // TODO: HB-1025: This "onUpdate" callback is deprecated and can be removed.
              />
            )}
          </Field>
        </Grid>
      );
    })}
  </Grid>
);

export default formFieldFactory;
