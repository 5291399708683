import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import AvailableWork from './AvailableWork';
import ScheduledWork from './ScheduledWork';
import WorkListHeader from './WorkListHeader';

const WorkList = ({ mobileHeaderRef }) => (
  <>
    <WorkListHeader mobileHeaderRef={mobileHeaderRef} />
    <Grid container direction="column" rowGap={2}>
      <ScheduledWork />
      <AvailableWork />
    </Grid>
  </>
);

WorkList.propTypes = {
  mobileHeaderRef: PropTypes.shape({}),
};

export default WorkList;
