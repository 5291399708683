import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCreateJob = (state) => get('createJob', state);

export const selectIsLoading = createSelector(selectCreateJob, (createJob) => createJob.isLoading);
export const selectIsLoadingPt = createSelector(
  selectCreateJob,
  (createJob) => createJob.isLoadingPt
);
export const selectIsLoadingCorporations = createSelector(
  selectCreateJob,
  (createJob) => createJob.selectIsLoadingCorporations
);
export const selectLocations = createSelector(selectCreateJob, (createJob) => createJob.locations);

export const selectFormData = createSelector(selectCreateJob, (createJob) => createJob.formData);

export const selectPositionTemplateData = createSelector(
  selectCreateJob,
  (createJob) => createJob.positionTemplateData
);

export const selectCorporations = createSelector(
  selectCreateJob,
  (createJob) => createJob.corporations
);

export const selectIsPosted = createSelector(selectCreateJob, (createJob) => createJob.isPosted);
