import FormTextField from '../../components/Form/FormTextField';
import { composeValidators, isValidPhoneOrEmail } from '../../components/Form/validations';

const formFieldData = [
  {
    ComponentType: FormTextField,
    fullWidth: true,
    label: 'Email or Phone Number',
    name: 'verificationType',
    required: true,
    variant: 'standard',
    validate: composeValidators(isValidPhoneOrEmail),
  },
];

export default formFieldData;
