import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

/**
 * This Rule is intended for directly setting the value of another field in our form framework.
 * The caller may choose between passing in newTargetValue or updateTargetValueCallback, but
 * at least one of them must be provided.
 *
 * @param {{}} props
 *  changedFieldName - The name of the field causing the change event
 *
 *  targetFieldName - Name of the field to be updated
 *
 *  newTargetValue - Value to set in the target field
 *
 *  updateTargetValueCallback - Function that will set value of the target field
 * @returns RFFFormSpy
 */
const ValueUpdateRule = ({
  changedFieldName,
  targetFieldName,
  newTargetValue,
  updateTargetValueCallback,
}) => (
  <Field name={targetFieldName} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
      <FormSpy subscription={{}}>
        {() => (
          <OnChange name={changedFieldName}>
            {/* FIXME: Make variable naming clear. this value that just got set. It's confusing with newFieldValue vs newTargetValue. */}
            {(newFieldValue) => {
              (newTargetValue && onChange(newTargetValue)) ||
                (updateTargetValueCallback && onChange(updateTargetValueCallback(newFieldValue)));
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

ValueUpdateRule.propTypes = {
  changedFieldName: PropTypes.string,
  targetFieldName: PropTypes.string,
  newTargetValue: PropTypes.string,
  updateTargetValueCallback: PropTypes.func,
};
export default ValueUpdateRule;
