import numeral from 'numeral';

import FormAutocompleteField from '../../../components/Form/FormAutocompleteField';
import FormCheckBox from '../../../components/Form/FormCheckBox';
import FormTextField from '../../../components/Form/FormTextField';
import { composeValidators, isCurrencyGreaterThan } from '../../../components/Form/validations';
import UserRole from '../../../constants/user-role';

const toDisabled = (field) => ({ ...field, disabled: true });

const toReadOnly = (field) => ({ ...field, readOnly: true });

const formatCurrency = (value) => (value === undefined ? '' : numeral(value).format('$0,0.00'));

const approved = {
  ComponentType: FormCheckBox,
  label: 'Approved',
  name: 'approved',
  variant: 'standard',
  fullWidth: true,
};

const billRateDT = {
  ComponentType: FormTextField,
  label: 'Double Time Bill Rate',
  name: 'billRateDT',
  format: (value) => formatCurrency(value),
  validate: composeValidators(isCurrencyGreaterThan(0)),
  placeholder: '$0.00',
  variant: 'standard',
  fullWidth: true,
};

const billRateOT = {
  ComponentType: FormTextField,
  label: 'Over Time Bill Rate',
  name: 'billRateOT',
  format: (value) => formatCurrency(value),
  validate: composeValidators(isCurrencyGreaterThan(0)),
  placeholder: '$0.00',
  variant: 'standard',
  fullWidth: true,
};

const billRateReg = {
  ComponentType: FormTextField,
  label: 'Bill Rate',
  name: 'billRateReg',
  format: (value) => formatCurrency(value),
  validate: composeValidators(isCurrencyGreaterThan(0)),
  placeholder: '$0.00',
  variant: 'standard',
  fullWidth: true,
};

const branch = {
  ComponentType: FormTextField,
  label: 'WAE Branch',
  name: 'branch',
  variant: 'standard',
  fullWidth: true,
};

const certification = (certifications) => ({
  ComponentType: FormAutocompleteField,
  key: 'id',
  label: 'Required Certifications',
  name: 'certifications',
  multiple: true,
  required: false,
  variant: 'standard',
  optionLabel: 'name',
  options: certifications,
  placeholder: 'Enter certification',
  fullWidth: true,
});

const corporationName = {
  ComponentType: FormTextField,
  label: 'Corporation',
  name: 'corporationName',
  variant: 'standard',
  fullWidth: true,
};

const description = {
  ComponentType: FormTextField,
  label: 'Description',
  name: 'description',
  required: true,
  variant: 'standard',
  fullWidth: true,
};

const location = (locations) => ({
  ComponentType: FormAutocompleteField,
  key: 'id',
  label: 'Location',
  name: 'locations',
  multiple: true,
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  options: locations,
  placeholder: 'Enter location',
  fullWidth: true,
});

const name = {
  ComponentType: FormTextField,
  label: 'Name',
  name: 'name',
  required: true,
  variant: 'standard',
  fullWidth: true,
};

const payRateDT = {
  ComponentType: FormTextField,
  label: 'Double Time Pay Rate',
  name: 'payRateDT',
  format: (value) => formatCurrency(value),
  validate: composeValidators(isCurrencyGreaterThan(0)),
  placeholder: '$0.00',
  variant: 'standard',
  fullWidth: true,
};

const payRateOT = {
  ComponentType: FormTextField,
  label: 'Over Time Pay Rate',
  name: 'payRateOT',
  format: (value) => formatCurrency(value),
  validate: composeValidators(isCurrencyGreaterThan(0)),
  placeholder: '$0.00',
  variant: 'standard',
  fullWidth: true,
};

const payRateReg = {
  ComponentType: FormTextField,
  label: 'Pay Rate',
  name: 'payRateReg',
  format: (value) => formatCurrency(value),
  validate: composeValidators(isCurrencyGreaterThan(0)),
  placeholder: '$0.00',
  variant: 'standard',
  fullWidth: true,
};

const saleRep = {
  ComponentType: FormTextField,
  label: 'Sales Rep',
  name: 'saleRep',
  variant: 'standard',
  fullWidth: true,
};

const workersCompCode = {
  ComponentType: FormTextField,
  label: 'Workers Comp Code',
  name: 'workersCompCode',
  variant: 'standard',
  fullWidth: true,
};

const adminFormFieldData = (certifications, locations) => [
  toReadOnly(name),
  toReadOnly(description),
  branch,
  location(locations),
  billRateReg,
  billRateDT,
  billRateOT,
  payRateReg,
  payRateDT,
  payRateOT,
  workersCompCode,
  saleRep,
  toReadOnly(corporationName),
  certification(certifications),
  approved,
];

const employerFormFieldData = [
  toReadOnly(name),
  toReadOnly(description),
  toReadOnly(billRateReg),
  toReadOnly(billRateDT),
  toReadOnly(billRateOT),
  toDisabled(approved),
];

const recruiterFormFieldData = [
  toReadOnly(name),
  toReadOnly(description),
  toReadOnly(billRateReg),
  toReadOnly(billRateDT),
  toReadOnly(billRateOT),
  toReadOnly(payRateReg),
  toReadOnly(payRateDT),
  toReadOnly(payRateOT),
  toReadOnly(workersCompCode),
  toReadOnly(saleRep),
  toReadOnly(corporationName),
  toDisabled(approved),
];

const getPositionTemplateFormFieldData = (userRole, certifications = [], locations = []) => {
  switch (userRole) {
    case UserRole.ADMIN:
      return adminFormFieldData(certifications, locations);
    case UserRole.EMPLOYER:
      return employerFormFieldData;
    case UserRole.RECRUITER:
      return recruiterFormFieldData;
    default:
      throw Error(`Invalid user role '${userRole}'`);
  }
};

export default getPositionTemplateFormFieldData;
