import React from 'react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';

import DashboardMenu from './DashboardMenu';
import JobsMenu from './JobsMenu';
import PositionsMenu from './PositionsMenu';
import PunchInOutMenu from './PunchInOutMenu';
import SettingsMenu from './SettingsMenu';

const DrawerMenus = ({ closeDrawer }) => (
  <div>
    <List component="nav">
      <DashboardMenu closeDrawer={closeDrawer} />
      <PunchInOutMenu closeDrawer={closeDrawer} />
      <PositionsMenu closeDrawer={closeDrawer} />
      <JobsMenu closeDrawer={closeDrawer} />
      <SettingsMenu closeDrawer={closeDrawer} />
    </List>
  </div>
);

DrawerMenus.propTypes = {
  closeDrawer: PropTypes.func,
};

export default DrawerMenus;
