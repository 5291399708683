import FormAutocompleteField from '../../../components/Form/FormAutocompleteField';
import FormSelectDropdownField from '../../../components/Form/FormSelectDropdownField';
import FormShiftPicker from '../../../components/Form/FormShiftPicker';
import FormTextField from '../../../components/Form/FormTextField';

// FIXME: HB-953 Update this to allow specific fields to be shown conditionally.
function getFormFieldData({
  isLoadingLocations,
  locations,
  isLoadingCertifications,
  certifications,
}) {
  return [
    {
      ComponentType: FormTextField,
      label: 'Position Name',
      name: 'name',
      required: true,
      variant: 'standard',
      fullWidth: true,
    },
    {
      ComponentType: FormSelectDropdownField,
      label: 'Location',
      name: 'location',
      multiple: true,
      required: true,
      variant: 'standard',
      isLoading: isLoadingLocations,
      options: locations,
      fullWidth: true,
    },
    {
      ComponentType: FormTextField,
      label: 'Description',
      name: 'description',
      required: true,
      variant: 'standard',
      fullWidth: true,
      multiline: true,
      maxRows: 5,
    },

    {
      ComponentType: FormAutocompleteField,
      label: 'Required Certifications',
      name: 'certification',
      multiple: true,
      required: false,
      variant: 'standard',
      isLoading: isLoadingCertifications,
      optionLabel: 'name',
      options: certifications,
      placeholder: 'Enter certification',
      fullWidth: true,
    },

    {
      ComponentType: FormShiftPicker,
      name: 'shifts',
      shiftTitle: 'Shifts',
      shiftDialogTitle: 'Add Shifts',
      shiftOpenDialogButtonVariant: 'outlined',
    },
  ];
}

export default getFormFieldData;
