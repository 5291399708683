import { createSlice } from '@reduxjs/toolkit';

import { clearLocalStorage, LOCAL_STORAGE_KEYS, setItemToLocalStorage } from '../../store/utils';

const initialState = {
  session: { user: null },
  snackbar: { isOpen: false, message: undefined },
};

export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    loginProcessed: (state, action) => {
      state.session.user = action.payload.data;
      setItemToLocalStorage(LOCAL_STORAGE_KEYS.userDataKey, action.payload.data);
    },
    logout: () => {
      clearLocalStorage();
    },
    hideSnackbar: (state) => {
      state.snackbar = {};
    },
    showSnackbar: (state, action) => {
      state.snackbar.isOpen = true;
      state.snackbar.message = action.payload.message;
    },
    updateUserData: (state, action) => {
      state.session.user = action.payload;
    },
    emailVerificationOrPWResetUpdateUserData: (state, action) => {
      state.session.user = action.payload.data;

      setItemToLocalStorage(LOCAL_STORAGE_KEYS.userDataKey, action.payload.data);
    },
    /* eslint-disable no-param-reassign */
  },
});

export const {
  loginProcessed,
  logout,
  readCandidateProcessedUpdateApp,
  hideSnackbar,
  showSnackbar,
  updateUserData,
  emailVerificationOrPWResetUpdateUserData,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
