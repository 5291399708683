import FormAutocompleteField from '../../../components/Form/FormAutocompleteField';
import FormTextField from '../../../components/Form/FormTextField';

import { fieldsJobDescription } from './formFieldData';

export const recruiterFormFieldData = [
  {
    ComponentType: FormAutocompleteField,
    label: 'Company',
    name: 'corporation',
    required: true,
    variant: 'standard',
    options: [],
    optionLabel: 'name',
    placeholder: 'Select a corporation',
    fullWidth: true,
  },
  ...fieldsJobDescription,
];

export const recruiterFormFieldDataConfirmation = recruiterFormFieldData.map((fieldData) => ({
  ComponentType: FormTextField,
  format: fieldData.format,
  label: fieldData.label,
  name: fieldData.name,
  readOnly: true,
  variant: 'standard',
  fullWidth: true,
  xs: fieldData.xs,
}));
