import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../components/Button';
import Dialog from '../../../../components/Dialog';
import {
  deleteIsProcessing,
  selectJobListRowData,
} from '../../../../store/selectors/jobListSelectors';
import { deleteJob } from '../../jobList';

const dialogWithin24HrBodyText = (
  <div>
    If any workers are placed on this scheduled job, relevant fees will be incurred for cancelling
    within twenty-four hours of their clock-in time. Please contact support.
  </div>
);

const dialogBodyText = <div>Are you sure you want to cancel this job?</div>;

const DeleteJob = ({ jobId, isLoading }) => {
  const dispatch = useDispatch();
  const isDeleteProcessing = useSelector(deleteIsProcessing);
  const jobListRowData = useSelector(selectJobListRowData);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [is24HRDeleteDialogOpen, setIs24HRDeleteDialogOpen] = useState(false);

  const handleClickDelete = useCallback(() => {
    const selectedData = jobListRowData.filter((row) => row.id === jobId);
    const timeDifference = selectedData[0].start - new Date().getTime();
    // Checks if the difference between job's start time and current time is greater than 24 hours.
    if (timeDifference > 86400000) {
      setIsDeleteDialogOpen(true);
    } else {
      setIs24HRDeleteDialogOpen(true);
    }
  }, [jobId, jobListRowData]);

  const handleJobDelete = useCallback(() => {
    dispatch(deleteJob({ id: jobId }));
    setIsDeleteDialogOpen(false);
  }, [dispatch, jobId]);

  const deleteDialogButtons = [
    <Button
      color="primary"
      text="Cancel"
      variant="text"
      key="handle-cancel-delete-button"
      onClick={() => setIsDeleteDialogOpen(false)}
    />,

    <Button
      color="primary"
      text="Confirm"
      variant="text"
      key="handle-confirm-delete-button"
      onClick={handleJobDelete}
      showIndeterminateProgress={isDeleteProcessing}
    />,
  ];

  const deleteWithin24HrDialogButtons = [
    <Button
      color="primary"
      text="Cancel"
      variant="text"
      key="handle-cancel-24HR-delete-button"
      onClick={() => setIs24HRDeleteDialogOpen(false)}
    />,
  ];

  return (
    <>
      <Button
        color="error"
        disabled={isLoading}
        text="Delete"
        variant="text"
        key="job-list-view-delete-button"
        onClick={handleClickDelete}
      />

      <Dialog
        open={isDeleteDialogOpen}
        dialogTitle="Cancel Up Coming Job?"
        dialogContentText={dialogBodyText}
        dialogActions={deleteDialogButtons}
        component="span"
        onBackdropClick={() => setIsDeleteDialogOpen(false)}
      />

      <Dialog
        open={is24HRDeleteDialogOpen}
        dialogTitle="Cancel Up Coming Job?"
        dialogContentText={dialogWithin24HrBodyText}
        dialogActions={deleteWithin24HrDialogButtons}
        component="span"
        onBackdropClick={() => setIs24HRDeleteDialogOpen(false)}
      />
    </>
  );
};

DeleteJob.propTypes = {
  jobId: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default DeleteJob;
