/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import Dialog from '../../../components/Dialog';
import { epochToTimeInReadableFormat as formatTime } from '../../../utils';

const ShiftDetailClockInConfirmDialog = ({
  placementId,
  tempClockInDateTime,
  open,
  setOpen,
  setClockedInDateTime,
}) => {
  const [confirmDialogText, setConfirmDialogText] = useState('');

  // update dialog text when tempClockInDateTime is updated
  useEffect(() => {
    const localTime = formatTime(tempClockInDateTime);
    const confirmText = `Clock-in and start working at ${localTime}?`;
    setConfirmDialogText(confirmText);
  }, [tempClockInDateTime]);

  // close dialog when clock in is cancelled
  const cancelClockIn = () => setOpen(false);

  const CancelDialogClockInOutButton = (
    <Button
      color="primary"
      variant="text"
      component="span"
      key="handle-cancel-clock-in-button"
      onClick={cancelClockIn}
    >
      Cancel
    </Button>
  );

  // TODO: Implement clock-in functionality
  const confirmClockIn = () => {
    const now = +new Date();
    setClockedInDateTime(now);

    const localTime = formatTime(now);
    alert(`SIMULATED: Clocked in for ${placementId} at ${localTime}`);

    setOpen(false);
  };

  const ConfirmDialogClockInButton = (
    <Button
      color="primary"
      variant="text"
      component="span"
      key="handle-confirm-clock-in-button"
      onClick={confirmClockIn}
    >
      Confirm
    </Button>
  );

  return (
    <Dialog
      open={open}
      dialogTitle="Clock-in to job"
      dialogContentText={confirmDialogText}
      dialogActions={[CancelDialogClockInOutButton, ConfirmDialogClockInButton]}
    />
  );
};

ShiftDetailClockInConfirmDialog.propTypes = {
  placementId: PropTypes.string,
  tempClockInDateTime: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setClockedInDateTime: PropTypes.func,
};

export default ShiftDetailClockInConfirmDialog;
