import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectForgotPassword = (state) => get('forgotMyPassword', state);

export const forgotMyPasswordIsProcessing = createSelector(
  selectForgotPassword,
  (forgotMyPassword) => forgotMyPassword.forgotIsProcessing
);

export const forgotMyPasswordIsNavigating = createSelector(
  selectForgotPassword,
  (forgotMyPassword) => forgotMyPassword.forgotIsNavigating
);
