import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { Box, Container } from '@mui/material';

import BottomAppBar from '../../../components/BottomAppBar/BottomAppBar';
import TopAppBar from '../../../components/TopAppBar/TopAppBar';
import theme from '../../../theme/theme';

const EmployeeNavigation = ({ mobileHeaderRef }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <TopAppBar mobileHeaderRef={mobileHeaderRef} />
    <Container sx={{ overflow: 'auto', flex: 1, padding: theme.spacing(2, 3, 2) }}>
      <Outlet />
    </Container>
    <BottomAppBar />
  </Box>
);

EmployeeNavigation.propTypes = {
  mobileHeaderRef: PropTypes.shape({}),
};

export default EmployeeNavigation;
