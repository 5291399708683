import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locations: [],
  isLoadingLocations: false,
  isLoading: false,
  certifications: [],
  isLoadingCertifications: false,
  errors: {},
};

export const createPositionTemplateSlice = createSlice({
  name: 'createPositionTemplateSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    getLocations: (state) => {
      state.isLoadingLocations = true;
    },
    getLocationsProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);
      const locations = map((ln) => {
        const { _id, ...rest } = ln;
        return {
          id: _id,
          name: `${get('description', ln)} - ${get('address', ln)}`,
          ...rest,
        };
      }, documents);

      state.locations = locations;
      state.isLoadingLocations = false;
    },
    getLocationsError: (state) => {
      state.isLoadingLocations = false;
    },
    postPositionTemplate: (state) => {
      state.isLoading = true;
    },
    postPositionTemplateProcessed: (state) => {
      state.errors = {};
      state.isLoading = false;
    },
    postPositionTemplateError: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload.data.error;
      const errors = {};

      errors.name = data.name ? data.name : null;
      errors.endTime = data.end ? data.end : null;

      state.errors = errors;
    },
    getCertifications: (state) => {
      state.isLoadingCertifications = true;
    },
    getCertificationsProcessed: (state, action) => {
      const { documents } = action.payload.data;
      const certifications = map(
        (certification) => ({
          id: get('_id', certification),
          name: get('name', certification),
        }),
        documents
      );
      state.certifications = certifications;
      state.isLoadingCertifications = false;
    },
    getCertificationsError: (state) => {
      state.certifications = [];
      state.isLoadingCertifications = false;
    },
  },
});

export const createPositionTemplateReducer = createPositionTemplateSlice.reducer;

export const {
  getLocations,
  getLocationsProcessed,
  getLocationsError,
  postPositionTemplate,
  postPositionTemplateProcessed,
  postPositionTemplateError,
  getCertifications,
  getCertificationsProcessed,
  getCertificationsError,
} = createPositionTemplateSlice.actions;
