/* eslint-disable import/prefer-default-export */
import get from 'lodash/fp/get';

export const calculateTotalPay = (groupPay) => {
  if (!groupPay) {
    return 'Error';
  }

  let payMessage;
  const min = get('min', groupPay, 'error');
  const max = get('max', groupPay, 'error');
  if (min === 'error' || max === 'error') {
    payMessage = 'Error';
  } else if (min === max) {
    payMessage = `$${max}`;
  } else {
    payMessage = `$${min}-$${max}`;
  }

  return payMessage;
};
