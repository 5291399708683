import React, { useCallback, useMemo } from 'react';
import map from 'lodash/fp/map';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '../../../components/Button';
import Form from '../../../components/Form';
import UserRole from '../../../constants/user-role';
import selectUser from '../../../store/selectors/appSelector';
import {
  requestIsProcessing,
  selectPositionTemplateListRowData,
} from '../../../store/selectors/positionTemplateListSelectors';
import { paperElevation } from '../../../theme/theme';
import { nameAndTimeToReadable } from '../../../utils';
import { updatePositionTemplate } from '../positionTemplateList';

import DeletePositionTemplate from './deletePositionTemplate';
import PositionTemplateDetailHeader from './PositionTemplateDetailHeader';
import getPositionTemplateFormFieldData from './positionTemplateFormFieldData';

const PositionTemplateDetailWrap = styled(Grid)(({ theme }) => ({
  alignContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(1, 1, 0),
}));

const PositionTemplateDetailContent = styled(Grid)(() => ({
  flex: 1,
  overflow: 'auto',
}));

const PositionTemplateDetailFooter = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'right',
  padding: theme.spacing(2),
}));

const formID = 'PositionTemplateDetail';

const PositionTemplateDetail = ({
  positionTemplateId: positionId,
  cleanPositionTemplateId: cleanPositionId,
  certifications: allCertifications,
  corporations: allCorporations,
  locations: allLocations,
}) => {
  const positionListRowData = useSelector(selectPositionTemplateListRowData);

  const selectedPosition = useMemo(
    () => positionListRowData.find(({ id }) => id === positionId),
    [positionListRowData, positionId]
  );

  const selectedData = useMemo(
    () =>
      selectedPosition
        ? {
            ...selectedPosition,
            certifications: selectedPosition.certifications.flatMap(
              (certification) => allCertifications.find((c) => c.id === certification) || []
            ),
            locations: selectedPosition.locations.flatMap(
              (location) => allLocations.find((l) => l.id === location) || []
            ),
            corporationName:
              allCorporations.find((corp) => corp.id === selectedPosition.corporation).name || '',
          }
        : null,
    [allCertifications, allCorporations, allLocations, selectedPosition]
  );

  const corporationLocations = useMemo(() => {
    if (!selectedData) return [];
    const { corporation } = selectedData;
    return allLocations.filter((l) => l.corporation === corporation);
  }, [allLocations, selectedData]);

  const user = useSelector(selectUser);
  let formFieldData = useMemo(
    () =>
      getPositionTemplateFormFieldData(user.role, allCertifications, corporationLocations) || [],
    [user, allCertifications, corporationLocations]
  );

  // sets initialValues to the form field
  if (selectedData) {
    formFieldData = formFieldData.map((field) => ({
      ...field,
      initialValue: selectedData[field.name],
    }));
  }

  const positionTemplateCertifications = (
    <div>
      <h5>Certifications</h5>
      <ul>
        {selectedData &&
          selectedData.certifications &&
          selectedData.certifications.map((certification) => (
            <li key={certification.id}>{certification.name}</li>
          ))}
      </ul>
    </div>
  );

  const positionTemplateLocations = (
    <div>
      <h5>Locations</h5>
      <ul>
        {selectedData &&
          selectedData.locations &&
          selectedData.locations.map((location) => (
            <li key={location.id}>{location.description}</li>
          ))}
      </ul>
    </div>
  );

  const positionTemplateShifts = (
    <div>
      <h5>Shifts</h5>
      <ul>
        {selectedData &&
          selectedData.shifts &&
          selectedData.shifts.map((shift) => (
            <li key={shift._id}>{nameAndTimeToReadable(shift.name, shift.start, shift.end)}</li>
          ))}
      </ul>
    </div>
  );

  const dispatch = useDispatch();
  const handlePositionTemplateUpdate = useCallback(
    (formData) => {
      const data = {
        id: positionId,
        fields: {
          approved: formData.approved,
          billRateDT: numeral(formData.billRateDT).value(),
          billRateOT: numeral(formData.billRateOT).value(),
          billRateReg: numeral(formData.billRateReg).value(),
          branch: formData.branch,
          certifications: map((cert) => cert.id, formData.certifications),
          locations: map((loc) => loc.id, formData.locations),
          payRateDT: numeral(formData.payRateDT).value(),
          payRateOT: numeral(formData.payRateOT).value(),
          payRateReg: numeral(formData.payRateReg).value(),
          saleRep: formData.saleRep,
          workersCompCode: formData.workersCompCode,
        },
      };
      dispatch(updatePositionTemplate(data));
    },
    [dispatch, positionId]
  );

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLoading = useSelector(requestIsProcessing);
  return (
    <Slide direction="left" in={positionId !== null} unmountOnExit timeout={200}>
      <Grid item md={4} xs sx={{ height: '100%' }}>
        <Paper elevation={mediumScreen ? paperElevation : 0} sx={{ height: '100%' }}>
          <PositionTemplateDetailWrap>
            <PositionTemplateDetailHeader cleanPositionTemplateId={cleanPositionId} />
            <PositionTemplateDetailContent>
              <Form
                formFieldData={formFieldData}
                formId={formID}
                onSubmit={handlePositionTemplateUpdate}
              />
              {user.role !== UserRole.ADMIN && positionTemplateCertifications}
              {user.role !== UserRole.ADMIN && positionTemplateLocations}
              {positionTemplateShifts}
            </PositionTemplateDetailContent>
            {[UserRole.RECRUITER, UserRole.ADMIN].includes(user.role) && (
              <PositionTemplateDetailFooter>
                <DeletePositionTemplate positionTemplateId={positionId} isLoading={isLoading} />
                {user.role === UserRole.ADMIN && (
                  <Button
                    form={formID}
                    key="position-template-update-button"
                    showIndeterminateProgress={isLoading}
                    disabled={isLoading}
                    text="Update"
                    type="submit"
                    variant="text"
                  />
                )}
              </PositionTemplateDetailFooter>
            )}
          </PositionTemplateDetailWrap>
        </Paper>
      </Grid>
    </Slide>
  );
};
PositionTemplateDetail.propTypes = {
  positionTemplateId: PropTypes.string,
  cleanPositionTemplateId: PropTypes.func,
  certifications: PropTypes.arrayOf(PropTypes.shape({})),
  corporations: PropTypes.arrayOf(PropTypes.shape({})),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
};
export default PositionTemplateDetail;
