import React from 'react';
import PropTypes from 'prop-types';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const RootGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  margin: theme.spacing(0, 0, 2),
}));

const JobDetailHeader = ({ cleanJobId }) => (
  <RootGrid container>
    <Typography>Job Order Details</Typography>
    <IconButton color="inherit" aria-label="close job update" onClick={() => cleanJobId()}>
      <CloseRoundedIcon />
    </IconButton>
  </RootGrid>
);

JobDetailHeader.propTypes = {
  cleanJobId: PropTypes.func,
};

export default JobDetailHeader;
