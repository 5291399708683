import React, { memo, useCallback, useEffect } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Form from '../../components/Form';
import FormPasswordField from '../../components/Form/FormPasswordField';
import selectVerifyingIsProcessing from '../../store/selectors/accountVerificationSelector';
import selectUser from '../../store/selectors/appSelector';
import { showSnackbar } from '../app/reducer';

import { passwordReset, verifyAccount } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const PasswordVerificationOrResetPage = memo(({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isProcessing = useSelector(selectVerifyingIsProcessing);

  const [searchParams] = useSearchParams();
  const emailQuery = searchParams.get('email');
  const verificationCodeQuery = searchParams.get('verificationCode');
  const recoveryCodeQuery = searchParams.get('recoveryCode');
  const user = useSelector(selectUser);
  const token = get('token', user);

  useEffect(() => {
    if (token) {
      navigate('dashboard');
    }
  }, [dispatch, navigate, token, user]);

  const handleOnSubmit = useCallback(
    (values) => {
      // TODO: HB-652
      if (values.newPassword !== values.confirmPassword) {
        dispatch(showSnackbar({ message: 'Passwords Do Not match!' }));
        return;
      }
      // TODO: HB-652
      if (values.newPassword.length < 9) {
        dispatch(showSnackbar({ message: 'Password is too short!' }));
        return;
      }
      const verifyAccBody = {
        email: emailQuery,
        verificationCode: verificationCodeQuery,
        newPassword: values.newPassword,
      };

      const resetPassBody = {
        email: emailQuery,
        recoveryCode: recoveryCodeQuery,
        password: values.newPassword,
      };
      if (type === 'passwordReset') {
        dispatch(passwordReset(resetPassBody));
        return;
      }
      if (type === 'accountVerification') {
        dispatch(verifyAccount(verifyAccBody));
      }
    },
    [dispatch, emailQuery, verificationCodeQuery, recoveryCodeQuery, type]
  );

  const handleOnCancel = () => navigate('/');

  const buttonData = [
    {
      color: 'primary',
      disabled: !!isProcessing,
      fullWidth: true,
      showIndeterminateProgress: !!isProcessing,
      text: 'Set Password',
      type: 'submit',
      variant: 'contained',
      xs: 12,
    },
    {
      color: 'primary',
      fullWidth: true,
      onClick: handleOnCancel,
      text: 'Cancel',
      variant: 'text',
      xs: 12,
    },
  ];

  const formFieldData = [
    {
      ComponentType: FormPasswordField,
      fullWidth: true,
      label: 'New Password',
      name: 'newPassword',
      required: true,
      type: 'password',
      variant: 'standard',
    },
    {
      ComponentType: FormPasswordField,
      fullWidth: true,
      label: 'Confirm Password',
      name: 'confirmPassword',
      required: true,
      type: 'password',
      variant: 'standard',
    },
  ];

  return (
    <RootGrid container>
      <FormGrid item xs={10} sm={5} md={4} lg={3}>
        <Typography variant="h3" align="center" sx={{ paddingBottom: 5 }}>
          {type === 'passwordReset' ? 'Reset Password' : 'Set New Password'}
        </Typography>
        <Form buttonData={buttonData} formFieldData={formFieldData} onSubmit={handleOnSubmit} />
      </FormGrid>
    </RootGrid>
  );
});

PasswordVerificationOrResetPage.propTypes = {
  type: PropTypes.string,
};
export default PasswordVerificationOrResetPage;
