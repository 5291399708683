import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locations: [],
  positionTemplateData: [],
  corporations: [],
  formData: null,
  isLoading: false,
  isLoadingPt: false,
  isLoadingLocation: false,
  isLoadingCorporations: false,
  isPosted: false,
};

export const createJobSlice = createSlice({
  name: 'createJobSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setJobCreateInitState: () => initialState,

    updateFormData: (state, action) => {
      state.formData = action.payload;
    },
    postJob: (state) => {
      state.isLoading = true;
    },
    postJobProcessed: (state) => {
      state.isLoading = false;
      state.isPosted = true;
    },
    postJobError: (state) => {
      state.isLoading = false;
    },
    getPositionTemplate: (state) => {
      state.isLoadingPt = true;
    },
    getPositionTemplateProcessed: (state, action) => {
      state.isLoadingPt = false;
      const documents = get(['payload', 'data', 'documents'], action);

      // const positionTemplateSelection = (action.payload.data || {}).documents || [];
      documents.sort(
        (a, b) =>
          (a.approved === b.approved && a.name.localeCompare(b.name)) || (a.approved && -1) || 1
      );

      const positionTemplateData = map((pt) => {
        const { _id, ...rest } = pt;
        return {
          id: _id,
          ...rest,
        };
      }, documents);

      state.positionTemplateData = positionTemplateData;
    },
    getPositionTemplateError: (state) => {
      state.isLoadingPt = false;
    },
    getCorporations: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsProcessed: (state, action) => {
      state.isLoadingCorporations = false;
      const documents = get(['payload', 'data', 'documents'], action);

      state.corporations = map((corp) => ({ ...corp, id: corp._id }), documents);
    },
    getCorporationsError: (state) => {
      state.isLoadingCorporations = false;
    },
    getLocations: (state) => {
      state.isLoadingLocation = true;
    },
    getLocationsProcessed: (state, action) => {
      state.isLoadingLocation = false;

      const documents = get(['payload', 'data', 'documents'], action);
      const locations = map((ln) => {
        const { _id, ...rest } = ln;

        return {
          id: _id,
          ...rest,
        };
      }, documents);

      state.locations = locations;
    },
    getLocationsError: (state) => {
      state.isLoadingLocation = false;
    },
  },
});

export const createJobReducer = createJobSlice.reducer;

export const {
  setJobCreateInitState,
  updateFormData,
  postJob,
  postJobProcessed,
  postJobError,
  getPositionTemplate,
  getPositionTemplateProcessed,
  getPositionTemplateError,
  getCorporations,
  getCorporationsProcessed,
  getCorporationsError,
  getLocations,
  getLocationsProcessed,
  getLocationsError,
} = createJobSlice.actions;
