import * as React from 'react';
import PropTypes from 'prop-types';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as ResponsiveTimePicker } from '@mui/x-date-pickers/TimePicker';

const TimePickerField = ({
  error,
  fullWidth,
  label,
  minTime,
  name,
  onChange,
  readOnly,
  required,
  sx,
  value,
  variant,
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} component="span">
    <Stack component="span">
      <ResponsiveTimePicker
        desktopModeMediaQuery="@media (min-width: 900px)" // TODO: HB-952 discuss min-width
        label={label}
        name={name}
        minTime={minTime}
        onChange={onChange}
        OpenPickerButtonProps={{ sx: { marginRight: 0, marginBottom: 1 } }}
        readOnly={readOnly}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            fullWidth={fullWidth}
            helperText={error || null}
            required={required}
            sx={sx}
            variant={variant}
          />
        )}
        value={value}
      />
    </Stack>
  </LocalizationProvider>
);
TimePickerField.propTypes = {
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  minTime: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  variant: PropTypes.string,
};
export default TimePickerField;
