import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { errorHandler } from './formUtils';

const FormCheckBox = ({
  disabled,
  fieldErrorData,
  input,
  label,
  meta,
  size,
  required,
  sx,
  variant,
}) => {
  const error = errorHandler(input, meta, fieldErrorData);
  return (
    <FormControl required={required} error={!!error} disabled={disabled} variant={variant}>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value || false}
            name={input.name}
            onChange={input.onChange}
            size={size}
            // defaultChecked commented for future use
          />
        }
        label={label}
        sx={sx}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

FormCheckBox.propTypes = {
  disabled: PropTypes.bool,
  fieldErrorData: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }),
  label: PropTypes.string,
  meta: PropTypes.shape(),
  required: PropTypes.bool,
  size: PropTypes.string, // default size is medium
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default FormCheckBox;
