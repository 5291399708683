import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

const WorkDetailDescription = ({ description }) => (
  <>
    <Typography fontWeight="bold" fontSize="large">
      Description
    </Typography>
    <Typography>{description}</Typography>
  </>
);

WorkDetailDescription.propTypes = {
  description: PropTypes.string,
};

export default WorkDetailDescription;
