import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../components/Button';
import Dialog from '../../../../components/Dialog';
import { requestIsProcessing } from '../../../../store/selectors/positionTemplateListSelectors';
import { deletePositionTemplate } from '../../positionTemplateList';

const dialogBodyText = <div>Are you sure you want to delete this position?</div>;

const DeletePositionTemplate = ({ positionTemplateId, isLoading }) => {
  const dispatch = useDispatch();
  const isDeleteProcessing = useSelector(requestIsProcessing);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleClickDelete = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, []);

  const handlePositionTemplateDelete = useCallback(() => {
    dispatch(deletePositionTemplate({ id: positionTemplateId }));
    setIsDeleteDialogOpen(false);
  }, [dispatch, positionTemplateId]);

  const deleteDialogButtons = [
    <Button
      color="primary"
      text="Cancel"
      variant="text"
      key="handle-cancel-delete-button"
      onClick={() => setIsDeleteDialogOpen(false)}
    />,

    <Button
      color="primary"
      text="Confirm"
      variant="text"
      key="handle-confirm-delete-button"
      onClick={handlePositionTemplateDelete}
      showIndeterminateProgress={isDeleteProcessing}
    />,
  ];

  return (
    <>
      <Button
        color="error"
        disabled={isLoading}
        text="Delete"
        variant="text"
        key="position-list-view-delete-button"
        onClick={handleClickDelete}
      />

      <Dialog
        open={isDeleteDialogOpen}
        dialogTitle="Delete Position?"
        dialogContentText={dialogBodyText}
        dialogActions={deleteDialogButtons}
        component="span"
        onBackdropClick={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

DeletePositionTemplate.propTypes = {
  positionTemplateId: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default DeletePositionTemplate;
