import React, { useCallback } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Form from '../../components/Form';
import FormSelectDropdownField from '../../components/Form/FormSelectDropdownField';
import FormTextField from '../../components/Form/FormTextField';
import selectIsLoading from '../../store/selectors/registerSelectors';
import launchDarklyToggles from '../../utils/launchDarklyToggles';

import { register } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const Register = ({ flags }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);

  const isRegisterEmployeeEnabledOnSignUp = launchDarklyToggles(
    flags,
    'isRegisterEmployeeEnabledOnSignUp'
  );
  const isRegisterEmployerEnabledOnSignUp = launchDarklyToggles(
    flags,
    'isRegisterEmployerEnabledOnSignUp'
  );

  const handleFormSubmit = useCallback(
    (values) => {
      if (values.userRole.name === 'Employer') {
        dispatch(register({ fields: { email: values.email, name: values.userRole.name } }));
      }
    },
    [dispatch]
  );

  const buttonData = [
    {
      color: 'primary',
      disabled: !!isLoading,
      fullWidth: true,
      showIndeterminateProgress: !!isLoading,
      text: 'Sign up',
      type: 'submit',
      variant: 'contained',
      xs: 12,
    },
    {
      color: 'primary',
      disabled: !!isLoading,
      fullWidth: true,
      onClick: () => navigate('/'),
      text: 'Sign In',
      variant: 'outlined',
      xs: 12,
    },
  ];

  const formFieldData = [
    {
      ComponentType: FormTextField,
      fullWidth: true,
      label: 'Email address',
      name: 'email',
      required: true,
      type: 'password',
      variant: 'standard',
    },
    {
      ComponentType: FormSelectDropdownField,
      fullWidth: true,
      label: 'Looking for',
      name: 'userRole',
      required: true,
      variant: 'standard',
      options: [
        { id: 'Employer', name: 'Employer', disabled: !isRegisterEmployerEnabledOnSignUp },
        { id: 'Employee', name: 'Employee', disabled: !isRegisterEmployeeEnabledOnSignUp },
      ],
    },
  ];

  return (
    <RootGrid container>
      <FormGrid item xs={10} sm={5} md={4} lg={3}>
        <Typography variant="h3" align="center" sx={{ paddingBottom: 5 }}>
          Sign Up
        </Typography>
        <Form buttonData={buttonData} formFieldData={formFieldData} onSubmit={handleFormSubmit} />
      </FormGrid>
    </RootGrid>
  );
};

Register.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(Register);
