import React from 'react';
import PropTypes from 'prop-types';

import { Button as MuiButton } from '@mui/material/';
import CircularProgress from '@mui/material/CircularProgress';

const Button = ({
  children,
  color,
  disabled,
  form,
  fullWidth,
  onClick,
  showIndeterminateProgress,
  size,
  sx,
  text,
  type,
  variant,
}) => (
  <MuiButton
    disabled={disabled}
    color={color}
    form={form}
    fullWidth={fullWidth}
    onClick={onClick}
    size={size}
    sx={{ borderRadius: '5px', ...sx }} // TODO: HB-957 refactor magic numbers to theme in shared buttons
    type={type}
    variant={variant}
  >
    {text} {children}
    {showIndeterminateProgress && (
      <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
        }}
      />
    )}
  </MuiButton>
);

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  showIndeterminateProgress: PropTypes.bool,
  size: PropTypes.string,
  sx: PropTypes.shape({}),
  text: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

export default Button;
