import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4D2C66',
    },
    secondary: {
      main: '#351E47',
    },
    subtext: {
      main: '#777777',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#a19f9d',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  button: {
    cancel: {
      color: '#705684',
    },
  },
});

export default theme;

export const drawerWidth = '240px';
export const drawerWidthInternal = drawerWidth - 1;
export const appBarHeight = '64px';
export const groupListCompanyImageSize = '64px';
export const jobListHeaderHeight = '40px';
export const positionTemplateListHeaderHeight = '40px';
export const paperElevation = 1;
