import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Form from '../../components/Form';
import { isValidEmailBoolean } from '../../components/Form/validations';
import {
  forgotMyPasswordIsNavigating,
  forgotMyPasswordIsProcessing,
} from '../../store/selectors/forgotMyPasswordSelector';
import { showSnackbar } from '../app/reducer';

import formFieldData from './formFieldData';
import { forgotPasswordNavigated, postForgotPassword } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const ForgotMyPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(forgotMyPasswordIsProcessing);
  const isNavigating = useSelector(forgotMyPasswordIsNavigating);

  useEffect(() => {
    if (isNavigating) {
      navigate('/');
      dispatch(forgotPasswordNavigated());
    }
  }, [dispatch, navigate, isNavigating]);

  const handleFormSubmit = useCallback(
    (values) => {
      const dispatchValue = isValidEmailBoolean(values.verificationType)
        ? { fields: { email: values.verificationType } }
        : dispatch(
            showSnackbar({
              message:
                'Mobile is currently not implemented, please enter an email address instead.', // FIXME: HB-943 Change to allow mobile when mobile feature is enabled
            })
          );

      dispatchValue && dispatch(postForgotPassword(dispatchValue));
    },
    [dispatch]
  );

  const buttonData = [
    {
      color: 'primary',
      disabled: !!isLoading,
      fullWidth: true,
      showIndeterminateProgress: !!isLoading,
      text: 'Submit',
      type: 'submit',
      variant: 'contained',
      xs: 12,
    },
    {
      color: 'primary',
      disabled: !!isLoading,
      fullWidth: true,
      onClick: () => navigate('/'),
      text: 'Cancel',
      variant: 'outlined',
      xs: 12,
    },
  ];

  return (
    <RootGrid container>
      <FormGrid item xs={10} sm={5} md={4} lg={3}>
        <Typography variant="h3" align="center" sx={{ paddingBottom: 5 }}>
          Forgot My Password
        </Typography>
        <Form
          buttonData={buttonData}
          formFieldData={formFieldData}
          onSubmit={handleFormSubmit}
          initialValues={{ verificationType: '' }}
        />
      </FormGrid>
    </RootGrid>
  );
};

export default ForgotMyPassword;
