import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Grid from '@mui/material/Grid';
import MobileStepper from '@mui/material/MobileStepper';
import { styled } from '@mui/material/styles';

import Button from '../../../components/Button';
import { selectIsLoading } from '../../../store/selectors/createJobSelectors';
import { appBarHeight } from '../../../theme/theme';

import HeaderCreateJob from './HeaderCreateJob';
import JobConfirmation from './JobConfirmation';
import JobDescription from './JobDescription';
import { setJobCreateInitState } from './reducer';

const RootGrid = styled(Grid)(() => ({
  justifyContent: 'center',
  height: `calc(100vh - ${appBarHeight})`,
}));

const FormGrid = styled(Grid)(({ theme }) => ({
  direction: 'column',
  justifyContent: 'center',
  alignContent: 'space-between',
  padding: theme.spacing(3, 2, 5),
  rowGap: theme.spacing(3),
}));

const formId = ['JobDescription', 'JobConfirmation']; // FIXME: HB-947/HB-948  find better way to create id

const CreateJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);
  const maxSteps = formId.length;
  const [activeStep, setActiveStep] = useState(0);

  useEffect(
    () => () => {
      dispatch(setJobCreateInitState());
    },
    [dispatch]
  );

  const handleNext = useCallback(() => {
    if (activeStep <= maxSteps) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [activeStep, maxSteps]);

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      navigate('/dashboard');
    } else setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [navigate, activeStep]);

  const slidePages = useMemo(
    () => [
      {
        component: <JobDescription formId="JobDescription" onNext={handleNext} />,
        label: 'Post New Job',
      },
      {
        component: <JobConfirmation formId="JobConfirmation" nav={navigate} />,
        label: 'Confirmation',
      },
    ],
    [handleNext, navigate]
  );

  return (
    <RootGrid container>
      <FormGrid item container xs={12} md={7}>
        <HeaderCreateJob
          header={slidePages[activeStep].label}
          nextPageHeader={
            (activeStep === maxSteps - 1 && 'Post Job ') ||
            `Next: ${slidePages[activeStep + 1].label}`
          }
        />
        <SwipeableViews slideStyle={{ overflow: 'visible' }} index={activeStep} disabled>
          {slidePages.map((step) => (
            <Grid key={step.label}>{step.component}</Grid>
          ))}
        </SwipeableViews>

        <MobileStepper
          style={{
            width: '100%',
            padding: 0,
          }}
          steps={maxSteps}
          activeStep={activeStep}
          position="static"
          nextButton={
            <Button
              type="submit"
              form={formId[activeStep]}
              color="primary"
              variant="contained"
              showIndeterminateProgress={!!isLoading}
              disabled={!!isLoading}
            >
              {activeStep === maxSteps - 1 ? (
                'Post Job'
              ) : (
                <>
                  Next <KeyboardArrowRight />
                </>
              )}
            </Button>
          }
          backButton={
            <Button onClick={handleBack} color="primary" variant="outlined" disabled={!!isLoading}>
              {activeStep !== 0 && <KeyboardArrowLeft />}
              {activeStep === 0 ? 'Cancel' : 'Back'}
            </Button>
          }
        />
      </FormGrid>
    </RootGrid>
  );
};

export default CreateJob;
