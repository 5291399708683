import { get, getOr } from 'lodash/fp';

const launchDarklyToggles = (flags, toggleName) =>
  // FIXME: HB-897 remove inverted values on the disables
  get(toggleName, {
    isSideBarDashboardButtonEnabled: getOr(false, 'dashboardToggle', flags),
    // Enables or disables dashboard navigation on sidebar menu
    // toggles between true or false
    // By default, dashboard select will be disabled, darkly return value: false.

    isRegisterEmployerEnabledOnSignUp: getOr(true, 'registerEmployer', flags),
    // Enables or disables employer select option on register page
    // toggles between true or false
    // By default, employer registration will be enabled, darkly return value: true.

    isRegisterEmployeeEnabledOnSignUp: getOr(false, 'registerEmployee', flags),
    // Enables or disables employee select option on register page
    // toggles between true or false
    // By default, employee registration will be disabled, darkly return value: false.

    isMaintenanceModeEnabled: getOr(false, 'maintenanceMode', flags), // toggles between true or false
    // By default, the maintenance page will be disabled, darkly return value: (false).
  });

export default launchDarklyToggles;
