import React from 'react';
import PropTypes from 'prop-types';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { drawerWidth, drawerWidthInternal } from '../../theme/theme';

import DrawerMenus from './Drawer/DrawerMenus';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const SideBar = ({ toggleDrawer, closeDrawer, isOpen }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidthInternal,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidthInternal,
            boxSizing: 'border-box',
          },
        }}
        mode="push"
        open={isOpen}
        onClose={toggleDrawer()}
        onKeyDown={toggleDrawer()}
        variant={largeScreen ? 'persistent' : 'temporary'}
        PaperProps={{ elevation: 3 }}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer()}>
            <ChevronLeftIcon color="primary" />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div style={{ width: drawerWidth }} role="presentation" onKeyDown={closeDrawer}>
          <DrawerMenus closeDrawer={closeDrawer} />
        </div>
      </Drawer>
      <DrawerHeader />
    </>
  );
};

SideBar.propTypes = {
  toggleDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  isOpen: PropTypes.bool,
};

SideBar.defaultProps = {
  toggleDrawer: () => {},
  closeDrawer: () => {},
  isOpen: false,
};

export default SideBar;
