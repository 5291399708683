import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { errorHandler } from './formUtils';

const FormPasswordField = ({
  fieldErrorData,
  fullWidth,
  input,
  label,
  meta,
  placeholder,
  required,
  sx,
  type,
  variant,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const error = errorHandler(input, meta, fieldErrorData);

  return (
    <TextField
      error={!!error}
      fullWidth={fullWidth}
      helperText={error || null}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="large" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={label}
      name={input.name}
      onChange={input.onChange}
      placeholder={placeholder}
      required={required}
      sx={sx}
      type={!showPassword ? type : 'text'}
      variant={variant}
      value={input.value || ''}
    />
  );
};

FormPasswordField.propTypes = {
  fullWidth: PropTypes.bool,
  input: PropTypes.shape(),
  label: PropTypes.string,
  meta: PropTypes.shape(),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  fieldErrorData: PropTypes.string,
};

export default FormPasswordField;
