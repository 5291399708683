import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '../../Button';

import ConfirmationModalStyle from './styles';

const ConfirmationModal = ({ data, isOpen, onConfirm, setIsOpen, submitting }) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Modal
      sx={{ overflow: 'scroll' }}
      open={isOpen}
      onClose={() => !submitting && setIsOpen(false)}
    >
      <Grid
        container
        direction="column"
        sx={{
          ...ConfirmationModalStyle,
          width: mediumScreen ? '50%' : '90%',
        }}
        data-testid="confirmation-modal-data"
      >
        <Typography variant="h4" align="left">
          Confirm order?
        </Typography>
        <List dense>
          {data.map((d) => (
            <ListItem disablePadding key={d}>
              <ListItemText primary={<Typography variant="subtitle1">{d}</Typography>} />
            </ListItem>
          ))}
        </List>
        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Button
            color="primary"
            disabled={submitting}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={submitting}
            showIndeterminateProgress={submitting}
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  setIsOpen: PropTypes.func,
  submitting: PropTypes.bool,
};

export default ConfirmationModal;
