import * as React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const WorkDetailShiftTable = ({ children }) => (
  <>
    <TableContainer component={Box}>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              th: { fontWeight: 'bold', whiteSpace: 'nowrap' },
            }}
          >
            <TableCell sx={{ paddingLeft: 0 }}>Day</TableCell>
            <TableCell align="center">Clock-in</TableCell>
            <TableCell align="center">Clock-out</TableCell>
            <TableCell sx={{ paddingLeft: 0, paddingRight: 0 }} />
          </TableRow>
        </TableHead>
        {children}
      </Table>
    </TableContainer>
  </>
);

WorkDetailShiftTable.propTypes = {
  children: PropTypes.shape(),
};

export default WorkDetailShiftTable;
