import { combineReducers } from 'redux';

import { appReducer, logout } from '../views/app';
import { forgotMyPasswordReducer } from '../views/forgotMyPassword';
import { createJobReducer } from '../views/jobs/createJob';
import { createJobsReducer } from '../views/jobs/createJobs';
import { createLongTermJobReducer } from '../views/jobs/createLongTermJob';
import { jobListReducer } from '../views/jobs/jobList';
import { loginReducer } from '../views/login';
import { passwordUpdateReducer } from '../views/passwordUpdate';
import { accountVerificationReducer } from '../views/PasswordVerificationOrReset';
import { createPositionTemplateReducer } from '../views/positionTemplate/createPositionTemplate';
import { positionTemplateListReducer } from '../views/positionTemplate/positionTemplateList';
import { registerReducer } from '../views/register/reducer';
import { employeeWorkReducer } from '../views/work/reducer';

const allReducers = combineReducers({
  app: appReducer,
  forgotMyPassword: forgotMyPasswordReducer,
  login: loginReducer,
  register: registerReducer,
  passwordUpdate: passwordUpdateReducer,
  createJob: createJobReducer,
  createJobs: createJobsReducer,
  createLongTermJob: createLongTermJobReducer,
  jobList: jobListReducer,
  employeeWork: employeeWorkReducer,
  createPositionTemplate: createPositionTemplateReducer,
  positionTemplateList: positionTemplateListReducer,
  accountVerification: accountVerificationReducer,
});

const createRootReducer = (state, action) => {
  if (action.type === logout.type) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};
export default createRootReducer;
