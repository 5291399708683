import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locations: [],
  positionTemplateData: [],
  corporations: [],
  isProcessingPostJob: false,
  isLoadingPt: false,
  isLoadingLocations: false,
  isLoadingCorporations: false,
};

export const createLongTermJobSlice = createSlice({
  name: 'createLongTermJobSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setJobCreateInitState: () => initialState,

    postJob: (state) => {
      state.isProcessingPostJob = true;
    },
    postJobProcessed: (state) => {
      state.isProcessingPostJob = false;
    },
    postJobError: (state) => {
      state.isProcessingPostJob = false;
    },
    getPositionTemplate: (state) => {
      state.isLoadingPt = true;
    },
    getPositionTemplateProcessed: (state, action) => {
      state.isLoadingPt = false;
      const documents = get(['payload', 'data', 'documents'], action);

      documents.sort(
        (a, b) =>
          (a.approved === b.approved && a.name.localeCompare(b.name)) || (a.approved && -1) || 1
      );

      const positionTemplateData = map((pt) => {
        const { _id, ...rest } = pt;
        return {
          id: _id,
          ...rest,
        };
      }, documents);

      state.positionTemplateData = positionTemplateData;
    },
    getPositionTemplateError: (state) => {
      state.isLoadingPt = false;
    },
    getCorporations: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsProcessed: (state, action) => {
      state.isLoadingCorporations = false;
      const documents = get(['payload', 'data', 'documents'], action);

      state.corporations = map((corp) => ({ ...corp, id: corp._id }), documents);
    },
    getCorporationsError: (state) => {
      state.isLoadingCorporations = false;
    },
    getLocations: (state) => {
      state.isLoadingLocations = true;
    },
    getLocationsProcessed: (state, action) => {
      state.isLoadingLocations = false;

      const documents = get(['payload', 'data', 'documents'], action);
      const locations = map((ln) => {
        const { _id, ...rest } = ln;

        return {
          id: _id,
          ...rest,
        };
      }, documents);

      state.locations = locations;
    },
    getLocationsError: (state) => {
      state.isLoadingLocations = false;
    },
  },
});

export const createLongTermJobReducer = createLongTermJobSlice.reducer;

export const {
  setJobCreateInitState,
  postJob,
  postJobProcessed,
  postJobError,
  getPositionTemplate,
  getPositionTemplateProcessed,
  getPositionTemplateError,
  getCorporations,
  getCorporationsProcessed,
  getCorporationsError,
  getLocations,
  getLocationsProcessed,
  getLocationsError,
} = createLongTermJobSlice.actions;
