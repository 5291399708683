/* eslint-disable no-alert */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid } from '@mui/material';

import ShiftDetailClockInConfirmDialog from './ShiftDetailClockInConfirmDialog';

const ShiftDetailClockInOut = ({ placementId, clockedInDateTime, setClockedInDateTime }) => {
  const [tempClockInDateTime, setTempClockInDateTime] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // TODO: Disable button based on time delta from beginning / ending of shift
  const clockInOutDisabled = !!clockedInDateTime;

  const openClockInConfirmDialog = () => {
    const now = +new Date();
    setTempClockInDateTime(now);
    setConfirmDialogOpen(true);
  };

  const clockInOutText = clockedInDateTime ? 'Clock out' : 'Clock in';

  return (
    <Grid item>
      <Button
        variant="contained"
        fullWidth
        disabled={clockInOutDisabled}
        onClick={openClockInConfirmDialog}
      >
        {clockInOutText}
      </Button>

      <ShiftDetailClockInConfirmDialog
        placementId={placementId}
        setClockedInDateTime={setClockedInDateTime}
        tempClockInDateTime={tempClockInDateTime}
        open={confirmDialogOpen}
        setOpen={setConfirmDialogOpen}
      />
    </Grid>
  );
};

ShiftDetailClockInOut.propTypes = {
  placementId: PropTypes.string,
  clockedInDateTime: PropTypes.number,
  setClockedInDateTime: PropTypes.func,
};

export default ShiftDetailClockInOut;
