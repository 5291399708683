import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';
import { Grid, Portal } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import WhiteLogo from '../../../assets/WAE_logo_WHT_F.svg';

const WorkListHeader = ({ mobileHeaderRef }) => {
  const { current: container } = mobileHeaderRef;

  return (
    <Portal container={container}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <img src={WhiteLogo} alt="WAE" height={50} width={50} />
      </Grid>
      <IconButton
        color="inherit"
        aria-label="search"
        // eslint-disable-next-line no-alert
        onClick={() => alert('search not implemented')}
        edge="start"
        sx={{ mr: 2 }}
      >
        <SearchIcon />
      </IconButton>
    </Portal>
  );
};

WorkListHeader.propTypes = {
  mobileHeaderRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

export default WorkListHeader;
