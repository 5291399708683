import { get } from 'lodash/fp';

import FormDatePickerField from '../../../../components/Form/FormDatePickerField';
import FormSelectDropdownField from '../../../../components/Form/FormSelectDropdownField';
import FormTextField from '../../../../components/Form/FormTextField';
import {
  composeValidators,
  isDateEqualOrGreaterThanToday,
  isGreaterThan,
  isInteger,
  isValidDate,
} from '../../../../components/Form/validations';
import { reformatDateMMMMDDYYYY } from '../../../../utils';

export const fieldsJobDescription = [
  {
    ComponentType: FormSelectDropdownField,
    label: 'Position',
    name: 'position',
    readOnlyFormat: (value) => get('name', value),
    required: true,
    variant: 'standard',
    options: [],
    fullWidth: true,
  },
  {
    ComponentType: FormSelectDropdownField,
    label: 'Location',
    name: 'location',
    readOnlyFormat: (value) => get('name', value),
    required: true,
    variant: 'standard',
    options: [],
    fullWidth: true,
  },
  {
    ComponentType: FormSelectDropdownField,
    label: 'Shift',
    name: 'shift',
    readOnlyFormat: (value) => get('name', value),
    required: true,
    variant: 'standard',
    options: [],
    fullWidth: true,
  },
  {
    ComponentType: FormTextField,
    label: 'Openings',
    name: 'numOpenings',
    required: true,
    validate: composeValidators(isInteger, isGreaterThan(0)),
    variant: 'standard',
    fullWidth: true,
  },

  {
    ComponentType: FormTextField,
    format: (value) => (value ? value.toLowerCase() : ''),
    label: 'Job title',
    name: 'title',
    required: true,
    variant: 'standard',
    fullWidth: true,
  },
  {
    ComponentType: FormDatePickerField,
    // TODO: HB-961 this formats the date if the ComponentType is FormTextField, get UX feedback about DatePicker formatting
    format: (val) => reformatDateMMMMDDYYYY(val),
    initialValue: new Date(),
    label: 'Start date',
    minDate: new Date(),
    name: 'startDate',
    required: true,
    validate: composeValidators(isValidDate, isDateEqualOrGreaterThanToday),
    variant: 'standard',
    fullWidth: true,
    xs: 6,
  },
  {
    ComponentType: FormDatePickerField,
    // TODO: HB-961 this formats the date if the ComponentType is FormTextField, get UX feedback about DatePicker formatting
    format: (val) => reformatDateMMMMDDYYYY(val),
    initialValue: new Date(),
    label: 'End date',
    minDate: new Date(),
    name: 'endDate',
    required: true,
    validate: composeValidators(isValidDate, isDateEqualOrGreaterThanToday),
    variant: 'standard',
    fullWidth: true,
    xs: 6,
  },
  {
    ComponentType: FormTextField,
    label: 'Description',
    name: 'description',
    parse: (value) => value,
    readOnly: true,
    required: true,
    variant: 'standard',
    fullWidth: true,
    multiline: true,
    maxRows: 5,
  },
];

export const fieldsJobConfirmation = fieldsJobDescription.map((fieldData) => ({
  ComponentType: FormTextField,
  format: fieldData.format,
  label: fieldData.label,
  name: fieldData.name,
  readOnly: true,
  variant: 'standard',
  fullWidth: true,
  xs: fieldData.xs,
}));
