import React, { useEffect } from 'react';
import get from 'lodash/fp/get';
import has from 'lodash/fp/has';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

import DefaultCompanyLogo from '../../../assets/DefaultCompanyLogo.svg';
import { selectCorporationById, selectFiles } from '../../../store/selectors/employeeWorkSelectors';
import theme, { groupListCompanyImageSize } from '../../../theme/theme';
import { getFilesForCandidate } from '../reducer';
import { calculateTotalPay } from '../viewCalculations';

const WorkListCard = ({ itemClickHandler, data }) => {
  const { _id, title, corporation: corporationId, totalPay, payRateReg } = data;
  const dispatch = useDispatch();

  const { name: corporationName, logo: logoID } =
    useSelector((state) => selectCorporationById(state, corporationId)) || {};

  const files = useSelector(selectFiles);
  const isFileRequested = has(logoID, files);
  const file = get([`${logoID}`], files);

  useEffect(() => {
    if (!isFileRequested && logoID) {
      dispatch(getFilesForCandidate({ id: logoID }));
    }
  }, [dispatch, logoID, isFileRequested]);

  return (
    <Paper
      square
      sx={{ padding: theme.spacing(2) }}
      key={_id}
      onClick={() => itemClickHandler(data)}
    >
      <Grid container key={_id} direction="row" justifyContent="space-between" alignItems="center">
        <Box item flex={1}>
          <img
            src={file || DefaultCompanyLogo}
            alt="logo"
            style={{
              height: groupListCompanyImageSize,
              display: 'block',
              margin: 'auto',
            }}
          />
        </Box>
        <Box item flex={5} sx={{ justifyContent: 'space-between' }}>
          <Grid
            container
            key={_id}
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              item
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>{title}</Typography>
              <Typography variant="h5">{calculateTotalPay(totalPay)}</Typography>
            </Box>
            <Box
              item
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle2" style={{ color: theme.palette.subtext.main }}>
                {corporationName}
              </Typography>

              <Typography variant="subtitle2" style={{ color: theme.palette.subtext.main }}>
                ${payRateReg}/hr
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
};

WorkListCard.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    logoID: PropTypes.string,
    title: PropTypes.string,
    corporation: PropTypes.string,
    totalPay: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
    }),
    payRateReg: PropTypes.number,
    imageURL: PropTypes.string,
  }),
  itemClickHandler: PropTypes.func,
};

export default WorkListCard;
