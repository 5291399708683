import React, { useState } from 'react';
import { getDistance } from 'geolib';

import Button from '../Button';

const GeolocationDemo = () => {
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [status, setStatus] = useState(null);

  const getLocation = () => {
    const nav = navigator;

    if (!nav.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      nav.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          setAccuracy(position.coords.accuracy);
        },
        (error) => {
          setStatus(error.message);
        }
      );
    }
  };

  return (
    <div className="App">
      <h1>Geolocation Punch-In Demo</h1>
      <p>Warning: This will not work from non-dev pc until we have HTTPS enabled.</p>
      <Button onClick={getLocation} color="secondary" variant="contained">
        Clock In
      </Button>
      <p>{status}</p>
      {lat && <p>Latitude: {lat}</p>}
      {lng && <p>Longitude: {lng}</p>}
      {accuracy && <p>Accuracy: {accuracy}</p>}
      {lat && lng && (
        <p>
          you are{' '}
          {getDistance(
            { latitude: lat, longitude: lng },
            { latitude: 43.14964563454832, longitude: -77.58817214807459 }
          )}{' '}
          meters away (+/- {accuracy}) from Career Start Headquarters.
        </p>
      )}
    </div>
  );
};

export default GeolocationDemo;
