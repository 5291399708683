import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

import { errorHandler } from './formUtils';

const FormTextField = ({
  disabled,
  fieldErrorData,
  focused,
  fullWidth,
  input,
  label,
  maxRows,
  meta,
  minRows,
  multiline,
  placeholder,
  readOnly,
  required,
  rows,
  sx,
  variant,
}) => {
  const error = errorHandler(input, meta, fieldErrorData);
  return (
    <TextField
      error={!!error}
      disabled={disabled}
      focused={focused}
      fullWidth={fullWidth}
      helperText={error || null}
      inputProps={readOnly && { readOnly }}
      label={label}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      name={input.name}
      onBlur={input.onBlur}
      onChange={input.onChange}
      placeholder={placeholder}
      required={required}
      rows={rows}
      sx={sx}
      value={input.value || ''}
      variant={variant}
    />
  );
};
FormTextField.propTypes = {
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      // FIXME: HB-944 find better way to specify the type, in most cases TextField will be accepting different type of data
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.bool,
      PropTypes.number,
    ]),
  }),
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  maxRows: PropTypes.number,
  meta: PropTypes.shape(),
  minRows: PropTypes.number,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  rows: PropTypes.number,
  sx: PropTypes.shape({}),

  variant: PropTypes.string,
  fieldErrorData: PropTypes.string,
};

export default FormTextField;
