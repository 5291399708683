import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import Form from '../../components/Form';
import FormPasswordField from '../../components/Form/FormPasswordField';
import selectIsLoading from '../../store/selectors/passwordUpdateSelectors';
import { showSnackbar } from '../app/reducer';

import { updatePassword } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100wh',
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const PasswordUpdate = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const handleOnSubmitLogUpdatePassword = (values) => {
    const { oldPassword, newPassword, confirmNewPassword } = values;
    if (newPassword === confirmNewPassword) {
      const OldAndNewPassword = { oldPassword, newPassword };
      dispatch(updatePassword(OldAndNewPassword));
    } else {
      dispatch(showSnackbar({ message: 'Confirmation password does not match' }));
    }
  };

  const buttonData = [
    {
      color: 'primary',
      fullWidth: true,
      text: 'Update Password',
      showIndeterminateProgress: !!isLoading,
      type: 'submit',
      variant: 'contained',
      disabled: !!isLoading,
    },
  ];

  const formFieldData = [
    {
      ComponentType: FormPasswordField,
      label: 'Current Password',
      name: 'oldPassword',
      required: true,
      type: 'password',
      variant: 'standard',
      fullWidth: true,
    },
    {
      ComponentType: FormPasswordField,

      label: 'New Password',
      name: 'newPassword',
      required: true,
      type: 'password',
      variant: 'standard',
      fullWidth: true,
    },
    {
      ComponentType: FormPasswordField,
      label: 'Confirm New Password',
      name: 'confirmNewPassword',
      required: true,
      type: 'password',
      variant: 'standard',
      fullWidth: true,
    },
  ];

  return (
    <RootGrid container>
      <FormGrid item xs={11} sm={8} md={5} lg={5}>
        <Form
          buttonData={buttonData}
          formFieldData={formFieldData}
          onSubmit={(values) => handleOnSubmitLogUpdatePassword(values)}
        />
      </FormGrid>
    </RootGrid>
  );
};

export default PasswordUpdate;
