import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import { errorHandler } from './formUtils';

const FormRadioButtons = ({
  fieldErrorData,
  initialValue,
  input,
  labels,
  meta,
  required,
  size,
  sx,
  title,
  values,
  variant,
}) => {
  const error = errorHandler(input, meta, fieldErrorData);

  return (
    <FormControl error={!!error} required={required} sx={sx} variant={variant}>
      <FormLabel>{title}</FormLabel>
      <RadioGroup
        defaultValue={initialValue}
        name={input.name}
        onChange={input.onChange}
        value={input.value}
      >
        {labels.map((label, index) => {
          const linkValue = values[index];
          return (
            <FormControlLabel
              control={<Radio />}
              key={input.name + label} // need to change key to another value
              label={label}
              size={size}
              value={linkValue}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

FormRadioButtons.propTypes = {
  fieldErrorData: PropTypes.string,
  initialValue: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  labels: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape(),
  required: PropTypes.bool,
  size: PropTypes.string, // default medium
  sx: PropTypes.shape({}),
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.string,
};

export default FormRadioButtons;
