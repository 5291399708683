import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCreateLongTermJob = (state) => get('createLongTermJob', state);

export const selectIsProcessingPostJob = createSelector(
  selectCreateLongTermJob,
  (createLongTermJob) => createLongTermJob.isProcessingPostJob
);

export const selectIsLoadingPt = createSelector(
  selectCreateLongTermJob,
  (createLongTermJob) => createLongTermJob.isLoadingPt
);

export const selectIsLoadingLocations = createSelector(
  selectCreateLongTermJob,
  (createLongTermJob) => createLongTermJob.isLoadingLocations
);

export const selectIsLoadingCorporations = createSelector(
  selectCreateLongTermJob,
  (createLongTermJob) => createLongTermJob.selectIsLoadingCorporations
);

export const selectLocations = createSelector(
  selectCreateLongTermJob,
  (createLongTermJob) => createLongTermJob.locations
);

export const selectPositionTemplateData = createSelector(
  selectCreateLongTermJob,
  (createLongTermJob) => createLongTermJob.positionTemplateData
);

export const selectCorporations = createSelector(
  selectCreateLongTermJob,
  (createLongTermJob) => createLongTermJob.corporations
);
