import get from 'lodash/fp/get';

import FormAutocompleteField from '../../../../components/Form/FormAutocompleteField';
import FormTextField from '../../../../components/Form/FormTextField';

import { fieldsJobDescription } from './formFieldData';

export const recruiterFormFieldData = [
  {
    ComponentType: FormAutocompleteField,
    fullWidth: true,
    label: 'Company',
    name: 'corporation',
    options: [],
    optionLabel: 'name',
    placeholder: 'Select a corporation',
    readOnlyFormat: (value) => get('name', value),
    required: true,
    variant: 'standard',
  },
  ...fieldsJobDescription,
];

export const recruiterFormFieldDataConfirmation = recruiterFormFieldData.map((fieldData) => ({
  ComponentType: FormTextField,
  format: fieldData.format,
  label: fieldData.label,
  name: fieldData.name,
  readOnly: true,
  variant: 'standard',
  fullWidth: true,
  xs: fieldData.xs,
}));
