import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LockClockIcon from '@mui/icons-material/LockClock';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import UserRole from '../../../constants/user-role';
import selectUser from '../../../store/selectors/appSelector';

const PunchInOutMenu = ({ closeDrawer }) => {
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  if (user.role === UserRole.CANDIDATE) {
    return (
      <List component="nav" disablePadding>
        <ListItemButton
          onClick={() => {
            navigate('geolocation');
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <LockClockIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Punch In/Out" />
        </ListItemButton>
      </List>
    );
  }

  return null;
};

PunchInOutMenu.propTypes = {
  closeDrawer: PropTypes.func,
};

export default PunchInOutMenu;
