import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import Button from '../../components/Button';
import { logout } from '../app/reducer';

const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutClickHandler = () => dispatch(logout());

  const updatePasswordClickHandler = () => navigate('/account/passwordUpdate');

  return (
    <>
      <Grid container sx={{ justifyContent: 'space-between' }}>
        <h1>Account</h1>
        <Button item onClick={logoutClickHandler}>
          Log out
        </Button>
      </Grid>
      <Button item onClick={updatePasswordClickHandler}>
        Update Password
      </Button>
    </>
  );
};
export default Account;
