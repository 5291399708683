import React from 'react';
import PropTypes from 'prop-types';

import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { Typography } from '@mui/material';

const WorkDateStatus = ({ start, end }) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const now = new Date();

  // return current icon for currently active shift
  const isCurrent = now > startDate && now < endDate;
  if (isCurrent) return <MoreTimeIcon color="primary" />;

  const startTimeString = startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  // return today text for upcoming shift today
  const isStartThisMonth = startDate.getMonth() === now.getMonth();
  const isStartThisDay = startDate.getDate() === now.getDate();
  const isStartToday = isStartThisMonth && isStartThisDay;
  if (isStartToday) return <Typography>Today, {startTimeString}</Typography>;

  // return tomorrow text for upcoming shift tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(now.getDate() + 1);
  const isStartTomorrowsMonth = startDate.getMonth() === tomorrow.getMonth();
  const isStartTomorrowsDay = startDate.getDate() === tomorrow.getDate();
  const isStartTomorrow = isStartTomorrowsMonth && isStartTomorrowsDay;
  if (isStartTomorrow) return <Typography>Tomorrow, {startTimeString}</Typography>;

  // return date text for upcoming shift in the future
  const startMonthDayString = `${startDate.getMonth() + 1}/${startDate.getDate()}`;
  return (
    <Typography>
      {startMonthDayString}, {startTimeString}
    </Typography>
  );
};

WorkDateStatus.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
};

export default WorkDateStatus;
