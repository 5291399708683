import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import WorkDetailCertification from './WorkDetailCertification';

const WorkDetailCertifications = ({ certifications }) => (
  <>
    <Typography fontWeight="bold" fontSize="large" mb={1}>
      Certifications
    </Typography>
    {certifications.map((certification) => (
      <WorkDetailCertification certification={certification} key={certification.id} />
    ))}
  </>
);

WorkDetailCertifications.propTypes = {
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default WorkDetailCertifications;
