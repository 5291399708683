import get from 'lodash/fp/get';
import { batch } from 'react-redux';

import { navigator } from '../main/utils/navigator';
import {
  emailVerificationOrPWResetUpdateUserData,
  loginProcessed as loginProcessedUpdateApp,
  showSnackbar,
} from '../main/views/app';
import {
  forgotPasswordIsNavigating,
  postForgotPassword,
  postForgotPasswordError,
  postForgotPasswordProcessed,
} from '../main/views/forgotMyPassword';
import {
  getLocations as getLocationsForJobsOrder,
  getLocationsError as getLocationsErrorForJobsOrder,
  getLocationsProcessed as getLocationsProcessedForJobsOrder,
  getPositionTemplate as getPositionTemplateForJobsOrder,
  getPositionTemplateErrorForJobs as getPositionTemplateErrorForJobsOrder,
  getPositionTemplateProcessedForJobs as getPositionTemplateProcessedForJobsOrder,
  postJobs,
  postJobsError,
  postJobsProcessed,
} from '../main/views/jobs/createJobs';
import {
  getCorporations,
  getCorporationsError,
  getCorporationsProcessed,
  getLocations as getLocationsForJobOrder,
  getLocationsError as getLocationsForJobOrderError,
  getLocationsProcessed as getLocationsForJobOrderProcessed,
  getPositionTemplate,
  getPositionTemplateError,
  getPositionTemplateProcessed,
  postJob,
  postJobError,
  postJobProcessed,
} from '../main/views/jobs/createLongTermJob';
import {
  deleteJob,
  deleteJobError,
  deleteJobProcessed,
  getCorporationsForJobsList,
  getCorporationsForJobsListError,
  getCorporationsForJobsListProcessed,
  getJobsList,
  getJobsListError,
  getJobsListProcessed,
  updateJob,
  updateJobError,
  updateJobProcessed,
} from '../main/views/jobs/jobList';
import { login as loginUpdateLogin, loginError, loginProcessed } from '../main/views/login';
import {
  updatePassword,
  updatePasswordError,
  updatePasswordProcessed,
} from '../main/views/passwordUpdate';
import {
  passwordReset,
  passwordResetError,
  passwordResetProcessed,
  verifyAccount,
  verifyAccountError,
  verifyAccountProcessed,
} from '../main/views/PasswordVerificationOrReset';
import {
  getCertifications,
  getCertificationsError,
  getCertificationsProcessed,
  getLocations,
  getLocationsError,
  getLocationsProcessed,
  postPositionTemplate,
  postPositionTemplateError,
  postPositionTemplateProcessed,
} from '../main/views/positionTemplate/createPositionTemplate';
import {
  deletePositionTemplate,
  deletePositionTemplateError,
  deletePositionTemplateProcessed,
  getCertificationsForPositionTemplateList,
  getCertificationsForPositionTemplateListError,
  getCertificationsForPositionTemplateListProcessed,
  getCorporationsForPositionTemplateList,
  getCorporationsForPositionTemplateListError,
  getCorporationsForPositionTemplateListProcessed,
  getLocationsForPositionTemplateList,
  getLocationsForPositionTemplateListError,
  getLocationsForPositionTemplateListProcessed,
  getPositionTemplateList,
  getPositionTemplateListError,
  getPositionTemplateListProcessed,
  updatePositionTemplate,
  updatePositionTemplateError,
  updatePositionTemplateProcessed,
} from '../main/views/positionTemplate/positionTemplateList';
import { register, registerError, registerProcessed } from '../main/views/register';
import {
  getCertificationsForCandidate,
  getCertificationsForCandidateError,
  getCertificationsForCandidateProcessed,
  getCorporationsForCandidate,
  getCorporationsForCandidateError,
  getCorporationsForCandidateProcessed,
  getFilesForCandidate,
  getFilesForCandidateError,
  getFilesForCandidateProcessed,
  getJobOrderList,
  getJobOrderListError,
  getJobOrderListProcessed,
  getPlacements,
  getPlacementsError,
  getPlacementsProcessed,
  getWorkList,
  getWorkListError,
  getWorkListProcessed,
  postPlacement,
  postPlacementError,
  postPlacementProcessed,
} from '../main/views/work/reducer';

const API = [
  {
    http: 'post',
    path: 'user/login',
    type: loginUpdateLogin.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(loginProcessedUpdateApp(payload));
        dispatch(loginProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const payloadWithMsg = {
        message:
          // FIXME: HB-200: When we handle i18n, this goes away and we handle it at time of display to the user according to their native language.
          (get(['data', 'error', 'data', 'error'], payload) === 'ERROR_ACCESS_DENIED' &&
            'Incorrect email address or password') ||
          (get(['data', 'error', 'data', 'email', 'msg'], payload) === 'Invalid email format.' &&
            'Email format is invalid') ||
          (['ERROR_FORBIDDEN', 'ERROR_NOT_FOUND'].includes(
            get(['data', 'error', 'type'], payload)
          ) &&
            'Incorrect email address or password') ||
          'Unknown Error',
      };
      batch(() => {
        dispatch(showSnackbar(payloadWithMsg));
        dispatch(loginError(payload));
      });
    },
  },

  {
    http: 'post',
    path: 'user/register-employer',
    type: register.type,
    successCallback: (payload, dispatch) => {
      const registerAction = registerProcessed(payload);
      const message = 'Verify email address to continue; verification email has been sent.';
      const messageAction = showSnackbar({ message });
      batch(() => {
        dispatch(registerAction);
        dispatch(messageAction);
      });
    },
    errorCallback: (payload, dispatch) => {
      const payloadWithMsg = {
        message:
          // FIXME: HB-956 Update register-employer error handling
          // FIXME: HB-200: When we handle i18n, this goes away and we handle it at time of display to the user according to their native language.
          (get(['data', 'error', 'data', 'error'], payload) === 'ERROR_ACCESS_DENIED' &&
            'Incorrect email or password') ||
          'Unknown Error',
      };
      batch(() => {
        dispatch(registerError(payload));
        dispatch(showSnackbar(payloadWithMsg));
      });
    },
  },

  {
    http: 'post',
    path: 'password/update',
    type: updatePassword.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updatePasswordProcessed(payload));
        dispatch(showSnackbar({ message: 'Password update successful!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      const msg = payload.data.error.data.error;
      const payloadWithMsg = {
        message:
          // FIXME: HB-200: When we handle i18n, this goes away and we handle it at time of display to the user according to their native language.
          msg === 'ERROR_ACCESS_DENIED' ? 'Incorrect Password' : 'Unknown Error',
      };
      batch(() => {
        dispatch(updatePasswordError(payload));
        dispatch(showSnackbar(payloadWithMsg));
      });
    },
  },

  {
    http: 'post', // Create Job Order
    path: 'v2/job-order',
    type: postJob.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postJobProcessed(payload));
        dispatch(showSnackbar({ message: 'Job Post Successful!' }));
        navigator('jobs/jobslist');
      });
    },
    errorCallback: (payload, dispatch) => {
      const msg = payload.data.error.data.error;
      const payloadWithMsg = {
        message:
          // FIXME: HB-200: When we handle i18n, this goes away and we handle it at time of display to the user according to their native language.
          msg === 'ERROR_ACCESS_DENIED' ? msg : 'Unknown Error',
      };
      batch(() => {
        dispatch(showSnackbar(payloadWithMsg));
        dispatch(postJobError(payload));
      });
    },
  },
  {
    http: 'post', // Create Job Order
    path: 'job-order/batch',
    type: postJobs.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postJobsProcessed(payload));
        dispatch(showSnackbar({ message: 'Job Post Successful!' }));
        navigator('jobs/jobslist');
      });
    },
    errorCallback: (payload, dispatch) => {
      const msg = payload.data.error ? payload.data.error.data.error : 'Unknown Error';
      const payloadWithMsg = {
        message:
          // FIXME: HB-200: When we handle i18n, this goes away and we handle it at time of display to the user according to their native language.
          // FIXME: HB-918: Standardize error consumption.
          msg === 'ERROR_ACCESS_DENIED' ? msg : 'Unknown Error',
      };
      batch(() => {
        dispatch(showSnackbar(payloadWithMsg));
        dispatch(postJobsError(payload));
      });
    },
  },

  {
    http: 'get', // Get List of Job Order
    path: 'v2/job-order',
    type: getJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getJobsListError(payload));
    },
  },

  {
    http: 'get', // Get Singular Job Order
    path: 'v2/job-order',
    type: getJobOrderList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobOrderListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getJobOrderListError(payload));
    },
  },

  {
    http: 'delete',
    path: 'v2/job-order',
    type: deleteJob.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(deleteJobProcessed(payload));
        dispatch(showSnackbar({ message: 'Delete Job Successful!' }));
        dispatch(getJobsList());
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(showSnackbar({ message: 'Unknown Error!' })); // FIXME: HB-114 need better error messages
        dispatch(deleteJobError(payload));
      });
    },
  },

  {
    http: 'put',
    path: 'v2/job-order',
    type: updateJob.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateJobProcessed(payload));
        dispatch(showSnackbar({ message: 'Job update successful!' })); // FIXME: HB-114 need better error messages
        dispatch(getJobsList());
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(showSnackbar({ message: 'Unknown Error!' }));
        dispatch(updateJobError(payload));
      });
    },
  },

  {
    http: 'get',
    path: 'corporation/read',
    type: getCorporationsForJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsForJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCorporationsForJobsListError(payload));
    },
  },

  {
    http: 'post',
    path: 'position-template/create',
    type: postPositionTemplate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postPositionTemplateProcessed(payload));
        dispatch(showSnackbar({ message: 'Position created successfully!' })); // TODO: HB-200 Should not be hardcoded for multiple languages
        navigator('/jobs/positionTemplateList');
      });
    },
    errorCallback: (payload, dispatch) => {
      const payloadWithMsg = {
        message: 'Please fix the above errors.',
      };
      batch(() => {
        dispatch(postPositionTemplateError(payload));
        dispatch(showSnackbar(payloadWithMsg));
      });
    },
  },

  {
    http: 'get',
    path: 'position-template/read',
    type: getPositionTemplate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateError(payload));
    },
  },

  {
    http: 'get',
    path: 'corporation/read',
    type: getCorporations.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCorporationsError(payload));
    },
  },

  {
    http: 'get',
    path: 'corporation/read',
    type: getCorporationsForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getCorporationsForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCorporationsForCandidateError(payload));
    },
  },

  {
    axiosOptions: {
      responseType: 'blob',
    },
    http: 'get',
    path: 'file/read',
    type: getFilesForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getFilesForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getFilesForCandidateError(payload));
    },
  },

  {
    http: 'get',
    path: 'position-template/read',
    type: getPositionTemplateForJobsOrder.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateProcessedForJobsOrder(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateErrorForJobsOrder(payload));
    },
  },

  {
    http: 'delete',
    path: 'position-template/del',
    type: deletePositionTemplate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(deletePositionTemplateProcessed(payload));
        dispatch(showSnackbar({ message: 'Position deleted successfully!' }));
        dispatch(getPositionTemplateList());
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(
          showSnackbar({
            message:
              payload.error.data ||
              "Sorry, we've encountered a problem on our servers. Tech staff have been alerted.",
          })
        );
        dispatch(deletePositionTemplateError(payload));
      });
    },
  },

  {
    http: 'post',
    path: 'position-template/update',
    type: updatePositionTemplate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updatePositionTemplateProcessed(payload));
        dispatch(showSnackbar({ message: 'Position updated successfully!' }));
        dispatch(getPositionTemplateList());
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(
          showSnackbar({
            message:
              payload.error.data ||
              "Sorry, we've encountered a problem on our servers. Tech staff have been alerted.",
          })
        );
        dispatch(updatePositionTemplateError(payload));
      });
    },
  },

  {
    http: 'get', // Get List of Positions
    path: 'position-template/read',
    type: getPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateListError(payload));
    },
  },

  {
    http: 'get',
    path: 'location/read',
    type: getLocations.type,
    successCallback: (payload, dispatch) => {
      dispatch(getLocationsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getLocationsError(payload));
    },
  },
  {
    http: 'get',
    path: 'location/read',
    type: getLocationsForJobsOrder.type,
    successCallback: (payload, dispatch) => {
      dispatch(getLocationsProcessedForJobsOrder(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getLocationsErrorForJobsOrder(payload));
    },
  },
  {
    http: 'get',
    path: 'location/read',
    type: getLocationsForPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getLocationsForPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getLocationsForPositionTemplateListError(payload));
    },
  },
  {
    http: 'get',
    path: 'location/read',
    type: getLocationsForJobOrder.type,
    successCallback: (payload, dispatch) => {
      dispatch(getLocationsForJobOrderProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getLocationsForJobOrderError(payload));
    },
  },
  {
    http: 'get',
    path: 'v1/certification',
    type: getCertifications.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCertificationsError(payload));
    },
  },
  {
    http: 'get',
    path: 'v1/certification',
    type: getCertificationsForPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCertificationsForPositionTemplateListError(payload));
    },
  },
  {
    http: 'get',
    path: 'corporation/read',
    type: getCorporationsForPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsForPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCorporationsForPositionTemplateListError(payload));
    },
  },
  {
    http: 'post',
    path: 'user/verify',
    type: verifyAccount.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(verifyAccountProcessed(payload));
        dispatch(emailVerificationOrPWResetUpdateUserData(payload));
        dispatch(showSnackbar({ message: 'Account Verified!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const payloadWithMsg = {
          message:
            // FIXME: HB-918 reformat  error message handling from backend
            get('data.error.type', payload, 'Unknown Error'),
        };
        dispatch(showSnackbar(payloadWithMsg));
        dispatch(verifyAccountError(payload));
      });
    },
  },
  {
    http: 'post',
    path: 'password/reset',
    type: passwordReset.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(passwordResetProcessed(payload));
        dispatch(emailVerificationOrPWResetUpdateUserData(payload));
        dispatch(showSnackbar({ message: 'Account Password Reset' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const payloadWithMsg = {
          message:
            // FIXME: HB-918 reformat  error message handling from backend
            get('data.error.type', payload, 'Unknown Error'),
        };
        dispatch(showSnackbar(payloadWithMsg));
        dispatch(passwordResetError(payload));
      });
    },
  },
  {
    http: 'post',
    path: 'password/request-reset',
    type: postForgotPassword.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postForgotPasswordProcessed(payload));
        dispatch(
          showSnackbar({ message: 'Email sent. Please check your email to reset your password.' }) // FIXME: HB-943 Change email to either email or mobile once mobile option is available
        );
        dispatch(forgotPasswordIsNavigating(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const payloadWithMsg = {
          message: get('data.error.type', payload, 'Unknown Error'),
        };
        dispatch(showSnackbar(payloadWithMsg));
        dispatch(postForgotPasswordError(payload));
      });
    },
  },
  {
    http: 'get', // Get List of Job Order for employee
    path: 'job-order/read-group',
    type: getWorkList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getWorkListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getWorkListError(payload));
    },
  },
  {
    http: 'get',
    path: 'v1/certification',
    type: getCertificationsForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCertificationsForCandidateError(payload));
    },
  },
  {
    http: 'post',
    path: 'v1/placement',
    type: postPlacement.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(showSnackbar({ message: 'Job Scheduled!' }));
        dispatch(postPlacementProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(showSnackbar({ message: 'Placement post error' }));
        dispatch(postPlacementError(payload));
      });
    },
  },

  {
    http: 'get',
    path: 'v1/placement',
    type: getPlacements.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getPlacementsProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getPlacementsError(payload));
      });
    },
  },
];

export default API;
