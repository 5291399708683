import React, { memo } from 'react';

const MaintenancePage = memo(() => (
  <div>
    WAE is currently undergoing maintenance. We apologize for the delay, and will be back up and
    running again shortly.
  </div>
));

export default MaintenancePage;
