import { minutesFromMidnightToTime } from './timeConverter';

export const dateToYearMonthDayMinutes = (date, minutes) => {
  const newObject = {
    year: date.getYear() + 1900,
    day: date.getDate(),
    month: date.getMonth() + 1,
    minutes,
  };
  return newObject;
};

export const nameAndTimeToReadable = (name, start, end) => {
  const readable = `${name} (${minutesFromMidnightToTime(start).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })} - ${minutesFromMidnightToTime(end).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })})`;

  return readable;
};
