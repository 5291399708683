import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import Form from '../../../components/Form';
import { isGreaterThanDate } from '../../../components/Form/formLevelValidations';
import { selectIsProcessingPostJob } from '../../../store/selectors/createLongTermJobSelectors';

import buildFormRules from './formData/formRules';
import HeaderCreateJob from './Header';

// TODO: This styling should be combined with other views. Consider including props passed into RootGrid too.
const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}));

// TODO: This styling should be combined with other views.
const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const CreateLongTermJob = memo(
  ({ formData, formId, locations, onSubmit, positionTemplateData }) => {
    const navigate = useNavigate();
    const [currentFormData, setFormData] = useState(formData);
    const isProcessingPostJob = useSelector(selectIsProcessingPostJob);

    return (
      <RootGrid container>
        <FormGrid item xs={11} sm={8} md={6} lg={6}>
          <HeaderCreateJob header="Create Job Order" />
          <Form
            buttonData={[
              {
                color: 'primary',
                onClick: () => navigate('jobs/jobslist'),
                text: 'Cancel',
              },
            ]}
            confirmationModal={{
              buttonText: 'Confirm',
              submitting: isProcessingPostJob,
            }}
            formFieldData={currentFormData}
            formRules={buildFormRules(currentFormData, setFormData, {
              locations,
              positionTemplateData,
            })}
            onSubmit={onSubmit}
            formId={formId}
            validateForm={isGreaterThanDate('startDate', 'endDate', {
              endDate: 'End date must be after the start date',
              startDate: 'End date must be after the start date',
            })}
          />
        </FormGrid>
      </RootGrid>
    );
  }
);

CreateLongTermJob.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.shape({})),
  formId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  positionTemplateData: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmit: PropTypes.func,
  user: PropTypes.shape({
    role: PropTypes.string,
    employer: PropTypes.shape({ corporation: PropTypes.string }),
  }),
};

export default CreateLongTermJob;
