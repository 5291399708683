import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
  selectCorporations,
  selectIsLoadingPlacements,
  selectPlacements,
} from '../../../store/selectors/employeeWorkSelectors';
import theme from '../../../theme/theme';

import WorkDateStatus from './WorkDateStatus';

const ScheduledWork = () => {
  const navigate = useNavigate();
  const itemClickHandler = (placementId) => {
    navigate(`/shifts/${placementId}`);
  };

  const now = new Date();
  const PlaceComponent = ({ placement }) => {
    const corporations = useSelector(selectCorporations);
    const corpNameList = corporations.filter((corp) => corp._id === placement.jobOrder.corporation);
    const corpName = corpNameList.length === 0 ? '' : corpNameList[0].name;
    // Fifteen minutes in epoch time
    const epochFifteenMinutes = 900000;

    if (new Date(placement.jobOrder.end + epochFifteenMinutes) > now) {
      return (
        <Grid
          container
          key={placement._id}
          direction="row"
          justifyContent="space-between"
          onClick={() => itemClickHandler(placement._id)}
        >
          <Grid item container xs={6}>
            <Stack>{corpName}</Stack>
          </Grid>
          <Grid item xs={6} container direction="row" justifyContent="flex-end" alignItems="center">
            <Stack>
              <WorkDateStatus start={placement.jobOrder.start} end={placement.jobOrder.end} />
            </Stack>
          </Grid>
        </Grid>
      );
    }
    return <></>;
  };

  PlaceComponent.propTypes = {
    placement: PropTypes.shape(),
  };

  const isLoadingPlacements = useSelector(selectIsLoadingPlacements);
  const placements = useSelector(selectPlacements);

  if (isLoadingPlacements) return <Typography variant="h4">Loading scheduled work... </Typography>;

  // Based on the placements we have, choose whether or not to show the section
  if (!placements.length) return <></>;

  const sortedPlacements = _.sortBy(placements, [
    // eslint-disable-next-line func-names
    function (placement) {
      return placement.jobOrder.start;
    },
  ]);

  // Check to see if all of the placements are in the past. If so, don't show the scheduled section
  const epochFifteenMinutes = 900000;

  if (
    sortedPlacements.every(
      (placement) =>
        new Date(placement.jobOrder.start - epochFifteenMinutes) < now &&
        new Date(placement.jobOrder.end + epochFifteenMinutes) < now
    )
  ) {
    return <></>;
  }

  let toDisplay;

  // Check to see if there is an active placement
  if (
    sortedPlacements.some(
      (placement) =>
        new Date(placement.jobOrder.start - epochFifteenMinutes) < now &&
        new Date(placement.jobOrder.end + epochFifteenMinutes) > now
    )
  ) {
    // If there is, we're gonna pull that one, and the next two from the sortedPlacements list to display
    const startingIndex = sortedPlacements.findIndex(
      (placement) =>
        new Date(placement.jobOrder.start - epochFifteenMinutes) < now &&
        new Date(placement.jobOrder.end + epochFifteenMinutes) > now
    );

    toDisplay = sortedPlacements.slice(startingIndex, startingIndex + 3);
  } else {
    // If there isn't one, we're gonna pull the next two upcoming placements to show to the user
    const startingIndex = sortedPlacements.findIndex(
      (placement) => new Date(placement.jobOrder.start - epochFifteenMinutes) > now
    );

    toDisplay = sortedPlacements.slice(startingIndex, startingIndex + 2);
  }

  return (
    <Grid item sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
      <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
        Scheduled
      </Typography>
      <Paper square sx={{ padding: theme.spacing(1, 1) }}>
        <Stack spacing={2}>
          {toDisplay.map((placement) => (
            <PlaceComponent placement={placement} key={placement._id} />
          ))}
        </Stack>
      </Paper>
    </Grid>
  );
};

export default ScheduledWork;
