import React, { useEffect, useRef } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import GeolocationDemo from '../../components/geolocation/GeolocationDemo';
import Snackbar from '../../components/Snackbar';
import Zendesk from '../../components/Zendesk/Zendesk';
import UserRole from '../../constants/user-role';
import selectUser from '../../store/selectors/appSelector';
import { getItemFromLocalStorage, LOCAL_STORAGE_KEYS } from '../../store/utils';
import launchDarklyToggles from '../../utils/launchDarklyToggles';
import { history } from '../../utils/navigator';
import Account from '../account/Account';
import ForgotMyPassword from '../forgotMyPassword';
import CreateJobs from '../jobs/createJobs';
// import CreateJob from '../jobs/createJob';
import CreateLongTermJob from '../jobs/createLongTermJob';
import JobsList from '../jobs/jobList';
import Login from '../login';
import MaintenancePage from '../maintenance';
import Notification from '../notification/Notification';
import PasswordUpdate from '../passwordUpdate';
import PasswordVerificationOrResetPage from '../PasswordVerificationOrReset/PasswordVerificationOrResetPage';
import Payment from '../payment/Payment';
import CreatePositionTemplate from '../positionTemplate/createPositionTemplate';
import PositionTemplateList from '../positionTemplate/positionTemplateList';
import Register from '../register/Register';
import ConnectedWork from '../work/ConnectedWork';
import ShiftDetail from '../work/shiftDetail';
import WorkDetailContainer from '../work/workDetail/WorkDetailContainer';
import WorkList from '../work/workList/WorkList';

import EmployeeNavigation from './EmployeeNavigation/EmployeeNavigation';
import Navigation from './Navigation';
import { updateUserData } from './reducer';

const AppRouter = ({ flags }) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      const userData = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.userDataKey);

      if (userData) {
        dispatch(updateUserData(userData));
        // TODO: (TICKET PENDING) Longer term, we'll want just the token and we'll do a lookup to get the
        // rest of the user data if it is a valid token. That way, either the token is invalid and
        // user is forced to reauth, or the token is valid and we get fresh data for the user.
      }
    }
  }, [dispatch, user]);

  const maintenance = launchDarklyToggles(flags, 'isMaintenanceModeEnabled');

  const token = get('token', user);
  const role = token && get('role', user);
  const { ADMIN, EMPLOYER, RECRUITER, CANDIDATE } = UserRole;
  const isAdmin = role === ADMIN;
  const isEmployer = role === EMPLOYER;
  const isRecruiter = role === RECRUITER;
  const isEmployee = role === CANDIDATE;

  const { REACT_APP_EMPLOYER_ZENDESK_KEY: EMPLOYER_ZENDESK_KEY } = process.env;

  const mobileHeaderRef = useRef(null);

  return (
    <HistoryRouter history={history}>
      <Snackbar />

      <Zendesk active={isEmployer} zendeskKey={EMPLOYER_ZENDESK_KEY} horizontal="left" />

      {maintenance ? (
        <Routes>
          <Route path="*" element={<MaintenancePage />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route
            path="accountverification/:queryinformation"
            element={<PasswordVerificationOrResetPage type="accountVerification" />}
          />
          <Route
            path="passwordReset/:queryinformation"
            element={<PasswordVerificationOrResetPage type="passwordReset" />}
          />
          <Route path="forgotMyPassword" element={<ForgotMyPassword />} />

          {/* Admin, Employer, Recruiter View */}
          {(isAdmin || isEmployer || isRecruiter) && (
            <Route element={<Navigation />}>
              <Route path="dashboard" element={<JobsList />} />
              <Route path="geolocation" element={<GeolocationDemo />} />
              <Route path="settings">
                <Route path="passwordUpdate" element={<PasswordUpdate />} />
              </Route>
              <Route path="jobs/jobsList" element={<JobsList />} />
              <Route path="jobs/createJobs" element={<CreateJobs />} />
              <Route path="jobs/createJob" element={<CreateLongTermJob />} />

              <Route path="jobs/positionTemplateList" element={<PositionTemplateList />} />
              <Route path="jobs/createPositionTemplate" element={<CreatePositionTemplate />} />
            </Route>
          )}

          {/* Employee View */}
          {isEmployee && (
            <Route element={<EmployeeNavigation mobileHeaderRef={mobileHeaderRef} />}>
              <Route
                path="dashboard"
                element={
                  <ConnectedWork>
                    <WorkList mobileHeaderRef={mobileHeaderRef} />
                  </ConnectedWork>
                }
              />
              <Route
                path="jobs/jobsList"
                element={
                  <ConnectedWork>
                    <WorkList mobileHeaderRef={mobileHeaderRef} />
                  </ConnectedWork>
                }
              />
              <Route
                path="jobs/jobDetail"
                element={
                  <ConnectedWork>
                    <WorkDetailContainer mobileHeaderRef={mobileHeaderRef} />
                  </ConnectedWork>
                }
              />
              <Route
                path="shifts/:placementId"
                element={
                  <ConnectedWork>
                    <ShiftDetail mobileHeaderRef={mobileHeaderRef} />
                  </ConnectedWork>
                }
              />
              {/* FIXME: Update headers to use Portal in the following views */}
              <Route path="payment" element={<Payment />} />
              <Route path="notification" element={<Notification />} />
              <Route path="account" element={<Account />} />
              <Route path="account/passwordUpdate" element={<PasswordUpdate />} />
            </Route>
          )}

          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </HistoryRouter>
  );
};

AppRouter.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(AppRouter);
