import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// FIXME: This really ought to be a common component. there's nothing specific to job creation here.
const HeaderCreateJob = ({ header }) => (
  <Grid item container direction="column" alignContent="center" justifyContent="center">
    <Typography variant="h4" align="center" sx={{ padding: 2 }}>
      {header}
    </Typography>
  </Grid>
);
HeaderCreateJob.propTypes = {
  header: PropTypes.string,
};
export default HeaderCreateJob;
