import React from 'react';
import PropTypes from 'prop-types';

import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

const AppBar = styled(MuiAppBar)({});

const TopAppBar = ({ mobileHeaderRef }) => (
  <>
    <CssBaseline />
    <AppBar position="relative">
      <Toolbar ref={mobileHeaderRef} />
    </AppBar>
  </>
);

TopAppBar.propTypes = {
  mobileHeaderRef: PropTypes.shape({}),
};

export default TopAppBar;
