import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import launchDarklyToggles from '../../../utils/launchDarklyToggles';

const DashboardMenu = ({ flags, closeDrawer }) => {
  const isSideBarDashboardButtonEnabled = launchDarklyToggles(
    flags,
    'isSideBarDashboardButtonEnabled'
  );

  const navigate = useNavigate();
  return (
    <List component="nav" disablePadding>
      <ListItemButton
        onClick={() => {
          navigate('dashboard');
          closeDrawer();
        }}
        disabled={!isSideBarDashboardButtonEnabled}
      >
        <ListItemIcon>
          <DashboardIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </List>
  );
};
DashboardMenu.propTypes = {
  flags: PropTypes.shape({}),
  closeDrawer: PropTypes.func,
};
export default withLDConsumer()(DashboardMenu);
