import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const PositionsMenu = ({ closeDrawer }) => {
  const navigate = useNavigate();

  return (
    <List component="nav" disablePadding>
      <ListItemButton
        onClick={() => {
          navigate('jobs/positionTemplateList');
          closeDrawer();
        }}
      >
        <ListItemIcon>
          <PersonAddAlt1Icon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Positions" />
      </ListItemButton>
    </List>
  );
};

PositionsMenu.propTypes = {
  closeDrawer: PropTypes.func,
};

export default PositionsMenu;
