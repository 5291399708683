import React, { useEffect } from 'react';
import find from 'lodash/fp/find';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Button from '../../../components/Button';
import {
  allDataValid,
  jobsList,
  selectPositionTemplateData,
} from '../../../store/selectors/createJobsSelectors';
import { appBarHeight } from '../../../theme/theme';

import CreateJobsDataGridLS from './CreateJobsDataGridLS';
import { clearValidateList, postJobs, setJobCreateInitState, updateValidateList } from './reducer';

const RootGrid = styled(Grid)(() => ({
  justifyContent: 'center',
  height: `calc(100vh - ${appBarHeight})`,
  alignContent: 'flex-start',
  flexDirection: 'row',
}));

const HeaderGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3, 0, 0),
}));

const FormGrid = styled(Grid)(({ theme }) => ({
  direction: 'column',
  justifyContent: 'center',
  alignContent: 'space-between',
  padding: theme.spacing(0, 0, 3),
}));

const ButtonGrid = styled(Grid)(() => ({
  justifyContent: 'flex-end',
}));

const CreateJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const displayJobsList = useSelector(jobsList);
  const positionTemplateData = useSelector(selectPositionTemplateData);
  const isAllDataValid = useSelector(allDataValid);
  useEffect(
    () => () => {
      dispatch(setJobCreateInitState());
    },
    [dispatch]
  );

  const [openPostDialog, setOpenPostDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [dialogDesc, setDialogDesc] = React.useState('');
  const [postData, setPostData] = React.useState([]);

  const handleDialogOpen = () => {
    setOpenPostDialog(true);
  };

  const handleDialogClose = () => {
    setOpenPostDialog(false);
  };

  const handleDialogPost = () => {
    handleDialogClose();
    if (postData.length > 0) {
      dispatch(postJobs({ orders: postData }));
    }
  };

  const handleDialogCancel = () => {
    handleDialogClose();
  };

  const generateTimeInfo = (positionId, shiftId, startInfo) => {
    const selectedPositionTemplate = find((pt) => pt.id === positionId, positionTemplateData) || {
      shifts: [],
    };
    const selectShift = find((shift) => shift._id === shiftId, selectedPositionTemplate.shifts) || {
      start: 0,
      end: 0,
    };

    const startMinutes = selectShift.start;
    const endMinutes = selectShift.end;

    const startDate = new Date(startInfo.year, startInfo.month - 1, startInfo.day, 0, 0, 0);
    const endDate = new Date(startDate);

    if (startMinutes > endMinutes) {
      // startMinutes>endMinutes one more day overnight job
      endDate.setDate(endDate.getDate() + 1);
    }

    return {
      start: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
        minutes: startMinutes,
      },

      end: {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate(),
        minutes: endMinutes,
      },
    };
  };

  const handlePostButton = () => {
    handleDialogOpen();
    dispatch(clearValidateList());
    const res = [];
    if (displayJobsList) {
      displayJobsList.forEach((job) => {
        if (
          job.startInfo.year &&
          job.numOpenings &&
          job.location &&
          job.location.id &&
          job.shift &&
          job.shift.id
        ) {
          const timeInfo = generateTimeInfo(job.position.id, job.shift.id, job.startInfo);
          res.push({
            frontEndId: job.id,
            start: timeInfo.start,
            end: timeInfo.end,
            numOpenings: parseInt(job.numOpenings, 10),
            location: job.location.id,
            positionTemplate: job.position.id,
          });
        } else {
          // TODO: HB-964 add batch validate update for day labor scheduler
          if (!job.position.id) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'position',
                status: false,
              })
            );
          }
          if (!job.location.id) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'location',
                status: false,
              })
            );
          }

          if (!job.startInfo.year) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'startInfo',
                status: false,
              })
            );
          }

          if (!job.shift.id) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'shift',
                status: false,
              })
            );
          }

          if (Number.isNaN(job.numOpenings) || job.numOpenings <= 0) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'numOpenings',
                status: false,
              })
            );
          }
        }
      });
    }
    const resLength = res.length;

    let totalOpenNumber = 0;
    res.forEach((job) => {
      totalOpenNumber += job.numOpenings;
    });

    if (resLength > 0) {
      // TODO HB-200 use i18n fix pluralization https://i18next.github.io/i18next/pages/sample.html
      const openingString = totalOpenNumber === 1 ? `1 opening` : `${totalOpenNumber} openings`;

      setPostData(res);
      setDialogTitle(`Post all jobs?`);
      setDialogDesc(`Total of ${openingString} will be posted.`);
    } else {
      setPostData([]);
    }
  };

  return (
    <RootGrid container>
      <Grid item xs={12} sm={12} md={11} xl={10}>
        <HeaderGrid item>
          <Typography variant="h5" align="left">
            Just a day scheduling
          </Typography>
        </HeaderGrid>
        <FormGrid item container>
          <CreateJobsDataGridLS />
        </FormGrid>

        <ButtonGrid item container>
          <Button
            sx={{ marginLeft: 1 }}
            color="primary"
            text="CANCEL"
            variant="outlined"
            key="create-jobs-cancel"
            onClick={() => navigate('/jobList')}
          />
          <Button
            sx={{ marginLeft: 1 }}
            color="primary"
            text="POST"
            variant="contained"
            key="create-jobs-post"
            disabled={!isAllDataValid}
            onClick={handlePostButton}
          />
          <Dialog
            sx={{ minWidth: 350 }} // TODO: HB-965 Refactor to use built in Dialog component and set minWidth for Dialog
            open={openPostDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description-none-send">
                <span style={{ whiteSpace: 'pre-line' }}>{dialogDesc}</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogCancel}>CANCEL</Button>
              {postData.length >= 1 && (
                <Button onClick={handleDialogPost} autoFocus>
                  CONFIRM
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </ButtonGrid>
      </Grid>
    </RootGrid>
  );
};

export default CreateJobs;
