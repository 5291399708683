import React from 'react';
import PropTypes from 'prop-types';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

// ZendeskAPI hide/show widget
// https://developer.zendesk.com/api-reference/widget/core/#show

// ZendeskAPI widget position
// https://developer.zendesk.com/api-reference/widget/settings/#position

const ZendeskComponent = ({
  active = false,
  horizontal = 'right',
  vertical = 'bottom',
  zendeskKey = null,
}) =>
  zendeskKey &&
  active && (
    <>
      <Zendesk defer webWidget={{ position: { horizontal, vertical } }} zendeskKey={zendeskKey} />
      {ZendeskAPI('webWidget', active ? 'show' : 'hide')}
    </>
  );

ZendeskComponent.propTypes = {
  active: PropTypes.bool,
  zendeskKey: PropTypes.string,
  horizontal: PropTypes.string,
  vertical: PropTypes.string,
};

export default ZendeskComponent;
