/* eslint-disable no-alert */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid } from '@mui/material';

import ShiftDetailCancelJobConfirmDialog from './ShiftDetailCancelJobConfirmDialog';

const ShiftDetailCancelJob = ({ placementId }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const confirmCancelJob = () => setConfirmDialogOpen(true);

  return (
    <>
      <Grid item>
        <Button fullWidth onClick={confirmCancelJob}>
          Cancel Job
        </Button>
        <ShiftDetailCancelJobConfirmDialog
          placementId={placementId}
          open={confirmDialogOpen}
          setOpen={setConfirmDialogOpen}
        />
      </Grid>
    </>
  );
};

ShiftDetailCancelJob.propTypes = {
  placementId: PropTypes.string.isRequired,
};

export default ShiftDetailCancelJob;
