import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Typography } from '@mui/material';

import WorkDetailCertifications from './certifications/WorkDetailCertifications';
import WorkDetailAvailableShifts from './shifts/WorkDetailAvailableShifts';
import WorkDetailYourShifts from './shifts/WorkDetailYourShifts';
import WorkDetailSkills from './skills/WorkDetailSkills';
import WorkDetailDescription from './WorkDetailDescription';
import WorkDetailEmployer from './WorkDetailEmployer';
import WorkDetailPayment from './WorkDetailPayment';

const WorkDetail = ({
  availableShifts,
  corporation,
  description,
  distance,
  groupCertifications,
  groupPlacements,
  jobOrderName,
  payRateReg,
  totalPay,
}) => (
  <Grid container direction="column" rowGap={4}>
    <Box item>
      <Typography variant="h5" fontWeight="bold">
        {jobOrderName}
      </Typography>
    </Box>
    <Box item>
      <WorkDetailEmployer corporation={corporation} distance={distance} />
    </Box>
    <Box item>
      <WorkDetailPayment payRateReg={payRateReg} totalPay={totalPay} />
    </Box>
    <Box item>
      <WorkDetailDescription description={description} />
    </Box>
    <Box item>
      {/* TODO: add skills */}
      <WorkDetailSkills />
    </Box>
    <Box item data-testid="test-cert-section">
      {groupCertifications.length > 0 && (
        <WorkDetailCertifications certifications={groupCertifications} />
      )}
    </Box>
    <Box item data-testid="test-shift-section">
      {groupPlacements.length > 0 && <WorkDetailYourShifts placements={groupPlacements} />}
    </Box>
    <Box item>
      <WorkDetailAvailableShifts shifts={availableShifts} />
    </Box>
  </Grid>
);

WorkDetail.propTypes = {
  availableShifts: PropTypes.arrayOf(PropTypes.shape({})),
  corporation: PropTypes.string,
  description: PropTypes.string,
  distance: PropTypes.number,
  groupCertifications: PropTypes.arrayOf(PropTypes.shape({})),
  groupPlacements: PropTypes.arrayOf(PropTypes.shape({})),
  jobOrderName: PropTypes.string,
  payRateReg: PropTypes.number,
  totalPay: PropTypes.shape({ max: PropTypes.number, min: PropTypes.number }),
};

export default WorkDetail;
