import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import FormTextField from '../../../components/Form/FormTextField';
import { composeValidators, isGreaterThan, isInteger } from '../../../components/Form/validations';
import {
  selectJobListRowData,
  updateIsProcessing,
} from '../../../store/selectors/jobListSelectors';
import { paperElevation } from '../../../theme/theme';
import { updateJob } from '../jobList';

import DeleteJob from './deleteJob';
import JobDetailHeader from './JobDetailHeader';

let formFieldData = [
  {
    ComponentType: FormTextField,
    label: 'Position',
    name: 'title',
    readOnly: true,
    variant: 'standard',
    fullWidth: true,
  },
  {
    ComponentType: FormTextField,
    label: 'Start date',
    name: 'startDate',
    readOnly: true,
    variant: 'standard',
    fullWidth: true,
  },
  {
    ComponentType: FormTextField,
    label: 'End date',
    name: 'endDate',
    readOnly: true,
    variant: 'standard',
    fullWidth: true,
  },
  {
    ComponentType: FormTextField,
    label: 'Location',
    name: 'address',
    readOnly: true,
    variant: 'standard',
    fullWidth: true,
  },
  {
    ComponentType: FormTextField,
    label: 'Clock-in time',
    name: 'startTime',
    readOnly: true,
    variant: 'standard',
    fullWidth: true,
  },
  {
    ComponentType: FormTextField,
    label: 'Clock-out time',
    name: 'endTime',
    readOnly: true,
    variant: 'standard',
    fullWidth: true,
  },
  {
    ComponentType: FormTextField,
    focused: true,
    label: 'Openings',
    name: 'numOpenings',
    required: true,
    variant: 'standard',
    fullWidth: true,
    validate: composeValidators(isInteger, isGreaterThan(0)),
  },
];

const dialogContentFor24HRUpdate = (
  <>Unable to update. The job is scheduled to start within 24 hours. Please contact support.</>
);
const JobDetailWrap = styled(Grid)(({ theme }) => ({
  alignContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(1, 1, 0),
}));

const JobDetailContent = styled(Grid)(() => ({
  flex: 1,
  overflow: 'auto',
}));

const JobDetailFooter = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'right',
  padding: theme.spacing(2),
}));

const formID = 'JobDetail';

const JobDetail = ({ jobId, cleanJobId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoading = useSelector(updateIsProcessing);
  const jobListRowData = useSelector(selectJobListRowData);
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const selectedData = jobListRowData.filter((row) => row.id === jobId)[0];
  const [is24HRUpdateDialogOpen, setIs24HRUpdateDialogOpen] = useState(false);

  if (selectedData) {
    // sets initial values
    formFieldData = formFieldData.map((field) => ({
      ...field,
      initialValue: selectedData[field.name],
    }));
  }

  const handleJobUpdate = useCallback(
    (formValues) => {
      const timeDifference = selectedData.start - new Date().getTime();
      // Check the difference between selected job start time and current time to check if time difference is less than 24 hours.
      if (timeDifference < 86400000) {
        setIs24HRUpdateDialogOpen(true);
        return;
      }
      const data = { id: jobId, fields: { numOpenings: formValues.numOpenings } };

      dispatch(updateJob(data));
    },
    [selectedData, dispatch, jobId]
  );

  const updateWithin24HrDialogButtons = [
    <Button
      color="primary"
      text="Cancel"
      variant="text"
      key="handle-cancel-twenty-four-hour-update-button"
      onClick={() => setIs24HRUpdateDialogOpen(false)}
    />,
  ];

  return (
    <>
      <Dialog
        open={is24HRUpdateDialogOpen}
        dialogTitle="Update Failed"
        dialogContentText={dialogContentFor24HRUpdate}
        dialogActions={updateWithin24HrDialogButtons}
        component="span"
        onBackdropClick={() => setIs24HRUpdateDialogOpen(false)}
      />
      <Slide direction="left" in={!!jobId} unmountOnExit timeout={200}>
        <Grid item md={4} xs sx={{ height: '100%' }}>
          <Paper elevation={mediumScreen ? paperElevation : 0} sx={{ height: '100%' }}>
            <JobDetailWrap>
              <JobDetailHeader cleanJobId={cleanJobId} />
              <JobDetailContent>
                <Form formFieldData={formFieldData} formId={formID} onSubmit={handleJobUpdate} />
              </JobDetailContent>
              <JobDetailFooter>
                <DeleteJob jobId={jobId} isLoading={isLoading} />
                <Button
                  form={formID}
                  key="job-update-button"
                  showIndeterminateProgress={isLoading}
                  disabled={isLoading}
                  text="Update"
                  type="submit"
                  variant="text"
                />
              </JobDetailFooter>
            </JobDetailWrap>
          </Paper>
        </Grid>
      </Slide>
    </>
  );
};
JobDetail.propTypes = {
  jobId: PropTypes.string,
  cleanJobId: PropTypes.func,
};
export default JobDetail;
