import FormSelectDropdownField from '../../../components/Form/FormSelectDropdownField';
import FormShiftPicker from '../../../components/Form/FormShiftPicker';
import FormTextField from '../../../components/Form/FormTextField';

// FIXME: HB-953 Update this to allow specific fields to be shown conditionally.
function getEmployerFormFieldData({ isLoadingLocations, locations }) {
  return [
    {
      ComponentType: FormTextField,
      label: 'Position Name',
      name: 'name',
      required: true,
      variant: 'standard',
      fullWidth: true,
    },
    {
      ComponentType: FormSelectDropdownField,
      label: 'Location',
      name: 'location',
      multiple: true,
      required: true,
      variant: 'standard',
      isLoading: isLoadingLocations,
      options: locations,
      fullWidth: true,
    },
    {
      ComponentType: FormTextField,
      label: 'Description',
      name: 'description',
      required: true,
      variant: 'standard',
      fullWidth: true,
      multiline: true,
      maxRows: 5,
    },
    {
      ComponentType: FormShiftPicker,
      name: 'shifts',
      shiftTitle: 'Shifts',
      shiftDialogTitle: 'Add Shifts',
      shiftOpenDialogButtonVariant: 'outlined',
    },
  ];
}

export default getEmployerFormFieldData;
