import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SnackbarBase from '@mui/material/Snackbar';

import selectSnackbarData from '../../store/selectors/snackbarSelector';
import { hideSnackbar } from '../../views/app/reducer';

// TODO: HB-960 add state to props
const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbarData = useSelector(selectSnackbarData);
  const { message, isOpen } = snackbarData;
  return (
    <SnackbarBase
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={message}
      open={isOpen}
      onClose={() => dispatch(hideSnackbar())}
    />
  );
};

export default Snackbar;
