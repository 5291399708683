import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Box, Grid, Typography } from '@mui/material';

import { showSnackbar } from '../../../app/reducer';

import WorkDetailConfirmSkillDialog from './WorkDetailConfirmSkillDialog';

const WorkDetailSkill = ({ skill }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const clickHandler = () => {
    setConfirmDialogOpen(true);
  };

  const dispatch = useDispatch();
  const onConfirm = () => {
    setConfirmDialogOpen(false);
    dispatch(showSnackbar({ message: 'Skill added to profile' }));
  };

  const onCancel = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        columnGap={1}
        wrap="nowrap"
        onClick={clickHandler}
      >
        <Box item p={1}>
          {skill.enabled && <CheckCircleOutlineIcon fontSize="large" color="success" />}
          {!skill.enabled && <DoNotDisturbIcon fontSize="large" color="neutral" />}
        </Box>
        <Box item>
          <Typography fontWeight="bold">{skill.title}</Typography>
          <Typography>{skill.summary}</Typography>
        </Box>
      </Grid>
      <WorkDetailConfirmSkillDialog
        skill={skill}
        isOpen={confirmDialogOpen}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

WorkDetailSkill.propTypes = {
  skill: PropTypes.shape({
    summary: PropTypes.string,
    title: PropTypes.string,
    enabled: PropTypes.bool,
  }),
};

export default WorkDetailSkill;
