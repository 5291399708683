import React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Button from '../../../components/Button';
import { jobListHeaderHeight } from '../../../theme/theme';

const RootGrid = styled(Grid)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  height: jobListHeaderHeight,
}));

const JobsListHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleJobMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleJobMenuClose = () => {
    setAnchorEl(null);
  };

  const jobMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleJobMenuClose}
    >
      <MenuItem onClick={() => navigate('/jobs/createJobs')}>
        <Typography>Just a day</Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate('/jobs/createJob')}>
        <Typography>Long-term job</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <RootGrid item container>
      <Typography sx={{ paddingLeft: 2 }}>Job Orders</Typography>

      <Button
        style={{ marginLeft: 1 }}
        color="primary"
        text="+&nbsp;New Job Order"
        variant="contained"
        key="job-list-view-new-button"
        onClick={handleJobMenuOpen}
      />
      {jobMenu}
    </RootGrid>
  );
};

export default JobsListHeader;
