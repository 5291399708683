import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import WorkIcon from '@mui/icons-material/Work';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const JobsMenu = ({ closeDrawer }) => {
  const navigate = useNavigate();

  return (
    <>
      <List component="nav" disablePadding>
        <ListItemButton
          sx={{ pl: 2 }}
          onClick={() => {
            navigate('jobs/jobsList');
            closeDrawer();
          }}
        >
          <ListItemIcon>
            <WorkIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Job Orders" />
        </ListItemButton>
      </List>
    </>
  );
};

JobsMenu.propTypes = {
  closeDrawer: PropTypes.func,
};

export default JobsMenu;
