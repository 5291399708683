import React from 'react';
import PropTypes from 'prop-types';

import { Dialog as MuiDialog } from '@mui/material/';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Dialog = ({
  open,
  onClose,
  fullWidth,
  fullScreen,
  PaperComponent,
  PaperProps,
  scroll,
  component,
  dialogActions,
  dialogTitle,
  dialogContentText,
  dialogUnscrollAbleBottomText,
  dialogUnscrollAbleTopText,
  onBackdropClick,
}) => {
  const UnscrollableTextTop = (
    <DialogContent>
      {/* unscrollable text that exist on the top (like a title) */}
      <DialogContentText component={component}> {dialogUnscrollAbleTopText} </DialogContentText>
    </DialogContent>
  );

  const UnscrollableTextBottom = (
    <DialogContent>
      {/* unscrollable text that exist on the bottom */}
      <DialogContentText component={component}> {dialogUnscrollAbleBottomText} </DialogContentText>
    </DialogContent>
  );

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      PaperComponent={PaperComponent}
      PaperProps={PaperProps}
      onBackdropClick={onBackdropClick}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>

      {dialogUnscrollAbleTopText && UnscrollableTextTop}

      <DialogContent sx={{ overflowY: 'unset' }}>
        <DialogContentText scroll={scroll} component={component}>
          {dialogContentText}
        </DialogContentText>
      </DialogContent>

      {dialogUnscrollAbleBottomText && UnscrollableTextBottom}

      <DialogActions>{dialogActions && dialogActions}</DialogActions>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool,
  component: PropTypes.string,
  onClose: PropTypes.func,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  PaperComponent: PropTypes.elementType,
  PaperProps: PropTypes.objectOf(PropTypes.string),
  scroll: PropTypes.string, // body or paper
  dialogActions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  dialogContentText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]), // PropTypes.elementType? can be used to customize the text
  dialogUnscrollAbleBottomText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  dialogUnscrollAbleTopText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onBackdropClick: PropTypes.func,
};

export default Dialog;
