/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import map from 'lodash/fp/map';
import { batch, useDispatch, useSelector } from 'react-redux';

import UserRole from '../../../constants/user-role';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectCorporations,
  selectIsLoadingCorporations,
  selectIsLoadingLocations,
  selectIsLoadingPt,
  selectLocations,
  selectPositionTemplateData,
} from '../../../store/selectors/createLongTermJobSelectors';
import { dateToYearMonthDayMinutes } from '../../../utils';

import { fieldsJobDescription } from './formData/formFieldData';
import { recruiterFormFieldData } from './formData/recruiterFormFieldData';
import CreateJobForm from './CreateJobForm';
import { getCorporations, getLocations, getPositionTemplate, postJob } from './reducer';

const WrapperCreateLongTermJob = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(getCorporations());
      dispatch(getPositionTemplate());
      dispatch(getLocations());
    });
  }, [dispatch]);

  const corporations = useSelector(selectCorporations);
  const isLoadingCorporations = useSelector(selectIsLoadingCorporations);
  const isLoadingPt = useSelector(selectIsLoadingPt);
  const isLoadingLocations = useSelector(selectIsLoadingLocations);
  const locations = useSelector(selectLocations);
  const positionTemplateData = useSelector(selectPositionTemplateData);
  const user = useSelector(selectUser);

  const onSubmit = (values) => {
    const { startDate, endDate, position, location, numOpenings, shift } = values;

    dispatch(
      postJob({
        start: dateToYearMonthDayMinutes(startDate, shift.start), // Y:####, M##, D##
        end: dateToYearMonthDayMinutes(endDate, shift.end),
        numOpenings: parseInt(numOpenings, 10),
        location: location.id,
        positionTemplate: position.id,
      })
    );
  };

  const baseFormData =
    (user.role === UserRole.EMPLOYER && fieldsJobDescription) || recruiterFormFieldData;

  return (
    !isLoadingCorporations &&
    !isLoadingPt &&
    !isLoadingLocations &&
    ((
      <CreateJobForm
        formData={map(
          (fieldData) =>
            ([UserRole.ADMIN, UserRole.RECRUITER].includes(user.role) &&
              fieldData.name === 'corporation' && {
                ...fieldData,
                options: corporations,
              }) ||
            (user.role === UserRole.EMPLOYER &&
              fieldData.name === 'position' && {
                ...fieldData,
                options: map(
                  (pt) => ({
                    name: pt.approved ? pt.name : `${pt.name}  (approval pending)`,
                    id: pt.id,
                    disabled: !pt.approved,
                  }),
                  positionTemplateData
                ),
              }) ||
            fieldData,
          baseFormData
        )}
        locations={locations}
        onSubmit={onSubmit}
        positionTemplateData={positionTemplateData}
      />
    ) ||
      // TODO: replace null with a "loading shell"
      null)
  );
};

export default WrapperCreateLongTermJob;
