/* eslint-disable no-debugger */
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import has from 'lodash/fp/has';
import map from 'lodash/fp/map';
import uniqBy from 'lodash/fp/uniqBy';

import { createSlice } from '@reduxjs/toolkit';

import { epochToShortDateString, epochToTimeInReadableFormat } from '../../utils';

const initialState = {
  certifications: [],
  corporations: [],
  files: {},
  groups: [],
  isLoadingCertifications: false,
  isLoadingCorporations: false,
  isLoadingJobOrderList: false,
  isLoadingPlacements: false,
  isLoadingWorkList: false,
  isProcessingPostPlacement: false,
  jobOrders: [],
  locations: [],
  placements: [],
};

export const employeeWorkSlice = createSlice({
  name: 'employeeWorkSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getWorkList: (state) => {
      state.isLoadingWorkList = true;
    },
    getWorkListProcessed: (state, action) => {
      state.isLoadingWorkList = false;
      const { groups } = action.payload.data;

      const uniqueLocations = groups.reduce(
        (acc, curr) => [...acc, ...flow(get('jobOrders'), map('location'), uniqBy('_id'))(curr)],
        []
      );

      const updatedData = groups.map((group) => {
        const groupData = {
          ...group,
          _id: group._id,
          payRateReg: group.payRateReg,
          title: group.name,
          totalPay: group.totalPay,
          jobOrders: group.jobOrders.map((jobOrder) => ({
            ...jobOrder,
            startDate: epochToShortDateString(get('start', jobOrder)),
            startTime: epochToTimeInReadableFormat(get('start', jobOrder)),
            endDate: epochToShortDateString(get('end', jobOrder)),
            endTime: epochToTimeInReadableFormat(get('end', jobOrder)),
          })),
        };
        return groupData;
      });
      state.groups = updatedData;
      state.locations = uniqueLocations;
    },
    getWorkListError: (state) => {
      state.isLoadingWorkList = false;
    },

    getFilesForCandidate: (state, action) => {
      const imageID = get(['payload', 'id'], action);

      // in case we have called for this file more than once, don't overwrite it with null.
      if (!has(imageID, state.files)) {
        state.files[imageID] = null;
      }
    },
    getFilesForCandidateProcessed: (state, action) => {
      const data = get(['payload', 'data'], action);
      const imageID = get(['payload', 'headers', 'id'], action);

      const blobToURL = URL.createObjectURL(data);

      state.files[imageID] = blobToURL;
    },

    getFilesForCandidateError: () => {},

    getCorporationsForCandidate: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsForCandidateProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);

      state.corporations = documents;

      state.isLoadingCorporations = false;
    },
    getCorporationsForCandidateError: (state) => {
      state.isLoadingCorporations = false;
    },

    postPlacement: (state) => {
      state.isProcessingPostPlacement = true;
    },
    postPlacementProcessed: (state, action) => {
      state.isProcessingPostPlacement = false;
      state.placements = [...state.placements, action.payload.data.document];
    },
    postPlacementError: (state) => {
      state.isProcessingPostPlacement = false;
    },

    getCertificationsForCandidate: (state) => {
      state.isLoadingCertifications = true;
    },
    getCertificationsForCandidateProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);

      const certifications = map((certification) => {
        const { _id, ...rest } = certification;
        return {
          id: _id,
          ...rest,
        };
      }, documents);
      state.certifications = certifications;
      state.isLoadingCertifications = false;
    },
    getCertificationsForCandidateError: (state) => {
      state.isLoadingCertifications = false;
    },

    getPlacements: (state) => {
      state.isLoadingPlacements = true;
    },
    getPlacementsProcessed: (state, action) => {
      state.isLoadingPlacements = false;
      const placementsFromApi = get(['payload', 'data', 'documents'], action);
      state.placements = placementsFromApi;
    },
    getPlacementsError: (state) => {
      state.isLoadingPlacements = false;
    },

    getJobOrderList: (state) => {
      state.isLoadingJobOrderList = true;
    },

    getJobOrderListProcessed: (state, action) => {
      state.isLoadingJobOrder = false;
      const jobOrders = get(['payload', 'data', 'documents'], action);
      state.jobOrders = jobOrders;
    },

    getJobOrderListError: (state) => {
      state.isLoadingJobOrder = false;
    },

    /* eslint-disable no-param-reassign */
  },
});

export const employeeWorkReducer = employeeWorkSlice.reducer;

export const {
  getCertificationsForCandidate,
  getCertificationsForCandidateError,
  getCertificationsForCandidateProcessed,

  getCorporationsForCandidate,
  getCorporationsForCandidateError,
  getCorporationsForCandidateProcessed,

  getFilesForCandidate,
  getFilesForCandidateError,
  getFilesForCandidateProcessed,

  getJobOrderList,
  getJobOrderListError,
  getJobOrderListProcessed,

  getPlacements,
  getPlacementsError,
  getPlacementsProcessed,

  getWorkList,
  getWorkListError,
  getWorkListProcessed,

  postPlacement,
  postPlacementError,
  postPlacementProcessed,
} = employeeWorkSlice.actions;
