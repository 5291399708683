import getOr from 'lodash/fp/getOr';

import UserRole from '../../../constants/user-role';

const address = {
  field: 'address',
  headerName: 'Location',
  width: 150,
};

const corporation = {
  field: 'corporation',
  headerName: 'Corporation',
  valueFormatter: ({ value }) => getOr('', 'name', value),
  width: 200,
};

const description = {
  field: 'description',
  headerName: 'Description',
  width: 300,
};

const endDate = {
  field: 'endDate',
  headerName: 'End Date',
  width: 150,
};

const endTime = {
  field: 'endTime',
  headerName: 'Clock Out Time',
  width: 150,
};

const externalId = {
  field: 'externalId',
  headerName: 'External ID',
  width: 150,
  valueGetter: (params) => {
    // in some cases, bullhorn is not included in the metadata
    const { bullhorn = {} } = params.row.metadata;
    return bullhorn.id;
  },
};

const name = {
  field: 'name',
  headerName: 'Position',
  width: 200,
};

const numOpenings = {
  field: 'numOpenings',
  headerName: 'Openings',
  width: 100,
};

const startDate = {
  field: 'startDate',
  headerName: 'Start Date',
  width: 150,
};

const startTime = {
  field: 'startTime',
  headerName: 'Clock In Time',
  width: 150,
};

const adminColumnData = [
  name,
  corporation,
  address,
  numOpenings,
  startTime,
  endTime,
  startDate,
  endDate,
  description,
  externalId,
];

const employerColumnData = [
  name,
  address,
  numOpenings,
  startTime,
  endTime,
  startDate,
  endDate,
  description,
];

const recruiterColumnData = [
  name,
  corporation,
  address,
  numOpenings,
  startTime,
  endTime,
  startDate,
  endDate,
  description,
  externalId,
];

const getJobListColumnData = (userRole) => {
  switch (userRole) {
    case UserRole.ADMIN:
      return adminColumnData;
    case UserRole.EMPLOYER:
      return employerColumnData;
    case UserRole.RECRUITER:
      return recruiterColumnData;
    default:
      throw Error(`Invalid user role '${userRole}'`);
  }
};

export default getJobListColumnData;
