import { createSlice } from '@reduxjs/toolkit';

const initialState = { verifyingIsProcessing: false };

export const accountVerification = createSlice({
  name: 'accountVerificationSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    verifyAccount: (state) => {
      state.verifyingIsProcessing = true;
    },
    verifyAccountProcessed: (state) => {
      state.verifyingIsProcessing = false;
    },
    verifyAccountError: (state) => {
      state.verifyingIsProcessing = false;
    },
    passwordReset: (state) => {
      state.verifyingIsProcessing = true;
    },
    passwordResetProcessed: (state) => {
      state.verifyingIsProcessing = false;
    },
    passwordResetError: (state) => {
      state.verifyingIsProcessing = false;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const {
  verifyAccount,
  verifyAccountProcessed,
  verifyAccountError,
  passwordReset,
  passwordResetError,
  passwordResetProcessed,
} = accountVerification.actions;

export const accountVerificationReducer = accountVerification.reducer;
