import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';

import { createSlice } from '@reduxjs/toolkit';

import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../utils';

const initialState = {
  jobListRowData: [],
  isLoading: false,
  deleteIsProcessing: false,
  updateIsProcessing: false,
  corporations: [],
  isLoadingCorporations: false,
};
export const jobListSlice = createSlice({
  name: 'jobListSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getJobsList: (state) => {
      state.isLoading = true;
    },
    getJobsListProcessed: (state, action) => {
      state.isLoading = false;
      const { documents } = action.payload.data;

      const updatedData = map((jobEntry) => {
        const rest = omit(['_id'], jobEntry);
        return {
          id: get('_id', jobEntry),
          title: get('name', jobEntry),
          address: get(['location', 'address'], jobEntry),
          startDate: epochToDateInReadableFormat(get('start', jobEntry)),
          startTime: epochToTimeInReadableFormat(get('start', jobEntry)),
          endDate: epochToDateInReadableFormat(get('end', jobEntry)),
          endTime: epochToTimeInReadableFormat(get('end', jobEntry)),
          ...rest,
        };
      }, documents);

      state.jobListRowData = updatedData;
    },
    getJobsListError: (state) => {
      state.isLoading = false;
    },
    deleteJob: (state) => {
      state.deleteIsProcessing = true;
    },
    deleteJobProcessed: (state) => {
      state.deleteIsProcessing = false;
    },
    deleteJobError: (state) => {
      state.deleteIsProcessing = false;
    },
    updateJob: (state) => {
      state.updateIsProcessing = true;
    },
    updateJobProcessed: (state) => {
      state.updateIsProcessing = false;
    },
    updateJobError: (state) => {
      state.updateIsProcessing = false;
    },
    getCorporationsForJobsList: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsForJobsListProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);

      const corporations = map((corporation) => {
        const { _id, ...rest } = corporation;
        return {
          id: _id,
          ...rest,
        };
      }, documents);
      state.corporations = corporations;
      state.isLoadingCorporations = false;
    },
    getCorporationsForJobsListError: (state) => {
      state.isLoadingCorporations = false;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const jobListReducer = jobListSlice.reducer;

export const {
  getJobsList,
  getJobsListError,
  getJobsListProcessed,
  deleteJob,
  deleteJobError,
  deleteJobProcessed,
  updateJob,
  updateJobProcessed,
  updateJobError,
  getCorporationsForJobsList,
  getCorporationsForJobsListProcessed,
  getCorporationsForJobsListError,
} = jobListSlice.actions;
