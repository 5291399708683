import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import findIndex from 'lodash/fp/findIndex';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import { v4 as uuidv4 } from 'uuid';

import { createSlice } from '@reduxjs/toolkit';

import { dateToYearMonthDayMinutes, nameAndTimeToReadable } from '../../../utils';

import EMPTY_JOB from './constant';

const initialState = {
  jobsList: [],
  locations: [],
  positionTemplateData: [],
  confirmationData: null,
  isLoading: false,
  isLoadingPt: false,
  isLoadingLocation: false,
  validateList: [],
  clearJobsList: false,
  allDataValid: false,
};

export const createJobsSlice = createSlice({
  name: 'createJobsSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setJobCreateInitState: () => initialState,

    updateJobList: (state, action) => {
      const { id, field, value } = action.payload;
      // TODO: HB-966 update error handling (What should we do if unable to find)
      const selectedJobsListIndex = findIndex(
        (job) => (job.id ? job.id === id : false),
        state.jobsList
      );

      if (field === 'position') {
        const selectedPositionTemplate = find((pt) => pt._id === value, state.positionTemplateData);

        state.jobsList[selectedJobsListIndex].position = {
          name: selectedPositionTemplate.name,
          id: selectedPositionTemplate.id,
        };

        const locOptions = filter(
          (ln) => selectedPositionTemplate.locations.includes(ln.id),
          state.locations
        );

        state.jobsList[selectedJobsListIndex].locationOptions = [...locOptions];

        const shiftOptions = selectedPositionTemplate.shifts.map((shift) => ({
          id: shift._id,
          name: nameAndTimeToReadable(shift.name, shift.start, shift.end),
          start: shift.start,
          end: shift.end,
        }));
        state.jobsList[selectedJobsListIndex].shiftOptions = [...shiftOptions];

        // once position changed. clear the location selection and Time selection
        state.jobsList[selectedJobsListIndex].location = {};
        state.jobsList[selectedJobsListIndex].shift = {};
      }
      if (field === 'location') {
        const selectedLoc = find((loc) => loc.id === value, state.locations);
        state.jobsList[selectedJobsListIndex].location = {
          id: selectedLoc.id,
          name: selectedLoc.name,
        };
      }
      if (field === 'shift') {
        const selectedShift = find(
          (loc) => loc.id === value,
          state.jobsList[selectedJobsListIndex].shiftOptions
        );

        state.jobsList[selectedJobsListIndex].shift = {
          id: selectedShift.id,
          name: selectedShift.name,
          start: selectedShift.start,
          end: selectedShift.end,
        };
      }

      if (field === 'startInfo') {
        const SelectedStartInfo = dateToYearMonthDayMinutes(
          value,
          state.jobsList[selectedJobsListIndex].shift.start
        );

        state.jobsList[selectedJobsListIndex].startInfo = {
          year: SelectedStartInfo.year,
          day: SelectedStartInfo.day,
          month: SelectedStartInfo.month,
          minutes: SelectedStartInfo.minutes,
        };
      }

      if (field === 'numOpenings') {
        state.jobsList[selectedJobsListIndex].numOpenings = value;
      }
    },

    resetJobList: (state, action) => {
      state.jobsList = action.payload;
    },

    addJob: (state) => {
      const newJob = { ...EMPTY_JOB };
      newJob.id = uuidv4();
      state.jobsList.push(newJob);
    },

    removeJob: (state, action) => {
      const id = action.payload;
      const res = filter((job) => (job.id ? job.id !== id : false), state.jobsList);
      state.jobsList = res;
    },

    // payload contains field, id, status
    updateValidateList: (state, action) => {
      const { field, id, status } = action.payload;

      const selectedValidateIndex = findIndex(
        (v) => (v.id ? v.id === id : false),
        state.validateList
      );

      if (selectedValidateIndex >= 0) {
        if (!state.validateList[selectedValidateIndex][field]) {
          state.validateList[selectedValidateIndex][field] = {};
        }
        state.validateList[selectedValidateIndex][field] = { isValid: status };
      } else {
        state.validateList.push({
          id,
          [field]: { isValid: status },
        });
      }
    },
    clearValidateList: (state) => {
      state.validateList = [];
    },
    updateValidation: (state, action) => {
      state.allDataValid = action.payload;
    },
    postJobs: (state) => {
      state.isLoading = true;
    },
    postJobsProcessed: (state) => {
      state.isLoading = false;
      state.clearJobsList = true;
    },

    resetClearJobList: (state) => {
      state.clearJobsList = false;
    },
    postJobsError: (state) => {
      state.isLoading = false;
    },
    getPositionTemplate: (state) => {
      state.isLoadingPt = true;
    },
    getPositionTemplateProcessedForJobs: (state, action) => {
      state.isLoadingPt = false;
      const positionTemplateSelection = (action.payload.data || {}).documents || [];
      state.positionTemplateData = [...positionTemplateSelection];
    },
    getPositionTemplateErrorForJobs: (state) => {
      state.isLoadingPt = false;
    },
    getLocations: (state) => {
      state.isLoadingLocation = true;
    },
    getLocationsProcessed: (state, action) => {
      state.isLoadingLocation = false;
      const { documents } = action.payload.data;
      const locations = map(
        (location) => ({
          id: get('_id', location),
          name: `${get('description', location)} - ${get('address', location)}`,
        }),
        documents
      );
      state.locations = [...locations];
    },

    getLocationsError: (state) => {
      state.isLoadingLocation = false;
    },

    /* eslint-disable no-param-reassign */
  },
});

export const createJobsReducer = createJobsSlice.reducer;

export const {
  setJobCreateInitState,
  updateJobDescription,
  removeJob,
  addJob,
  updateJobDateAndTime,
  updateJobScheduling,
  getLocationsError,
  getLocations,
  getLocationsProcessed,
  getPositionTemplate,
  getPositionTemplateErrorForJobs,
  getPositionTemplateProcessedForJobs,
  postJobs,
  postJobsError,
  postJobsProcessed,
  resetClearJobList,
  resetJobList,
  updateValidateList,
  clearValidateList,
  updateValidation,
} = createJobsSlice.actions;
