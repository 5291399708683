import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

const WorkDetailEmployer = ({ corporation, distance }) => (
  <>
    <Typography fontWeight="bold" fontSize="large">
      {corporation}
    </Typography>
    <Typography>{distance || '[#]'} miles from you</Typography>
  </>
);

WorkDetailEmployer.propTypes = {
  corporation: PropTypes.string,
  distance: PropTypes.number,
};

export default WorkDetailEmployer;
