import React from 'react';
import PropTypes from 'prop-types';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { DatePicker as ResponsiveDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { errorHandler } from './formUtils';

const FormDatePickerField = ({
  fieldErrorData,
  fullWidth,
  input,
  label,
  meta,
  minDate,
  readOnly,
  required,
  sx,
  variant,
}) => {
  const error = errorHandler(input, meta, fieldErrorData);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ResponsiveDatePicker
        desktopModeMediaQuery="@media (min-width: 900px)" // TODO: HB-952 discuss min-width
        label={label}
        minDate={minDate}
        name={input.name}
        onChange={input.onChange}
        OpenPickerButtonProps={{ sx: { marginRight: 0, marginBottom: 1 } }}
        readOnly={readOnly}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            fullWidth={fullWidth}
            helperText={error || null}
            required={required}
            sx={sx}
            variant={variant}
          />
        )}
        value={input.value}
        views={['year', 'month', 'day']}
      />
    </LocalizationProvider>
  );
};
FormDatePickerField.propTypes = {
  fieldErrorData: PropTypes.string,
  fullWidth: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
  label: PropTypes.string,
  meta: PropTypes.shape(),
  minDate: PropTypes.instanceOf(Date),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
};
export default FormDatePickerField;
