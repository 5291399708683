/*
 * Runs series of validators against the input value. In the case of an error, the first error found is returned.
 */
export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

/*
 * Validates the value is a number and greater than the provided minimum value
 */
export const isGreaterThan = (min) => (value) =>
  !(/^[0-9]*\.?[0-9]*$/.test(value) && Number(value) > min)
    ? `Value must be greater than ${min}`
    : undefined;

/*
 * Validates the currency value greater than the provided minimum value
 */
export const isCurrencyGreaterThan =
  (min) =>
  (value = '0') =>
    !(Number(value.toString().replace(/[^0-9.-]+/g, '')) > min)
      ? `Value must be greater than ${min}`
      : undefined;

/*
 * Validates the value is an integer
 */
export const isInteger = (value) => (!/^-?\d+$/.test(value) ? 'Value must be a number' : undefined);

/*
 * Validates the value is a valid date
 */
export const isValidDate = (date) =>
  Number.isNaN(Date.parse(date)) ? 'Not a valid date' : undefined;

/*
 * Validates the date is today or later
 */
export const isDateEqualOrGreaterThanToday = (date) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const isBeforeToday = new Date(date) < today;
  return isBeforeToday ? 'Date must not be in the past' : undefined;
};

/*
 * TO DO: make this validator more general
 * Validates the date is equal or greater than current date
 */
export const isDateEqualOrGreaterThanTodayDateObject = (dateObject) => {
  const inputDate = new Date(dateObject.year, dateObject.month - 1, dateObject.day).setHours(
    0,
    0,
    0,
    0
  );
  const today = new Date().setHours(0, 0, 0, 0);

  return inputDate < today ? 'Date must not be in the past' : undefined;
};

/*
 * Validates the date is before today
 */
export const isDateSmallerThanToday = (date) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const isTodayOrLater = new Date(date) >= today;
  return isTodayOrLater ? 'Date must be before today' : undefined;
};

/*
 * Validates the time is a valid time
 */
export const isValidTime = (time) =>
  Number.isNaN(Date.parse(time)) ? 'Not a valid time' : undefined;

/*
 * Custom Validation for shift component. Validates if shift is empty
 */
export const isPTShiftEmpty = (value) => {
  if (value) {
    return value.length <= 0 ? 'At least one shift must be created' : undefined;
  }
  return 'At least one shift must be created';
};

/*
 * Validates field input as a valid phone number
 */
export const isValidPhoneNumber = (value) =>
  !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value)
    ? 'Field must be in a phone number format'
    : undefined;

/*
 * Validates field input as a valid email address
 */
export const isValidEmail = (value) =>
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? 'Field must be in a valid email format'
    : undefined;

/*
 * Custom Validation for forgot my pass textfield component. Validates if textfield is in either valid email or phone number format
 */
export const isValidPhoneOrEmail = (value) =>
  isValidPhoneNumber(value) && isValidEmail(value)
    ? 'Field must be in a valid email or phone number format'
    : undefined;

/*
 * Validates field input as a valid phone number  Returns a boolean.
 */
export const isValidPhoneNumberBoolean = (value) =>
  !!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value);

/*
 * Validates field input as a valid email address. Returns a boolean.
 */
export const isValidEmailBoolean = (value) =>
  !!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
